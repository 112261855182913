/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store/stores";
//Paginas
import MainView from "../views/MainView.vue";
import LoginView from "../views/LoginView.vue";
import SigninView from "../views/SigninView.vue";
import CardView from "../views/CardView.vue";
import ProfileView from "../views/profile/ProfileView.vue";
import ProfileIndexView from "../views/profile/ProfileIndexView.vue";
import ProfileAddress from "../views/profile/address/ProfileAddress.vue";
import ProfilePurchases from "../views/profile/purchases/ProfilePurchases.vue";
import PurchaseItem from "../views/profile/purchases/PurchaseItem.vue";
import addAddress from "../views/profile/address/addAddress.vue";
import ProfileCard from "../views/profile/payment/ProfileCard.vue";
import EditProfile from "../views/profile/EditProfile.vue";
import addCard from "../views/profile/payment/addCard.vue";
import PurchaseView from "../views/purchase/PurchaseView.vue";
import PurchaseShipping from "../views/purchase/PurchaseShipping.vue";
import PurchaseAddress from "../views/purchase/PurchaseAddress.vue";
import PurchasePayment from "../views/purchase/PurchasePayment.vue";
import PurchaseCard from "../views/purchase/PurchaseCard.vue";
import PurchaseInstallment from "../views/purchase/PurchaseInstallment.vue";
import PurchaseConfirm from "../views/purchase/PurchaseConfirm.vue";
import PurchasePayOrder from "../views/purchase/PurchasePayOrder.vue";
import PurchaseResult from "../views/purchase/PurchaseResult.vue";
import FavoritesView from "../views/profile/FavoritesView.vue";
import WeAre from "../views/footerComponents/WeAre.vue";
import ChangePolicies from "../views/footerComponents/ChangePolicies.vue";
import ConsumerDefence from "../views/footerComponents/ConsumerDefence.vue";
import FaqsQuestions from "../views/footerComponents/FaqsQuestions.vue";
import OfficesComponent from "../views/footerComponents/OfficesComponent.vue";
import PaymentWays from "../views/footerComponents/PaymentWays.vue";
import ShipmentPolicies from "../views/footerComponents/ShipmentPolicies.vue";
import TermsConditions from "../views/footerComponents/TermsConditions.vue";
import WithdrawalOffice from "../views/footerComponents/WithdrawalOffice.vue";
import WellcomeView from "../views/WellcomeView.vue";
import PrivacyPolicies from "../views/footerComponents/PrivacyPolicies.vue";
import ContactUs from "../views/ContactUs.vue";
import SellersView from "../views/SellersView.vue";
import ShopView from "../views/ShopView.vue";
import SearchView from "../views/SearchView.vue";
import RatingView from "../views/profile/RatingView.vue";
import ProfileMenuComponent from "../components/profile/ProfileMenuComponent.vue";
import ProfilesView from "../views/profile/ProfilesView.vue";
import NotificationsView from "../views/profile/NotificationsView.vue";
import SecurityView from "../views/profile/SecurityView.vue";
import ProfileImage from "../components/shared/ProfileImage.vue";
import ProductsType from "../views/ProductsType.vue";
import MerchantView from "../views/MerchantView.vue";
import LogToContinue from "../views/LogToContinue.vue";
import SaveView from "../views/profile/SaveView.vue";
import MerchantCategoryView from "../views/MerchantCategoryView.vue";
import NotFound from "../views/NotFound.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: MainView,
      name: 'Home'
    },
    {
      path: "/search/:search?",
      component: SearchView,
    },
    {
      path: "/products/:type?",
      component: ProductsType,
    },
    {
      path: "/merchant/category/:id?",
      component: MerchantCategoryView,
    },
    {
      path: "/merchant/:merchant?",
      component: MerchantView,
    },
    {
      path: '/profile/:username',
      component: ProfileIndexView
    },
    {
      path: "/profile",
      children: [
        {
          path: "config",
          component: ProfileView
        },
        {
          path: "config/edit",
          component: EditProfile
        },
        {
          path: "info",
          component: ProfileMenuComponent,
          children: [
            {
              path: "my-cards",
              component: ProfileCard,
            },
            {
              path: "my-addresses",
              component: ProfileAddress,
            },
            {
              path: "my-purchases",
              component: ProfilePurchases,
            },
            {
              path: "my-purchases/:orderId",
              component: PurchaseItem,
            },
            {
              path: "saved",
              component: SaveView,
            },
            {
              path: "favorites",
              component: FavoritesView,
            },
            {
              path: "ratings/:section",
              component: RatingView,
            },
            {
              path: "my-security",
              component: SecurityView,
            },
            {
              path: "my-profiles",
              component: ProfilesView,
            },
            {
              path: "my-notifications",
              component: NotificationsView,
            },
            {
              path: "add",
              children: [
                {
                  path: "paymentCard",
                  component: addCard,
                },
                {
                  path: "address",
                  component: addAddress,
                },
              ],
            },
          ],
        },
        {
          path: "edit",
          children: [
            {
              path: "address/:idToEdit",
              component: addAddress,
            },
          ],
        },
      ],
      beforeEnter: (to, from, next) => {
        const $U = useUserStore();
        if ($U.currentUser) {
          next();
        } else {
          next({ name: 'Home' });
        }
      },
    },
    {
      path: "/login",
      component: LoginView,
      beforeEnter: (to, from, next) => {
        const $U = useUserStore();
        if ($U.currentUser) {
          next({ name: 'Home' });
        } else {
          next();
        }
      },
    },
    {
      path: "/signin",
      component: SigninView,
    },
    {
      path: "/changepolicies",
      component: ChangePolicies,
    },
    {
      path: "/consumerdefence",
      component: ConsumerDefence,
    },
    {
      path: "/faqsquestions",
      component: FaqsQuestions,
    },
    {
      path: "/officescomponent",
      component: OfficesComponent,
    },
    {
      path: "/paymentways",
      component: PaymentWays,
    },
    {
      path: "/shipmentpolicies",
      component: ShipmentPolicies,
    },
    {
      path: "/termsconditions",
      component: TermsConditions,
    },
    {
      path: "/withdrawaloffice",
      component: WithdrawalOffice,
    },
    {
      path: "/weare",
      component: WeAre,
    },
    {
      path: "/wellcomeview",
      component: WellcomeView,
    },
    {
      path: "/privacypolicies",
      component: PrivacyPolicies,
    },
    {
      path: "/contactus",
      component: ContactUs,
    },
    {
      path: "/sellersview",
      component: SellersView,
    },
    {
      path: "/shopview",
      component: ShopView,
    },
    {
      path: "/profileimage",
      component: ProfileImage,
    },
    {
      path: "/logToContinue",
      component: LogToContinue,
    },
    {
      path: "/cart/purchase",
      component: PurchaseView,
      children: [
        {
          path: "shipping-options",
          component: PurchaseShipping,
        },
        {
          path: "add/address",
          component: addAddress,
        },
        {
          path: "edit/address/:idToEdit",
          component: addAddress,
        },
        {
          path: "select/address",
          component: PurchaseAddress,
        },
        {
          path: "select/branch",
          component: PurchaseAddress,
        },
        {
          path: "payment-options",
          component: PurchasePayment,
        },
        {
          path: "add/paymentCard",
          component: addCard,
        },
        {
          path: "select/paymentCard",
          component: PurchaseCard,
        },
        {
          path: "installment-options",
          component: PurchaseInstallment,
        },
        {
          path: "confirm",
          component: PurchaseConfirm,
        },
        {
          path: "pay-orders",
          component: PurchasePayOrder,
        }
      ],
      beforeEnter: (to, from, next) => {
        const $U = useUserStore();
        if ($U.currentUser) {
          next();
        } else {
          next({ name: 'Home' });
        }
      },
    },
    {
      path: "/purchase/:result",
      component: PurchaseResult,
      beforeEnter: (to, from, next) => {
        const $U = useUserStore();
        if ($U.currentUser) {
          next();
        } else {
          next({ name: 'Home' });
        }
      },
    },
    {
      path: "/producto",
      children: [
        {
          path: ":id",
          component: CardView,
        },
      ],
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  ],
  scrollBehavior() {
    return { top: 0 };
  }
});
