<script setup>
//vue
import { computed } from "vue";
//vue-router
import { RouterLink, useRoute } from "vue-router";
//components
import CartComponent from "./CartComponent.vue";
import SearchComponent from "./SearchComponent.vue";
//stores
import { useUserStore } from "@/store/stores";
//helpers
import { capitalize } from "@/helpers/capitalize";
import { generateInitials, getInitialsClass } from "@/helpers/initials";

const $U = useUserStore();
const route = useRoute();

const routeCheck = computed(() => route.fullPath.split("/")[1] === "cart");
const logged = computed(() => Boolean($U.currentUser));
</script>

<template>
  <div class="navbar-container">
    <div class="navbar-information">
      <div class="navbar-logo">
        <RouterLink to="/">
          <img src="@/assets/logos/mall.svg" alt="Logo" />
        </RouterLink>
      </div>

      <SearchComponent />

      <div class="navbar-user">
        <div class="navbar-links" v-if="!logged">
          <RouterLink to="/signin">Creá tu cuenta</RouterLink>
          <RouterLink to="/login">Ingresá</RouterLink>
        </div>

        <RouterLink to="/profile/config" v-else class="user-button">
          <div v-if="$U.currentUser.image" class="menu-user">
            <img :src="$U.currentUser.image"/>
          </div>
          <div v-else :class="getInitialsClass($U.currentUser.firstName)">{{ generateInitials($U.currentUser.name ? $U.currentUser.name : $U.currentUser.firstName) }}</div>
          <span>{{ capitalize($U.currentUser.firstName) }}</span>
        </RouterLink>

        <CartComponent v-if="!routeCheck" />
        <div v-else class="empty"></div>
      </div>
    </div>
  </div>
</template>

<style>
.empty {
  width: 40px;
  height: 40px;
}
</style>