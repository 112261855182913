<template>
  <v-dialog v-model="$Profile.openPantsModal">
    <div class="pants-modals">
      <div class="back-close">
        <div class="cross-close" @click="$Profile.openPantsModal = false">
          <img src="../../assets/close-cross.svg" alt="logo" />
        </div>
      </div>
      <div class="pants-image">
        <img src="../../assets/blue-t-pant.svg" alt="logo" />
      </div>

      <div class="pants-title">¿Cuál es el talle de tu pantalón?</div>
      <div class="pants-text">
        Con esto te mostramos modelos aproximados a tu talle.
      </div>
      <div class="grid-buttons">
        <div class="grid-container">
          <div
            :class="['grid-item', { selected: isSelectedBottomSize(bottomSize) || selectedBottomSize === bottomSize }]"
            v-for="bottomSize in bottomSizes"
            :key="bottomSize"
            @click="updatebottomSize(bottomSize)"
          >
            {{ bottomSize }}
          </div>
        </div>
      </div>
      <button class="modal-button" @click="lastModal">
        Listo
      </button>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, reactive,onMounted } from "vue";
import { useProfileStore } from "@/store/stores";

// const selectedUnderwear = ref(null);
const selectedBottomSize = ref(null);
const lastProfile = ref(null);

const bottomSizes = ["XS", "S", "M", "L", "XL", "XXL"];

const updatebottomSize = (bottomSize) => {
  if (selectedBottomSize.value === bottomSize) {
    selectedBottomSize.value = null;
  } else {
    selectedBottomSize.value = bottomSize;
  }
  dataToSend.bottomSize = selectedBottomSize.value;
};

const isSelectedBottomSize = (bottomSize) => {
  if(lastProfile.value?.id !== $Profile.currentProfile?.id) {
    lastProfile.value = $Profile.currentProfile;
    selectedBottomSize.value = $Profile.currentProfile.bottomSize;
  }
  return selectedBottomSize.value === bottomSize;
};
// const underwears = ["XS", "S", "M", "L", "XL", "XXL"];

// const updateUnderwear = (underwear) => {
//   if (selectedUnderwear.value === underwear) {
//     selectedUnderwear.value = null;
//   } else {
//     selectedUnderwear.value = underwear;
//   }
//   dataToSend.underwear = selectedUnderwear.value;
// };

const $Profile = useProfileStore();
const currentProfile = $Profile.currentProfile;
const dataToSend = reactive({
  underwear: "",
  bottomSize: "",
});

const lastModal = () => {
  currentProfile.bottomSize = dataToSend.bottomSize;
  currentProfile.underwear = dataToSend.underwear;
  $Profile.openPantsModal = false;
};
onMounted(async () => {
  selectedBottomSize.value = $Profile.currentProfile.bottomSize;
});
</script>
