<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { useRouter } from "vue-router";
//components
import PaymentCardCarrousel from "@/components/PaymentCardCarrousel.vue";
//stores
import { useUserStore, usePurchaseStore, usePaymentStore, usePopStore } from "@/store/stores";

const router = useRouter();
const $POP = usePopStore();
const $P = usePurchaseStore();
const $U = useUserStore();
const $C = usePaymentStore();
const user = $U.currentUser;
const loaded = ref(false);

const allCards = computed(() => $C.paymentCards);
onMounted(async () => {
  if($P.cart.totalPrice === 0) {
    router.push("/");
    return;
  }
  if($P.step > 1) {
    $P.step = 2;
    await $C.getAllCards();
    loaded.value = true
  } else {
    router.push(`/cart/purchase/shipping-options`);
  }
});

const mainCard = ref(user.mainPaymentCardId);
const lastFourDigits = ref(null);

const addCard = () => {
  router.push(`/cart/purchase/add/paymentCard`);
};
const payment = async () => {
  user.mainPaymentCardId = mainCard.value;
  $P.payment.card = await $C.getCard(mainCard.value)
  await $C.updateMainPaymentCardId(mainCard.value);
  if($P.payment.card.isCreditCard) {
    router.push(`/cart/purchase/installment-options`);
    $P.step = 3;
  } else {
    $P.installmentsPlans = [];
    for (const preOrder of $P.preOrders) {
      $P.installmentsPlans.push({
        installment: {
          totalAmount: preOrder.total + preOrder.orderDetails.shippingCost - preOrder.totalDiscount,
          amountPerInstallment: preOrder.total + preOrder.orderDetails.shippingCost - preOrder.totalDiscount,
          installments: 1,
          actualInstallments: 1,
          coefficient: 1
        },
        userMerchant: preOrder.userMerchant
      })
    }
    router.push(`/cart/purchase/confirm`);
    $P.step = 4;
  }
};
const getInfo = (info) => {
  mainCard.value = info.id;
  lastFourDigits.value = info.lastFourDigits;
};
</script>

<template>
  <div class="loading" v-if="!loaded">
    <img class="manijita" src="../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../assets/logos/bolsa.svg">
  </div>
  <div v-else class="purchase-shipping">
    <p class="purchase-title">¿Cómo querés pagar?</p>

    <div v-if="$U.currentUser.mainPaymentCardId === null" class="options-domicilio">
      <p>No tenes tarjetas agregadas</p>
    </div>
    
    <div v-else class="options-paymentCard">
      <PaymentCardCarrousel @sendInfo="getInfo" :data="allCards" />
    </div>

    <div class="buttons">
      <button @click="router.back()" class="blue-button">Atras</button>
      <button @click="addCard" class="form-button">+ Agregar tarjeta</button>
      <button :disabled="!allCards?.length" @click="payment" class="blue-button">Continuar</button>
    </div>

  </div>
</template>