<template>
  <div class="wellcome-info">
    <div class="wellcome-alignment">
      <div class="title">Tu app el shopping</div>
      <div class="sub-title">Mall tu tiempo, tu imagen, nuestra meta</div>
      <div class="discover-btn">
        <p>Descubrir</p>
        <img src="../assets/next-arrow.svg">
      </div>
      <div class="embedded-video"></div>
    </div>
  </div>
</template>

<script setup></script>
