import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { ref } from "vue";

export const useOcaStore = defineStore("oca", () => {
  const linkToOca = ref("");

  const trackOrder = async (idTrack) => {
    try {
      const response = await fetchWrapper.get(
        `oca/getTrackOrderLink/${idTrack}`
      );
      linkToOca.value = response;
    } catch (error) {
      console.log(error);
    }
  };
  return {
    trackOrder,
    linkToOca,
  };
});
