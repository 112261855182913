<template>
  <div class="terms-conditions">
    <div class="terms-background">
      <div class="terms">
        <div class="terms-title">
          Políticas de Privacidad
        </div>
        <div class="terms-subtitle">
          Fecha de actualización 27/05/2024
        </div>
        <div class="terms-text">
          Nombre de la Empresa., con domicilio AV. J. NEWBERY - S. 
          5005 MAR DEL PLATA NORTE 7600-BUENOS AIRES, ARGENTINA, ("MALL" o
          "nosotros") tomamos en serio su privacidad y garantizamos la seguridad
          y la protección de los datos personales (“Datos Personales”) de todos
          los clientes, socios, proveedores y usuarios ("Usuarios" o "usted")
          del sitio web "www.MALL.tienda” y cualquier otro sitio web, plataforma
          o aplicación operada por MALL (“Plataforma”). Esta Política de
          privacidad ("Política de Privacidad") tiene como objetivo informarle
          cómo usamos y divulgamos la información recopilada de sus visitas a
          nuestra Plataforma y de los mensajes que intercambiamos con usted
          ("Comunicaciones"). Esta Política de Privacidad sólo aplica a la
          información recopilada a través de la Plataforma y proporciona una
          descripción general de nuestras prácticas de privacidad y las
          elecciones que puede hacer, así como los derechos que puede ejercer en
          relación con los Datos Personales que procesamos. Si tiene alguna
          pregunta sobre el uso de sus Datos Personales, contáctenos utilizando
          el formulario disponible en este enlace. Esta Política de Privacidad
          no aplica a las aplicaciones, productos, servicios, sitios web o
          funciones de redes sociales de terceros que se pueden ofrecer o a los
          que se puede acceder a través de la Plataforma. Acceder a estos
          enlaces hará que abandone la Plataforma y puede resultar en la
          recopilación o el intercambio de información sobre usted por parte de
          terceros. No controlamos, respaldamos ni hacemos ninguna
          representación sobre estos sitios de terceros o sus prácticas de
          privacidad, las cuales pueden diferir de las nuestras. Le recomendamos
          que revise la política de privacidad de cualquier sitio web con el que
          interactúe antes de permitir la recopilación y el uso de sus datos
          personales. Si nos envía o comparte Datos Personales sobre otras
          personas, declara y garantiza que ha obtenido el consentimiento
          necesario para autorizar el uso de dicha información en los términos
          de esta Política de Privacidad conforme lo exigen las Leyes de
          Protección de Datos Personales.
        </div>
        <div class="terms-subtitle">
          1. Definiciones
        </div>
        <div class="terms-subtitle">
          1.1 A los efectos de esta Política de Privacidad:
        </div>
        <div class="terms-text">
          (i) “Datos Personales” significa cualquier información que, directa o
          indirectamente, identifique o pueda identificar a una persona natural
          o jurídica, como nombre, CPF, fecha de nacimiento, dirección IP, entre
          otros; <br />(ii) “Datos Personales Sensibles” significa cualquier
          información que revele el origen racial o étnico, la convicción
          religiosa, la opinión política, la afiliación a un sindicato u
          organización de carácter religioso, filosófico o político, datos
          relativos a la salud o vida sexual, datos genéticos o biométricos en
          relación con el titular del dato personal; <br />(iii) “Tratamiento de
          Datos Personales” significa cualquier operación realizada en el ámbito
          de los Datos Personales, a través de medios automáticos o no, tales
          como la recolección, registro, organización, estructuración,
          almacenamiento, adaptación o alteración, recuperación, consulta, uso,
          la divulgación por transmisión, difusión o, en su defecto, puesta a
          disposición, armonización o asociación, restricción, eliminación o
          destrucción. También se considera Tratamiento de Datos Personales
          cualquier otra operación prevista en los términos de la legislación
          aplicable; <br />(iv) “Leyes de Protección de Datos” significa todas
          las disposiciones legales que regulan el Tratamiento de Datos
          Personales, incluyendo, pero sin limitarse a, la Ley de Protección de
          Datos Personales Nro. 25.326, su decreto reglamentario Nro. 1558/2001
          y las disposición y resoluciones emitidas de tanto en tanto por la
          autoridad de aplicación.
        </div>
        <div class="terms-subtitle">
          2. Datos Personales recopilados
        </div>
        <div class="terms-subtitle">
          2.1 El público en general podrá navegar por la Plataforma sin
          necesidad de registrarse ni proporcionar sus Datos Personales, excepto
          aquellos que son recolectados en forma automática y que se detallan
          más adelante en el punto 2.3.(iii). Sin embargo, algunas de las
          características de la Plataforma pueden depender del registro y envío
          de Datos Personales.
        </div>
        <div class="terms-subtitle">
          2.2 Si usted se comunica con MALL, MALL puede recopilar:
        </div>
        <div class="terms-text">
          (i) Datos de contacto. Nombre, apodo, teléfono, ciudad, estado y
          dirección de correo electrónico; <br />(ii) Información que envíe.
          Información que nos envíe a través del formulario disponible (por
          ejemplo, quejas, sugerencias, críticas, felicitaciones, etc.).
        </div>
        <div class="terms-subtitle">
          2.3 En la navegación general por la Plataforma, Mall podrá recolectar:
        </div>
        <div class="terms-text">
          (i) Preferencias. Información sobre sus preferencias e intereses con
          respecto a la Plataforma (cuando nos dice cuáles son o cuando podemos
          inferirlo de su comportamiento en la Plataforma) y cómo prefiere
          recibir nuestras Comunicaciones; <br />(ii) Datos de navegación en la
          Plataforma. Información sobre sus visitas y actividades en la
          Plataforma, incluido el contenido (y cualquier publicidad) que ve y
          con el que interactúa, información sobre el navegador y el dispositivo
          que está utilizando, su dirección IP, su ubicación o la dirección del
          sitio web desde el que accede. Parte de la información se recopila
          utilizando nuestras herramientas de recopilación automática de datos,
          que incluyen cookies, balizas web y enlaces web integrados. Para
          obtener más información, lea cómo usamos las herramientas de
          recopilación automática de datos en el punto 6; <br />(iii) Datos
          seudonimizados o agregados.. Durante nuestras operaciones, en ciertos
          casos, aplicamos un proceso de desidentificación o seudonimización a
          sus Datos Personales para que sea razonablemente poco probable que se
          identifique mediante el uso de esos datos con la tecnología
          disponible; y <br />(iv) Otra información que podemos recopilar. Otra
          información que no revele específicamente su identidad o que no esté
          directamente relacionada con una persona, como la información del
          navegador y del dispositivo; datos de uso de la plataforma; e
          información recopilada a través de cookies, etiquetas de píxeles y
          otras tecnologías.
        </div>

        <div class="terms-subtitle">
          2.4. Si accede a nuestra página de carrera (Trabajá en MALL), MALL
          puede recopilar:
        </div>
        <div class="terms-text">
          (i) Datos de contacto. Nombre, apodo, teléfono, correo electrónico; y
          <br />(ii) Información incluida en su currículum (CV), tal como la
          expectativa salarial, o lo que sea compartido por usted al momento de
          acceder a nuestra página de carrera.
        </div>
        <div class="terms-subtitle">
          2.4.1. Al postularse para una vacante en MALL, acepta el procesamiento
          de sus datos personales, sensibles o no, así como que sus datos se
          mantengan en nuestra base de datos con el fin de reclutamiento para
          futuras vacantes.
        </div>
        <div class="terms-subtitle">
          2.5 En situaciones específicas (por ejemplo, encuestas que realizamos
          para conocer el perfil de nuestros clientes), podemos tratar Datos
          Sensibles de nuestros clientes directos y/o indirectos (como en el
          caso de los clientes de nuestros clientes). Cuando esto ocurra, dicho
          tratamiento se dará de conformidad con los requisitos establecidos en
          las Leyes de Protección de Datos Personales, en particular al obtener
          el consentimiento de nuestros clientes y/o consumidores, cuando sea
          necesario.
        </div>
        <div class="terms-subtitle">
          2.6. No está obligado a compartir con nosotros los Datos Personales
          que solicitamos; sin embargo, si elige no compartirlos, en algunos
          casos es posible que no podamos brindarle acceso completo a la
          Plataforma o a algunas funciones especializadas, o que no podamos para
          responder eficazmente a cualquier consulta que pueda tener. En los
          casos en que sea necesario su consentimiento para realizar una
          determinada actividad, le informaremos de las consecuencias de no
          facilitarlo.
        </div>
        <div class="terms-subtitle">
          3. Para qué recopilamos y usamos sus Datos Personales
        </div>
        <div class="terms-subtitle">
          3.1 Recopilamos y usamos Datos Personales para administrar su relación
          con MALL y brindarle un mejor servicio cuando utiliza la Plataforma,
          personalizando y mejorando su experiencia. Ejemplos de cómo usamos los
          Datos Personales incluyen:
        </div>
        <div class="terms-text">
          Para que Usted pueda registrarse, acceder, utilizar y navegar la
          Plataforma; Para verificar y validar su identidad; Para prestar
          nuestros servicios a través de la Plataforma, procesar sus solicitudes
          y transacciones, elaborar y mantener un registro de las operaciones
          que realice en la Plataforma y darles seguimiento; Para responder a
          los requerimientos y consultas que realice a través de la Plataforma,
          ya sea acerca de los productos y servicios de MALL y/u otros; Para
          mantener contacto con los Usuarios, atender los comentarios, quejas y
          sugerencias; Para cobrar y facturar las cantidades previstas en los
          servicios contratados por usted; Para confirmar o corregir la
          información que tenemos sobre usted; Para enviarle información que
          creemos será de su interés, a través de comunicaciones de marketing;
          Para personalizar su experiencia de uso de la Plataforma; Para
          comunicarnos con usted a un número de teléfono y/o dirección de correo
          electrónico proporcionados, con fines de soporte, resolución de
          problemas y, cuando sea necesario, para fines comerciales razonables,
          como enviarle información promocional de productos o servicios de MALL
          o de nuestro socios; Para mejorar los servicios y las funcionalidades
          de la Plataforma Para cumplir con los requisitos de un proceso legal;
          Para cumplir con una decisión judicial o de autoridad administrativa
          competente, incluidos los tribunales y las autoridades fuera del país
          de residencia de los Usuarios; Para hacer cumplir nuestros Términos y
          condiciones de uso; Para proteger nuestras operaciones; Para proteger
          los derechos, la privacidad, la seguridad de MALL, la suya o la de
          otros Usuarios o terceros; Para detectar y prevenir el fraude; Para
          permitirnos utilizar los recursos disponibles o limitar los daños que
          podamos sufrir; Para realizar encuestas de calidad, para conocer cómo
          es su relación con nosotros y los servicios que le brindamos (por
          ejemplo, la jornada empresarial y las estrategias aplicadas en su
          tienda virtual en un año determinado); Para preparar nuestro informe
          anual de comercio electrónico, NuvemCommerce; y De otras formas
          permitidas por la ley.
        </div>
        <div class="terms-subtitle">
          3.2 Si visita nuestra página de carreras y está interesado en una de
          las vacantes ofrecidas, también podemos procesar sus Datos personales
          como parte de nuestro proceso de reclutamiento y selección.
        </div>
        <div class="terms-subtitle">
          4. Terceros con quienes compartimos sus Datos Personales
        </div>
        <div class="terms-subtitle">
          4.1 MALL puede compartir sus Datos Personales:
        </div>
        <div class="terms-text">
          (i) Con la(s) empresa(s) asociada(s) que seleccione o elija para
          enviar sus datos, consultas, preguntas, etc., así como también con
          proveedores de servicios o socios de MALL para administrar o respaldar
          ciertos aspectos de nuestras operaciones comerciales en nuestro
          nombre, incluyendo proveedores de servidores para aprobación y
          producción; proveedores de alojamiento y almacenamiento de datos;
          proveedores de validación de identidad, gestión de fraudes, atención
          al cliente, ventas en nuestro nombre, cumplimiento de pedidos,
          personalización de contenido, actividades de publicidad y marketing
          (incluida la publicidad digital y personalizada) y servicios de TI;
          empresas procesadoras de pagos; proveedores de sistemas informáticos;
          proveedores de servicios en la nube; proveedores de servicios
          tecnológicos en general, call centers o centros de atención al
          cliente; agencias de publicidad o marketing; agencias de análisis de
          datos; consultores de seguros, legales y/o financieros; otros agentes
          y representantes, por ejemplo: <br />(ii) Con terceros, con el fin de
          ayudarnos a administrar la Plataforma; <br />(iii) Con terceros,
          cuando sea necesario, para coadyuvar en los procesos de reclutamiento
          y selección; y <br />(iv) Con entidades financieras y cualquier otra
          empresa que pudiera ser necesaria para prestar los Servicios ofrecidos
          mediante la Plataforma. Los Datos Personales de los Usuarios no serán
          utilizados por los terceros proveedores de servicios para ningún otro
          propósito que aquel para el cual MALL se los transfirió y siempre los
          procesarán siguiendo las instrucciones de MALL. <br />(v) Ante un
          proceso de auditoría en caso de una potencial reorganización, fusión,
          venta, asociación en participación, cesión, transferencia de la
          totalidad o parte de nuestra empresa, activos o capital, con los
          potenciales adquirentes y/o sus asesores, siempre bajo obligación de
          confidencialidad. <br />(vi) Con terceros adquirentes de cualquier
          reorganización, fusión, venta, asociación en participación, cesión,
          transferencia o transferencia de la totalidad o parte de nuestra
          empresa, activos o capital (incluidos los relacionados con procesos
          concursales o similares), quienes pasarán a ser los responsables del
          procesamiento de los Datos Personales. <br />(vii) Con entidades
          pertenecientes al mismo grupo corporativo de o empresas asociadas a
          MALL en todo el mundo de acuerdo con esta Política de Privacidad.
        </div>
        <div class="terms-subtitle">
          5. Transferencias internacionales de Datos Personales
        </div>
        <div class="terms-subtitle">
          5.1 Cuando sea necesario transferir Datos Personales, MALL adoptará
          las salvaguardas necesarias para que sus Datos Personales tengan un
          nivel de protección equivalente al que hemos adoptado y, cuando sea
          necesario, utilizaremos el mecanismo de transferencia internacional de
          datos requerido por las leyes de protección de datos.
        </div>
        <div class="terms-subtitle">
          5.2 Usted expresamente entiende y acepta que sus Datos Personales
          pueden ser compartidos, cedidos o transferidos fuera de su país de
          residencia para su procesamiento para las finalidades descriptas en la
          presente Política de Privacidad incluso cuando los destinatarios estén
          ubicados en otros países que no garanticen los mismos niveles de
          protección que las Leyes de Protección de Datos Personales.
        </div>
        <div class="terms-subtitle">
          6. Forma de recolección automática de Datos Personales
        </div>
        <div class="terms-subtitle">
          6.1 Cuando visita la Plataforma, esta puede almacenar o recuperar
          información en su navegador, principalmente mediante el uso de
          cookies, que son archivos de texto que contienen pequeñas cantidades
          de información.
        </div>
        <div class="terms-subtitle">
          6.2 Esta información puede ser sobre usted, sus preferencias o su
          dispositivo y se usa principalmente para hacer que la Plataforma
          funcione como usted espera. La información generalmente no lo
          identifica directamente, pero puede proporcionar una experiencia web
          más personalizada.
        </div>
        <div class="terms-subtitle">
          6.3 De acuerdo con esta Política de Privacidad de MALL, nosotros y
          nuestros proveedores de servicios externos podemos recopilar sus Datos
          personales de varias maneras, que incluyen, entre otras:
        </div>
        <div class="terms-text">
          (i) A través del navegador o dispositivo: la mayoría de los
          navegadores o dispositivos de acceso a Internet recopilan cierta
          información, como el tipo de computadora, la resolución de pantalla,
          el nombre y la versión del sistema operativo, el modelo y el
          fabricante del dispositivo, el idioma, el tipo y la versión del
          navegador de Internet que esté utilizando. Podemos utilizar esta
          información para garantizar que la Plataforma funcione correctamente.
          <br />(ii) Uso de cookies: La información sobre su uso de la
          Plataforma puede ser recopilada por terceros a través de cookies. Las
          cookies son información almacenada directamente en la computadora que
          está utilizando. Las cookies permiten recopilar información como el
          tipo de navegador, el tiempo que se pasa en la Plataforma, las páginas
          visitadas, las preferencias de idioma y otros datos de tráfico
          anónimos. Nosotros y nuestros proveedores de servicios usamos
          información para proteger la seguridad, facilitar la navegación,
          mostrar información de manera más eficiente y personalizar su
          experiencia al usar la Plataforma, así como para el seguimiento en
          línea. También recopilamos información estadística sobre su uso de la
          Plataforma para mejorar continuamente nuestro diseño y funcionalidad,
          para comprender cómo se usa la Plataforma y para ayudarlo a resolver
          problemas relacionados con la Plataforma.
        </div>
        <div class="terms-subtitle">
          7. Duración del tratamiento
        </div>
        <div class="terms-subtitle">
          7.1 Los Datos Personales procesados por MALL se almacenarán durante el
          tiempo que sea necesario para cumplir con los fines para los que
          fueron recopilados.
        </div>
        <div class="terms-subtitle">
          7.2 Sin embargo, los Datos Personales pueden conservarse por un
          período más largo, para cumplir con una obligación legal o
          reglamentaria, para cumplir con una orden de autoridad competente, o
          bien para el ejercicio del derecho de defensa de MALL en
          procedimientos judiciales o administrativos por todo el plazo de
          prescripción de los mismos.
        </div>
        <div class="terms-subtitle">
          8. Derechos del usuario
        </div>
        <div class="terms-subtitle">
          8.1. Usted puede, en cualquier momento, ejercer los derechos que las
          Leyes de Protección de Datos Personales le garantizan como titular de
          datos, entre otros, Usted puede solicitar a MALL:
        </div>
        <div class="terms-text">
          (i) confirmación de que sus Datos Personales están siendo procesados;
          <br />(ii) acceso a sus Datos Personales; <br />(iii) corrección de
          datos incompletos, inexactos o desactualizados; <br />(iv)
          anonimización, bloqueo o eliminación (en caso de corresponder) de
          datos innecesarios, excesivos o tratados en violación de la ley;
          <br />(v) la portabilidad de los Datos Personales a otro proveedor de
          servicios, siempre que ello no afecte nuestros secretos industriales y
          comerciales; <br />(vi) la eliminación de los Datos Personales
          procesados con su consentimiento, en la medida permitida por la ley;
          <br />(vii) información sobre las entidades con las que se han
          compartido sus Datos Personales; <br />(viii) información sobre la
          posibilidad de no prestar el consentimiento y las consecuencias de no
          prestar el consentimiento; y <br />(ix) revocación del consentimiento.
        </div>
        <div class="terms-subtitle">
          8.2 Sus solicitudes serán tratadas con especial cuidado para que
          podamos garantizar la efectividad de sus derechos. Como resultado,
          podemos pedirle que proporcione una prueba de su identidad para
          garantizar que los Datos personales solo se compartan con el titular.
        </div>
        <div class="terms-subtitle">
          8.3 Debe tener en cuenta que, en determinados casos (por ejemplo,
          debido a requisitos legales o limitaciones técnicas), es posible que
          su pedido no se satisfaga de inmediato y/o que MALL no pueda cumplirlo
          debido a la necesidad de cumplimiento de obligaciones legales.
        </div>
        <div class="terms-subtitle">
          8.4. Le informamos que la autoridad en materia de protección de datos
          de su país de residencia podría tener facultades para atender las
          denuncias y reclamos que interpongan quienes resulten afectados en sus
          derechos por incumplimiento de las normas vigentes en materia de
          protección de datos personales.
        </div>
        <div class="terms-subtitle">
          9. Seguridad de los Datos Personales
        </div>
        <div class="terms-subtitle">
          9.1 Procuramos adoptar en nuestra organización las medidas técnicas y
          organizativas previstas por las Leyes de Protección de Datos y que
          sean adecuadas para la protección de los Datos Personales.
          Desafortunadamente, no podemos garantizar que ningún sistema de
          transmisión o almacenamiento de datos sea 100% seguro. Si tiene
          motivos para creer que su interacción con nosotros ya no es segura
          (por ejemplo, si cree que la seguridad de cualquiera de sus cuentas se
          ha visto comprometida), notifíquenos de inmediato.
        </div>
        <div class="terms-subtitle">
          10. Enlaces de hipertexto a otros sitios web y redes sociales
        </div>
        <div class="terms-subtitle">
          10.1 La Plataforma puede contener enlaces de hipertexto que lo
          redirigirán a los sitios web de las redes, anunciantes, proveedores,
          etc. de nuestros socios. Si hace clic en uno de estos enlaces a
          cualquiera de estos sitios, le recordamos que cada sitio tiene sus
          propias prácticas de privacidad y que no somos responsables de esas
          políticas. Consulte esas políticas antes de enviar Datos Personales a
          estos sitios.
        </div>
        <div class="terms-subtitle">
          10.2 No somos responsables de las políticas y prácticas de
          recopilación, uso y divulgación (incluidas las prácticas de protección
          de datos) de otras organizaciones, como Facebook, Apple, Google,
          Microsoft o cualquier otro desarrollador de software o proveedor de
          aplicaciones, plataformas de redes sociales, operadores sistema,
          proveedor de servicios inalámbricos de Internet o fabricante del
          dispositivo, incluidos los datos personales que divulgue a otras
          organizaciones a través de las aplicaciones, en relación con dichas
          aplicaciones o publicados en nuestras páginas de redes sociales. Le
          recomendamos que se informe sobre la política de privacidad de cada
          sitio web que visite o de cada proveedor de servicios que utilice.
        </div>
        <div class="terms-subtitle">
          11. Uso de la Plataforma por Menores
        </div>
        <div class="terms-subtitle">
          11.1 La plataforma no está destinada a personas menores de 18
          (dieciocho) años y solicitamos que dichas personas no nos proporcionen
          ningún dato personal.
        </div>
        <div class="terms-subtitle">
          Actualizaciones a esta Política de Privacidad
        </div>
        <div class="terms-subtitle">
          12.1 Si modificamos nuestra Política de Privacidad, publicaremos el
          nuevo texto en la Plataforma, con la fecha de revisión actualizada.
          Podemos cambiar esta Política de Privacidad en cualquier momento. Si
          hay un cambio sustancial en los términos de esta Política de
          Privacidad, podemos informarle a través de la información de contacto
          que tenemos en nuestra base de datos o mediante una notificación en
          nuestra Plataforma y recabaremos su consentimiento en caso de ser
          necesario bajo la Ley de Protección de Datos Personales. .
        </div>
        <div class="terms-subtitle">
          12.2 Le recordamos que nos comprometemos a no procesar sus Datos
          Personales de manera incompatible con los fines descritos
          anteriormente, a menos que la ley o una orden judicial exijan lo
          contrario.
        </div>
        <div class="terms-subtitle">
          13. Responsable del Tratamiento de Datos Personales
        </div>
        <div class="terms-subtitle">
          13.1 NOMBRE de la Empresa MALL., con domicilio AV. J. NEWBERY - 5005 MAR DEL PLATA NORTE 7600-BUENOS AIRES, ARGENTINA,
          es la responsable del Tratamiento de sus Datos Personales en la
          Plataforma. Si pretende ponerse en contacto con nuestro Oficial del
          Tratamiento de Datos Personales y/o ejercer alguno de los derechos
          previstos en esta Política de Privacidad y/o en las Leyes de
          Protección de Datos, o resolver cualquier duda relacionada con el
          Tratamiento de sus Datos Personales, póngase en contacto con nosotros
          a través del formulario disponible en este enlace
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
