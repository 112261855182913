import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { ref } from "vue";
import { usePopStore, useUserStore, useAddressStore } from "@/store/stores";


export const usePurchaseStore = defineStore(
  "purchase",
  () => {
    const $P = usePopStore();
    const $U = useUserStore();
    const $A = useAddressStore();

    const step = ref(1);
    const shipping = ref({
      address: null,
      method: {},
      shippingPrice: 0
    });
    const cart = ref({
      merchants: {},
      totalPrice: 0,
      totalDiscount: 0
    });
    const payment = ref({
      card: null,
      securityCode: ""
    });
    const discount = ref([]);
    const preOrders = ref([]);
    const installmentsPlans = ref([]);
    const payOrderBodies = ref([]);

    const resetPurchase = () => {
      $A.branchId = 0;
      step.value = 1;
      shipping.value = {
        address: null,
        method: {},
        shippingPrice: 0
      };
      cart.value = {
        merchants: {},
        totalPrice: 0,
        totalDiscount: 0
      };
      payment.value = {
        card: null,
        securityCode: ""
      };
      discount.value = [];
      preOrders.value = [];
      installmentsPlans.value = [];
      payOrderBodies.value = [];
    };

    const generatePreOrders = async () => {
      preOrders.value = [];
      for (const key in cart.value.merchants) {
        const orderItems = cart.value.merchants[key].products.map(p => {
          return {
            articleVariantId: p.variantId,
            quantity: p.quantity
          };
        });
      
        const generatePreOrderBody = {
          branchId: shipping.value.method === "branch" ? shipping.value.address.IdCentroImposicion : undefined,
          branchPostalCode: shipping.value.method === "branch" ? shipping.value.address.CodigoPostal.toString() : undefined,
          orderItems,
          orderItemsDiscount: cart.value.merchants[key].discount,
          pickUpStore: shipping.value.method === "pickUp",
          useBranch: shipping.value.method === "branch",
          userClientAddressId: $U.currentUser.address.id,
        };
        const res = await fetchWrapper.post("orders/generatePreOrder", generatePreOrderBody);
        preOrders.value.push(res.data.preOrder);
      }
      console.log("preOrders", preOrders.value);
      return preOrders;
    };

    const makeOrder = async (order, merchant) => {
      try {
        const res = await fetchWrapper.post("orders", order);
        return {
          order: res.data.order,
          merchant: merchant
        };
      } catch (e) {
        console.log(e);
        return {
          order: false,
          merchant: merchant
        };
      }
    };

    const getOrders = async () => {
      try {
        const res = await fetchWrapper.get(
          `orders/userClient/${$U.currentUser.id}?limit=500&count=true&orderBy=created_at&orderCriteria=DESC&isDeleted=false`
        );
        return res.data;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al obtener las ordenes", 0);
      }
    };

    const getOrder = async (orderId) => {
      try {
        const res = await fetchWrapper.get(`orders/${orderId}`);
        return res.data;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al obtener la orden", 0);
      }
    };

    const getOpetativas = async () => {
      try {
        const res = await fetchWrapper.get("oca/operativas");
        return res.data;
      } catch (e) {
        $P.popUpHandler("Hubo un error al obtener las operativas", 0);
      }
    };

    const getShippingCost = async (operativa, postalDestination, orderItems, merchantConfig) => {
      try {
        const data = {
          operativa: operativa,
          codigoPostalDestino: postalDestination,
          orderItems: orderItems,
          shippingPercentageDiscount: merchantConfig
        };
        const res = await fetchWrapper.post("oca/getShippingCost", data);
        return res.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    };

    const deleteOrder = async (orderId) => {
      try {
        await fetchWrapper.delete(`orders/${orderId}`);
        return true;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al eliminar la orden", 0);
        return false;
      }
    };

    const payOrder = async data => {
      try {
        console.log("DATA PAYORDER", data);
        const res = await fetchWrapper.post("payments/payOrder", data);
        console.log("DATA RESPUESTA PAYORDER", res.data);
        $P.popUpHandler("Pagaste exitosamente tu pedido. ¡Gracias por confiar en nosotros!");
        return true;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al pagar la orden", 0);
        return false;
      }
    };

    const validateOrderItem = async (orderItemId) => {
      try {
        const res = await fetchWrapper.get(`orderItems/validateIfOrderItemIsReadyToBePaid/${orderItemId}`);
        console.log(res);
      } catch(e) {
        console.log(e);
      }
    };

    return {
      discount,
      cart,
      step,
      shipping,
      payment,
      preOrders,
      installmentsPlans,
      payOrderBodies,
      resetPurchase,
      generatePreOrders,
      makeOrder,
      getOrders,
      getOrder,
      getOpetativas,
      getShippingCost,
      deleteOrder,
      payOrder,
      validateOrderItem
    };
  },
  {
    persist: true,
  }
);
