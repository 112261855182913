<script setup>
//vue
import { ref, onMounted, watch, computed } from "vue";
//vue-router
import { useRoute } from "vue-router";
//store
import { useProductStore } from "@/store/stores";
//components
import ProfileCompletation from "@/components/shared/ProfileCompletation.vue";
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";
import CategoriesCarrousel from "@/components/shared/CategoriesCarrousel.vue";

const route = useRoute();
const $P = useProductStore();

const loading = ref(true);
const searchProducts = ref(null);
const users = ref(null);
const merchants = ref(null);
const searchParam = computed(() => route.params.search);
const count = ref();

const loadMore = async () => {
  if(searchProducts.value.length < count.value) {
    const res = await $P.getBySearch(searchParam.value, searchProducts.value.length);
    searchProducts.value.push(...res.articles);
    return true;
  } else {
    return false;
  }
};

const filter = async (data) => {
  if(data) {
    searchProducts.value = null;
    const res = await $P.getBySearch(searchParam.value, 0);
    count.value = res.count;
    console.log(count.value);
    searchProducts.value = res.articles;
  }
};

onMounted(async () => {
  const promises = [];
  const get_merchant = $P.getMerchantBySearch(searchParam.value);
  const get_users = $P.getUserBySearch(searchParam.value);
  const get_products = $P.getBySearch(searchParam.value, 0);
  promises.push(get_merchant, get_users, get_products);
  await Promise.all(promises).then((res) => {
    console.log(res);
    merchants.value = res[0].map(obj => ({
      image: obj.image,
      nameCategory: obj.name,
      id: obj.id,
      type: "merchant",
      path: `/merchant/${obj.id}`
    }));
    users.value = res[1].map(obj => ({
      image: obj.image,
      nameCategory: obj.firstName + " " + obj.lastName,
      username: obj.username,
      id: obj.id,
      type: "client",
      path: `/profile/${obj.username}`,
      initials: obj.firstName[0] + obj.lastName[0]
    }));

    count.value = res[2].count;
    searchProducts.value = res[2].articles;
  });
  loading.value = false;
});

watch(searchParam, async (newSearch) => {
  searchProducts.value = await $P.getBySearch(newSearch, 0);
});

</script>

<template>
  <div class="loading" v-if="loading">
    <img class="manijita" src="../assets/logos/manijita.svg">
    <img class="bolsa" src="../assets/logos/bolsa.svg">
  </div>
  <div v-else class="searchview">

    <h1 v-if="merchants?.length" class="center">Tiendas</h1>
    <CategoriesCarrousel v-if="merchants?.length" :data="merchants" />

    <h1 v-if="users?.length" class="center">Usuarios</h1>
    <CategoriesCarrousel v-if="users?.length" :data="users" />

    <h1 v-if="searchProducts?.length">Productos</h1>
    <div v-if="searchProducts?.length" class="search-container">
      <InfiniteComponent :products="searchProducts" :function="loadMore" :full="true" />
      <ProfileCompletation v-if="searchProducts?.length" @apply-filter="filter" />
    </div>

    <div v-if="!merchants?.length && !users?.length && !searchProducts?.length" class="empty-data">
      <img src="../assets/shoesize-modal-blue.svg" alt="svg">
      <span>¡Ups!</span>
      <p>No se encontro nada al buscar "{{ searchParam }}"</p>
    </div>
  </div>
</template>

<style scoped>
.center {
  text-align: center;
}
</style>