<script setup>
/* eslint-disable */
import { ref, onMounted } from "vue";
import { useProductStore, useSaveStore } from "@/store/stores";
import CardComponent from "@/components/shared/CardComponent.vue";

const $P = useProductStore();
const $S = useSaveStore();

const saved = ref([]);
const loaded = ref(false);

onMounted(async () => {
  if($S.save.length > 0) {
    const saveIds = {
      ids: $S.save.map(p => p)
    };
    saved.value = await $P.getByIds(saveIds);
  }
  loaded.value = true;
});
</script>

<template>
  <div v-if="!loaded"></div>
  <div v-else-if="saved.length > 0" class="favorite-container">
    <h1>Mis productos guardados</h1>
    <div class="cards">
      <CardComponent v-for="(product, index) in saved" :data="product" :key="index" />
    </div>
  </div>
  <div v-else class="empty-data">
    <img src="../../assets/shoesize-modal-blue.svg" alt="svg">
    <span>Guardados</span>
    <p>Todavia no guardaste ningun producto. Agregue los que mas te gusten para recibir noticias de los mismos.</p>
  </div>
</template>

<style>
.cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
</style>