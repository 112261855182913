import { ref } from "vue";
import { defineStore } from "pinia";
import { usePopStore, useUserStore, usePurchaseStore } from "@/store/stores";
import { fetchWrapper } from "@/helpers/fetchWrapper";

export const usePaymentStore = defineStore("payments", () => {
  const $P = usePopStore();
  const $U = useUserStore();
  const $C = usePurchaseStore();

  const paymentCards = ref();

  const getAllCards = async () => {
    try {
      const res = await fetchWrapper.get("paymentCards/userClient?isDeleted=false");
      const allCards = res.data;
      const index = allCards.findIndex((c) => c.id === $U.currentUser.mainPaymentCardId);
      const card = allCards.find((c) => c.id === $U.currentUser.mainPaymentCardId);
      if (index !== -1) {
        allCards.splice(index, 1);
        allCards.unshift(card);
        paymentCards.value = allCards;
      } else {
        paymentCards.value = allCards;
      }
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar las tarjetas", 0);
    }
  };

  const getCard = async (cardId) => {
    try {
      const res = await fetchWrapper.get(`paymentCards/${cardId}`);
      return res.data;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al carga la tarjeta", 0);
    }
  };

  const validateCard = async (id, userClientId, securityCode, lastFourDigits) => {
    try {
      await fetchWrapper.post("paymentCards/validate", {id, userClientId, securityCode, lastFourDigits});
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  };

  const getInstallment = async () => {
    const installmentsPlansByPreOrders = [];
    for (const preOrder of $C.preOrders) {
      try {
        const res = await fetchWrapper.post("zenrise/getInstallmentsPlan", {
          paymentCardId: $C.payment.card.id,
          order: preOrder
        });
        installmentsPlansByPreOrders.push({
          installmentsPlan: res.data.installmentsPlan,
          userMerchant: preOrder.userMerchant,
          installment: null,
        });
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al carga las cuotas", 0);
        return false;
      }
    }
    console.log(installmentsPlansByPreOrders);
    $C.installmentsPlans = installmentsPlansByPreOrders;
    return true;
  };

  const getInstallmentByOrder = async (order, cardId) => {
    try {
      const res = await fetchWrapper.post("zenrise/getInstallmentsPlan", {
        paymentCardId: cardId,
        order: order
      });
      return res.data.installmentsPlan;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al carga las cuotas", 0);
      return false;
    }
  };

  const newCard = async (card) => {
    try {
      const res = await fetchWrapper.post("paymentCards", card);
      await updateMainPaymentCardId(res.data.id);
      $P.popUpHandler("Tarjeta agregada exitosamente");
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al crear la tarjeta.", 0);
    }
  };

  const deleteCard = async (cardId) => {
    try {
      await fetchWrapper.delete(`paymentCards/userClient/${cardId}`);
      paymentCards.value = paymentCards.value.filter(
        (card) => card.id !== cardId
      );
      if ($U.currentUser.mainPaymentCardId === cardId) {
        if (paymentCards.value.length !== 0) {
          const newId = paymentCards.value[0].id;
          await updateMainPaymentCardId(newId);
        } else {
          await updateMainPaymentCardId(null);
        }
      }
      $P.popUpHandler("Tarjeta eliminada exitosamente");
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al eliminar la tarjeta", 0);
    }
  };

  const updateMainPaymentCardId = async (cardId) => {
    try {
      const res = await fetchWrapper.put(`clients/users/${$U.currentUser.id}`, {
        mainPaymentCardId: cardId,
      });
      $U.currentUser = res.data.updatedUserClient;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    paymentCards,
    getAllCards,
    getCard,
    validateCard,
    getInstallment,
    getInstallmentByOrder,
    newCard,
    deleteCard,
    updateMainPaymentCardId,
  };
});
