<script setup>
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { RouterLink } from "vue-router";
//components
import PaymentCard from "@/components/shared/PaymentCard.vue";
//store
import { usePaymentStore } from "@/store/stores";

const $P = usePaymentStore();

const paymentCards = computed(() => $P.paymentCards);
const loaded = ref(false);

onMounted(async() => {
  await $P.getAllCards();
  loaded.value = true;
});
</script>

<template>
  <div v-if="loaded" class="payments">
    <div class="payments-title">
      <h1>Tarjetas</h1>
      <RouterLink :to="`/profile/info/add/paymentCard`" class="blue-button"><v-icon>mdi-plus</v-icon></RouterLink>
    </div>

    <div v-if="paymentCards?.length === 0" class="empty-data">
      <img src="../../../assets/shoesize-modal-blue.svg" alt="svg">
      <span>Agrega tu tarjeta tocando "+"</span>
      <p>Todavia no agregaste ninguna tarjeta.</p>
    </div>

    <div v-else class="payments-cards">
      <PaymentCard v-for="(cardInfo, index) in paymentCards" :key="index" :data="cardInfo" :delete="true" />
    </div>
    
  </div>
</template>