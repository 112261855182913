<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//vue-router
import { RouterView, useRouter } from "vue-router";
//stores
import { usePurchaseStore, useWindowStore, useCartStore } from "@/store/stores";
//helpers
import { capitalize } from "@/helpers/capitalize";

const router = useRouter();
const $P = usePurchaseStore();
const $C = useCartStore();
const $W = useWindowStore();

const products = ref([]);
const modal = ref(false);

onMounted(async () => {
  if($P.cart.totalPrice !== 0) {
    for (const key in $P.cart.merchants) {
      const p = $P.cart.merchants[key].products;
      products.value.push(...p);
    }
  }
});

</script>

<template>
  <div class="purchase">
    <div class="view-container">
      <RouterView />
    </div>
    <div v-if="$W.windowWidth > 768" class="purchase-items">
      <p class="purchase-title">Resumen de compra</p>
      <div class="items-list">
        <div class="item" v-for="(product, index) in products" :key="index">
          <img :src="product.image" :alt="product.name">
          <div>
            <strong>{{ product.name }} x{{ product.quantity }}</strong>
            <div>
              <template v-for="values in Object.entries(product.attributes)">
                <p>{{ capitalize(values[0]) }}: {{ values[1].toUpperCase() }}</p>
              </template>
            </div>
          </div>
          <p>${{ (product.isOnPromotion ? product.promotionalPrice : product.price).toFixed() * product.quantity }}</p>
        </div>
      </div>
      <div class="item-subprice">
        <div v-if="$P.step !== 1 || $P.cart.totalDiscount > 0" class="item-price">
          <p>Subtotal</p>
          <strong>${{ $P.cart.totalPrice }}</strong>
        </div>
        <div v-if="$P.shipping.shippingPrice !== 0" class="item-price">
          <p>Costo de envio</p>
          <strong>${{ $P.shipping.shippingPrice }}</strong>
        </div>
        <template v-if="$P.cart.totalDiscount > 0">
          <div class="item-price">
            <p>Descuento</p>
            <strong>-${{ $P.cart.totalDiscount.toFixed() }}</strong>
          </div>
        </template>
      </div>
      <template v-if="$P.step === 4">
        <div v-for="(plan, index) in $P.installmentsPlans" :key="index" class="item-finalPrice">
          <p>Plan de {{ plan.userMerchant.name }}</p>
          <div class="fp">
            <strong>{{ plan.installment.actualInstallments }}x ${{ plan.installment.amountPerInstallment.toFixed() }}</strong>
            <p>${{ plan.installment.totalAmount.toFixed() }}</p>
          </div>
        </div>
      </template>
      <div v-else class="item-finalPrice">
        <p>Total</p>
        <strong v-if="$P.step === 4">${{ $C.totalAmountToPay() }}</strong>
        <strong v-else>${{ ($P.cart.totalPrice + $P.shipping.shippingPrice - $P.cart.totalDiscount).toFixed() }}</strong>
      </div>
    </div>

    <!-- MOBILE RESUME -->
    <div class="purchase-items__mobile" v-else @click="modal = !modal">
  
      <div class="header">
        <strong class="purchase-title">Resumen de compra</strong>
      </div>

      <div class="list" :class="{activeList: modal}">
        <div class="wrapper">
          <div class="item" v-for="(product, index) in products" :key="index">
            <img :src="product.image" :alt="product.name">
            <div class="item-details">
              <strong>{{ product.name }} x{{ product.quantity }}</strong>
              <template v-for="values in Object.entries(product.attributes)">
                <p>{{ capitalize(values[0]) }}: {{ values[1].toUpperCase() }}</p>
              </template>
            </div>
            <p>${{ (product.promotionalPrice ? product.promotionalPrice : product.price) * product.quantity }}</p>
          </div>
          <div v-if="$P.step !== 1 || $P.cart.totalDiscount > 0" class="subitem">
            <p>Subtotal</p>
            <strong>${{ $P.cart.totalPrice }}</strong>
          </div>
          <div v-if="$P.shipping.shippingPrice !== 0" class="subitem">
            <p>Costo de envio</p>
            <strong>${{ $P.shipping.shippingPrice }}</strong>
          </div>
          <template v-if="$P.cart.totalDiscount > 0">
            <div class="item-price">
              <p>Descuento</p>
              <strong>-${{ $P.cart.totalDiscount.toFixed() }}</strong>
            </div>
          </template>
          <template v-if="$P.step === 4">
            <div v-for="(plan, index) in $P.installmentsPlans" :key="index" class="subitem">
              <p>Plan de {{ plan.userMerchant.name }}</p>
              <div class="fp">
                <strong>{{ plan.installment.actualInstallments }}x ${{ plan.installment.amountPerInstallment.toFixed() }}</strong>
                <p>${{ plan.installment.totalAmount.toFixed() }}</p>
              </div>
            </div>
          </template>
          <div v-else class="subitem">
            <p>Total</p>
            <strong v-if="$P.step === 4">${{ $C.totalAmountToPay() }}</strong>
            <strong v-else>${{ ($P.cart.totalPrice + $P.shipping.shippingPrice - $P.cart.totalDiscount).toFixed() }}</strong>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>