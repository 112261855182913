<script setup>
/* eslint-disable */
//vue
import { ref, computed, watch } from "vue";
//vue-router
import { useRouter, RouterLink } from "vue-router";
//stores
import { useCartStore, usePurchaseStore, useUserStore } from "@/store/stores";
import { capitalize } from "@/helpers/capitalize";

const router = useRouter();
const $C = useCartStore();
const $P = usePurchaseStore();
const $U = useUserStore();

const button = ref(true);
const step = ref(0);
const outStock = ref(null);

const totalProducts = () => {

  const totalProducts = [];
  for (const key in $C.currentCart.merchants) {
    $C.currentCart.merchants[key].products.map(p => {
      totalProducts.push(p.quantity);
    });
  }
  return totalProducts.reduce((a, b) => a + b, 0);
};

const purchase = async () => {
  button.value = false;
  const res = await $C.checkStock(Object.values($C.currentCart.merchants).flatMap(x => x.products));
  button.value = true;
  if(!$U.currentUser) {
    $C.cartModal = false;
    router.push("/logToContinue");
  } else if (res === true) {
    $P.resetPurchase();
    $P.cart.merchants = JSON.parse(JSON.stringify($C.currentCart.merchants));
    $P.cart.totalPrice = $C.totalPrice();
    $P.cart.totalDiscount = $C.totalDiscount();
    $P.discount.push(...$C.getOrderItemsDiscount());
    $C.cartModal = false;
    router.push(`/cart/purchase/shipping-options`);
  } else {
    outStock.value = res;
    step.value = 1;
  }
};
</script>

<template>
  <v-dialog v-model="$C.cartModal">
    <template v-slot:activator="{ props }">
      <div class="cart-icon">
        <v-icon v-bind="props" icon="mdi-cart-heart" /> 
        <span v-if="totalProducts() > 0">{{ totalProducts() }}</span>
      </div>
    </template>
    <div v-if="Object.keys($C.currentCart.merchants).length > 0" class="cart-container">
      <v-icon class="close" @click="$C.cartModal = false">mdi-close</v-icon>
      <h2 v-if="step === 0">Productos del carrito</h2>
      <h2 v-else>Conflictos</h2>
      <div class="merchant-container__cart">

        <div v-if="step === 0" class="cart-merchant" v-for="(merchant, index) in $C.currentCart.merchants" :key="index">
          <div v-if="merchant.merchant.image" class="merchant-img">
            <img :src="merchant.merchant.image">
          </div>
          <h1 v-else class="merchant-name">{{ merchant.merchant.name }}</h1>
          <div class="cart-product" v-for="(product, index) in merchant.products" :key="index">
            <RouterLink :to="`/producto/${product.id}`"><img :src="product.image" :alt="product.name"></RouterLink>
            <div class="cart-info">
              <div class="cart-name">
                <RouterLink :to="`/producto/${product.id}`"><p>{{ product.name }}</p></RouterLink>
                <div v-if="product?.promotion">
                  <span :class="{active: product?.quantity % Number(product?.promotion.type.split('x')[0]) === 0}">{{ product?.promotion.type }}</span>
                </div>
                <template v-if="product?.attributes">
                  <span v-for="values in Object.entries(product?.attributes)">{{ capitalize(values[0]) }}: {{ values[1].toUpperCase() }}</span>
                </template>
              </div>
              <div class="cart-price">
                <div class="divider">
                  <div class="cart-counter">
                    <button @click="$C.subtract(merchant.merchant.id, index)">
                      <v-icon>mdi-minus</v-icon>
                    </button>
                    <span>{{ product.quantity }}</span>
                    <button @click="$C.add(merchant.merchant.id, index)"><v-icon>mdi-plus</v-icon></button>
                  </div>
                  <div v-if="product.isOnPromotion" class="promotion">
                    <span>${{ product.price * product.quantity }}</span>
                    <p>${{ product.promotionalPrice * product.quantity }}</p>
                  </div>
                  <p v-else>${{ product.price * product.quantity }} </p>
                </div>
                <button class="delete" @click="$C.deleteItem(merchant.merchant.id, index)"><v-icon>mdi-trash-can-outline</v-icon></button>
                <span v-if="product.quantity === product.stock" class="stock">¡Maximo stock disponible!</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 1" v-for="(p, index) in outStock" :key="index" class="outStock">
          <div class="outStock-img">
            <img :src="p.image">
          </div>

          <div class="outStock-info">
            <div class="outStock-header">
              <h1>{{ p.name }}</h1>
              <div class="outStock-header_attributes">
                <span v-for="(value, key) in p.attributes" :key="key">
                  <strong>{{ capitalize(key) }}:</strong> {{ value.toUpperCase() }}
                </span>
              </div>
            </div>
            <div class="outStock-data">
              <div v-if="p.quantity >= p.realStock">Cantidad en el carrito: {{ p.quantity }} <v-icon>mdi-arrow-right</v-icon> Stock real: {{ p.realStock }}</div>

              <div v-if="p.isOnPromotion !== p.realIsOnPromotion">
                <span v-if="p.isOnPromotion">El producto ya no esta en promoción.</span>
                <span v-if="p.isOnPromotion">Precio en el carrito: ${{ p.promotionalPrice }} <v-icon>mdi-arrow-right</v-icon> Precio real: ${{ p.realPrice }}</span>

                <span v-if="p.realIsOnPromotion">El producto ahora esta en promoción. Busqueló y agregueló al carrito para aprovechar el descuento.</span>
                <span v-if="p.realIsOnPromotion">Precio en el carrito: ${{ p.price }} <v-icon>mdi-arrow-right</v-icon> Precio en promoción: ${{ p.realPromotionalPrice }}</span>
              </div>

              <div v-if="p.isOnPromotion === p.realIsOnPromotion">
                <span v-if="p.isOnPromotion && p.promotionalPrice !== p.realPromotionalPrice">Precio promocional en el carrito: ${{ p.promotionalPrice }} <v-icon>mdi-arrow-right</v-icon> Precio promocional real: ${{ p.realPromotionalPrice }}</span>

                <span v-if="!p.isOnPromotion && p.price !== p.realPrice">Precio en el carrito: ${{ p.price }} <v-icon>mdi-arrow-right</v-icon> Precio real: ${{ p.realPrice }}</span>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div v-if="step === 0 && $C.totalDiscount() > 0" class="cart-total">
        <p>Descuento</p>
        <p>-${{ $C.totalDiscount() }}</p>
      </div>

      <div v-if="step === 0" class="cart-total">
        <p>Total</p>
        <p>${{ $C.totalPrice() - $C.totalDiscount() }}</p>
      </div>

      <div v-if="step === 0" class="cart-buttons">
        <button @click="$C.deleteAll" class="secondary-button">Vaciar carrito</button>
        <button :disabled="!button" @click="purchase" class="blue-button">Comprar ahora</button>
      </div>

      <div v-else class="cart-buttons">
        <button @click="$C.deleteOutStock(outStock); step = 0" class="secondary-button">Eliminar conflictos</button>
        <button @click="step = 0" class="blue-button">Editar carrito</button>
      </div>

      <div v-if="step === 1" class="disclaimer">
        *Puede que los productos en el carrito hayan sido agregados hace tiempo o que la tienda los haya actualizado haciendo que no sean los mismos actualmente. Considere eliminarlos y volver a agregarlos para actualizarlos. 
      </div>

    </div>

    <div v-else class="cart-empty">
      <v-icon class="close" @click="$C.cartModal = false">mdi-close</v-icon>
      <img src="../assets/empty-cart.svg" alt="carrito vacio">
      <strong>¡Empieza un carrito de compras!</strong>
      <p>Aún no tenés nada en el carrito.</p>
      <RouterLink to="/" class="blue-button">Descubrir productos</RouterLink>
    </div>
      
  </v-dialog>
</template>