<template>
  <div class="payments-way">
    <div class="payments-way-view">
      <div class="payments-way-title">Medios de pago MALL</div>
      <div class="payments-way-subtitle">Tarjetas de debito</div>
      <div class="payments-way-text">Acreditacion instantanea</div>

      <div class="debit-cards-list">
        <div class="debit-list">
          <div class="debit-img"><img src="@/assets/logos/maestro.png" /></div>
        </div>
        <div class="debit-list">
          <div class="debit-img"><img src="@/assets/logos/cabal.png" /></div>
        </div>
        <div class="debit-list">
          <div class="debit-img">
            <img src="@/assets/logos/mastercard.png" />
          </div>
        </div>
        <div class="debit-list">
          <div class="debit-img"><img src="@/assets/logos/visa.jpg" /></div>
        </div>
      </div>
      <div class="payments-way-subtitle">Tarjetas de credito</div>
      <div class="payments-way-text">Acreditacion instantanea</div>

      <div class="credit-cards-list">
        <div class="credit-list">
          <div class="credit-img">
            <img src="@/assets/logos/american.png" />
          </div>
        </div>
        <div class="credit-list">
          <div class="credit-img"><img src="@/assets/logos/cabal.png" /></div>
        </div>
        <div class="credit-list">
          <div class="credit-img">
            <img src="@/assets/logos/mastercard.png" />
          </div>
        </div>
        <div class="credit-list">
          <div class="credit-img"><img src="@/assets/logos/visa.jpg" /></div>
        </div>
        <div class="credit-list">
          <div class="credit-img"><img src="@/assets/logos/naranja.png" /></div>
        </div>
      </div>
      <div class="payments-way-text">
        La cantidad de cuotas puede variar segun la tarjeta
      </div>
      <!-- <div class="payments-way-subtitle">Efectivo</div>
      <div class="payments-text">
        Es muy simple: cuando termines tu compra, te daremos las instrucciones
        para que sepas como y donde pagarla
      </div>
      <div class="payment-img"><img src="@/assets/logos/pagofacil.png" /></div> -->
    </div>
  </div>
</template>

<script></script>
