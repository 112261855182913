<template>
  <div class="view">
    <div class="back-close">
      <div class="back" @click="$Profile.currentView--">
        <v-icon icon="mdi-chevron-left" />
        <div class="back-text">Atras</div>
      </div>
    <DotsView/>
    </div>
    <div class="fit-image">
      <img src="../../assets/fit-modal-blue.svg" alt="logo" />
    </div>

    <div class="fit-title">¿Cuál es el talle de tu pantalón?</div>
    <div class="fit-text">
      Con esto te mostramos modelos aproximados a tu talle.
    </div>
    <div class="grid-buttons">
      <div class="grid-container">
        <div
          :class="['grid-item', { selected: selectedBottomSize === bottomSize }]"
          v-for="bottomSize in bottomSizes"
          :key="bottomSize"
          @click="updatebottomSize(bottomSize)"
        >
          {{ bottomSize }}
        </div>
      </div>
    </div>
    <button class="modal-button" @click="nextModal">
      Siguiente
    </button>
    <div class="bottom-text" v-if="!$User.currentUser">
      <p>
        ¿Ya sos miembro? <strong><RouterLink to="/login"><a href="#">Iniciar Sesión</a></RouterLink></strong>
      </p>
    </div>
  </div>
</template>

<script setup>
import { RouterLink } from "vue-router";
import { ref, reactive } from "vue";
import { useProfileStore, useUserStore } from "@/store/stores";
import DotsView from "./DotsView.vue";

const selectedBottomSize = ref(null);
const bottomSizes = ["XS", "S", "M", "L", "XL", "XXL"];
const $User = useUserStore();

const updatebottomSize = (bottomSize) => {
  if (selectedBottomSize.value === bottomSize) {
    selectedBottomSize.value = null;
  } else {
    selectedBottomSize.value = bottomSize;
  }
  dataToSend.bottomSize = selectedBottomSize.value;
};

const $Profile = useProfileStore();
const currentProfile = $Profile.currentProfile;
const dataToSend = reactive({
  bottomSize: "",
});
const nextModal = () => {
  currentProfile.bottomSize = dataToSend.bottomSize;
  $Profile.currentView++;
  console.log("bottomsize en botomzise.vue",$Profile.currentProfile.bottomSize);
};
</script>
