<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const modules = [Pagination];
defineProps(["data"]);
</script>

<template>
  <swiper
    :loop="true"
    :pagination="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide v-for="(image, index) in data" :key="index">
      <img :src="image">
    </swiper-slide>
  </swiper>
</template>