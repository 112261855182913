import { defineStore } from "pinia";
import { ref } from "vue";

export const usePopStore = defineStore("popUp", () => {
  const popUp = ref(false);
  const popUpText = ref([]);
  const errorModal = ref(false);
  
  const popUpHandler = (text, status = 1) => {
    const randomId = crypto.randomUUID();
    popUpText.value.push({text, status, active: true, id: randomId});
    if(!popUp.value) popUp.value = true;
    const i = popUpText.value.findIndex(t => t.id === randomId);
    setTimeout(() => {
      popUpText.value[i].active = false;
    }, 4000);
    if(!popUpText.value.some(t => t.active === true)) {
      popUp.value = false;
      popUpText.value = [];
    }
  };

  return { popUp, popUpText, popUpHandler, errorModal };
});
