import { defineStore } from "pinia";
import { ref } from "vue";

export const useWindowStore = defineStore("window", () => {
  const windowWidth = ref(window.innerWidth);

  const handleSize = () => {
    windowWidth.value = window.innerWidth;
  };

  return { windowWidth, handleSize };
});
