import { defineStore } from "pinia";
import { ref } from "vue";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore } from "@/store/stores";

export const useRatingStore = defineStore("rating", () => {
  const $P = usePopStore();
  const ratings = ref(null);

  const getRatingByArticle = async (articleId) => {
    try {
      const res = await fetchWrapper.get(
        `articles/ratings/byArticle/${articleId}?isDeleted=false`
      );
      return res.data.articleRatings.filter(r => r.rating !== null);
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar las opiniones", 0);
    }
  };

  const getRatingByUser = async () => {
    try {
      const res = await fetchWrapper.get("articles/ratings/byClient?isDeleted=false");
      if(res.data.articleRatings.length > 0) {
        const split = {
          pending: [],
          completed: []
        };
        res.data.articleRatings.forEach(p => {
          if(p.rating === null) split.pending.push(p);
          else split.completed.push(p);
        });
        ratings.value = split;
      } else {
        console.log("No hay ratings");
      }
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar la opinion", 0);
    }
  };

  const editRating = async (ratingId, rating) => {
    try {
      const res = await fetchWrapper.put(`articles/ratings/${ratingId}`, rating);
      console.log(res);
      $P.popUpHandler("Opinion actualizada exitosamente");
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al actualizar la opinion", 0);
    }
  };

  const deleteRating = async (ratingId) => {
    try {
      await fetchWrapper.delete(`articles/ratings/${ratingId}`);
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al actualizar la opinion", 0);
    }
  };

  return { ratings, getRatingByArticle, getRatingByUser, editRating, deleteRating };
});
