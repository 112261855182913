import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { useUserStore, usePopStore } from "@/store/stores";

export const useProfileStore = defineStore(
  "profiles",
  () => {
    //store
    const $USER = useUserStore();
    const $PopUp = usePopStore();
    //modal
    const openUpdateShoeData = ref(false);
    const openTorsoModal = ref(false);
    const openPantsModal = ref(false);
    const openModal = ref(true);
    const openFloating = ref(true);
    const isOpenModal = ref(false);
    //profile
    const createNewProfile = ref(false);
    const updateNewProfiles = ref(false);
    const currentView = ref(0);
    const defaultProfileData = ref(null);
    const profileList = ref([]);
    const lastOpen = ref(false);
    const currentProfile = ref({
      alias: "invitado",
      genre: "",
      torso: "",
      bottomSize: "",
      shoeSize: "",
      sizeType: "",
    });
    let lastProfile = {};
    const isCreatingNewProfile = ref(false);
    const updateTimerModal = () => {
      const now = new Date();

      // First time
      if (!lastOpen.value) {
        isOpenModal.value = true;
        lastOpen.value = now;
        return;
      }
      // Any other time
      const diffMinutes = Math.abs(new Date(lastOpen.value) - now) / 1000 / 60;
      if (diffMinutes > 10) {
        isOpenModal.value = true;
        lastOpen.value = now;
      }
    };

    const getProfiles = async () => {
      try {
        if (!$USER.currentUser?.id) {
          return;
        }
        const profileData = await fetchWrapper.get(
          `profiles/userClient/${$USER.currentUser.id}`
        );
        profileList.value = profileData.data.sort((a, b) =>
          a.alias.localeCompare(b.alias)
        ).filter((profileItem) => {
          return (typeof profileItem !== "string");
        });
        console.log("lista perfiles", profileList.value);
        setDefaultProfile();

        if (profileList.value.length > 0 && !currentProfile.value?.id) {
          currentProfile.value = profileList.value[0];
        }
      } catch (e) {
        console.log("Error al obtener perfiles:", e);
      }
    };
    const deleteProfile = async (id) => {
      try {
        await fetchWrapper.delete(`profiles/${id}`);
        getProfiles();
        $PopUp.popUpHandler("Perfil eliminado exitosamente");
      } catch (e) {
        console.log(e);
        $PopUp.popUpHandler("Hubo un error al eliminar el perfil", 0);
      }
    };
    const getDefaultProfile = () => {
      return profileList.value.length ? profileList.value[0] : null;
    };

    const setDefaultProfile = () => {
      if (!defaultProfileData.value && profileList.value.length) {
        defaultProfileData.value = profileList.value[0];
      }
    };

    const closeModal = () => {
      openModal.value = false;
    };
    const closeNewModal = () => {
      createNewProfile.value = false;
    };

    const changeView = (view) => {
      currentView.value = view;
    };

    const newProfile = async () => {
      const data = {
        //userClientId: $USER.currentUser?.id, se reconoce id desde el login
        alias: currentProfile.value.alias,
        genre: currentProfile.value.genre,
        torso: currentProfile.value.torso,
        bottomSize: currentProfile.value.bottomSize,
        shoeSize: currentProfile.value.shoeSize,
        sizeType: currentProfile.value.sizeType,
      };
      if ($USER.currentUser?.id) {
        try {
          await fetchWrapper.post("profiles", data);
        } catch (e) {
          console.error(e);
        }
      } else {
        console.log("data guarda datos de forma temporal:", data);
      }
    };

    const updateProfile = async () => {
      if (!$USER.currentUser?.id || !currentProfile.value?.id) {
        return console.log(
          "data para actualizar guardada de forma temporal:",
          currentProfile.value
        );
      }
      const isNewAlias =
        currentProfile.value.alias !== lastProfile.alias &&
        currentProfile.value.id === lastProfile.id;

      try {
        const updatedData = {
          alias: isNewAlias ? currentProfile.value.alias : undefined,
          genre: currentProfile.value.genre,
          torso: currentProfile.value.torso,
          bottomSize: currentProfile.value.bottomSize,
          shoeSize: currentProfile.value.shoeSize,
          sizeType: currentProfile.value.sizeType,
        };

        await fetchWrapper.put(
          `profiles/${currentProfile.value.id}`,
          updatedData
        );
      } catch (e) {
        console.error(e);
        $PopUp.popUpHandler("Hubo un error al actualizar el perfil", 0);
      }
      lastProfile = { ...currentProfile.value };
    };

    watch(
      currentProfile,
      async () => {
        if (currentProfile.value.id && isCreatingNewProfile.value === false) {
          await updateProfile();
        }
      },
      { deep: true }
    );

    const calculateProfilePercentage = (profile) => {
      if (!profile) return 0;
      const profileValues = Object.values(profile);

      const nonEmptyValues = profileValues.filter((value) => {
        return value !== null && value !== undefined && value !== "";
      });

      const percentage = (nonEmptyValues.length / profileValues.length) * 100;
      const roundedPercentage = percentage.toFixed(0).substring(0, 3);
      return roundedPercentage;
    };

    const getEmptyValues = () => {
      const emptyValues = [];
      for (const [key, value] of Object.entries(currentProfile.value)) {
        if (!value) {
          emptyValues.push(key);
        }
      }
      return emptyValues;
    };

    const cleanProfile = () => {
      currentProfile.value = {
        alias: "Invitado",
        genre: "",
        torso: "",
        bottomSize: "",
        shoeSize: "",
        sizeType: "",
      };
    };

    return {
      isCreatingNewProfile,
      isOpenModal,
      closeNewModal,
      createNewProfile,
      openPantsModal,
      openTorsoModal,
      openUpdateShoeData,
      newProfile,
      openFloating,
      openModal,
      currentView,
      closeModal,
      currentProfile,
      changeView,
      profileList,
      calculateProfilePercentage,
      getEmptyValues,
      updateProfile,
      getProfiles,
      getDefaultProfile,
      setDefaultProfile,
      updateNewProfiles,
      deleteProfile,
      updateTimerModal,
      lastOpen,
      cleanProfile
    };
  },
  {
    persist: true,
  }
);
