<script setup>
/* eslint-disable */
//vue
import { ref, computed, watch } from "vue";
//vue-router
import { RouterView, RouterLink, useRoute } from "vue-router";
//stores
import { useWindowStore } from "@/store/window";

const route = useRoute();
const $W = useWindowStore();

const routeOn = computed(() => {
  return route.fullPath.split("/").pop().split("?")[0];
});

const items = [
  { text: "Mis compras", icon: "mdi-shopping-outline", link: "my-purchases" },
  { text: "Opiniones", icon: "mdi-cloud-question-outline", link: "ratings/pending" },
  { text: "Guardados", icon: "mdi-bookmark-outline", link: "saved" },
  { text: "Favoritos", icon: "mdi-heart-box-outline", link: "favorites" },
  { text: "Direcciones", icon: "mdi-map-marker-outline", link: "my-addresses" },
  { text: "Tarjetas", icon: "mdi-credit-card-outline", link: "my-cards" },
  { text: "Seguridad", icon: "mdi-lock-outline", link: "my-security" },
  { text: "Perfiles", icon: "mdi-account-group", link: "my-profiles" },
  { text: "Notificaciones", icon: "mdi-cash", link: "my-notifications" },
];

const toggle = ref(false);

watch(routeOn, () => toggle.value = false);

</script>

<template>
  <div class="profile-container">
    <div v-if="toggle === true" @click="toggle = false" class="menu-background"></div>
    <div class="menu-container" :class="{activeMenu: toggle}" @click="toggle = true" v-if="$W.windowWidth > 768">

      <div class="menu-options">

        <div class="option" :class="{activeLink: toggle}">
          <v-icon v-if="toggle === false">mdi-menu</v-icon>
          <v-icon v-else @click.stop="toggle = false">mdi-close</v-icon>
          <RouterLink to="/profile/config" v-if="toggle">Mi cuenta</RouterLink>
        </div>

        <div v-for="(item, index) in items" class="option" :class="{activeLink: toggle}" :key="index">
          <v-icon :class="{color: routeOn.split('?')[0] === item.link.split('?')[0]}">{{ item.icon }}</v-icon>
          <RouterLink :to="`/profile/info/${item.link}`" v-if="toggle">{{ item.text }}</RouterLink>
        </div>

      </div>
    </div>

    <div class="view-container">
      <RouterView />
    </div>
  </div>
</template>

<style scoped>
.color {
  color: #0057ff;
}
</style>