<template>
  <div class="firstlogin-view">
    <v-dialog v-model="profileStore.updateNewProfiles">
      
      <div v-if="profileStore.currentView === 0">
        <updateNewAlias />
      </div>
      <div v-if="profileStore.currentView === 1">
        <UpdateGender />
      </div>
      <div v-if="profileStore.currentView === 2">
        <UpdateTorso />
      </div>
      <div v-if="profileStore.currentView === 3">
        <UpdateShoesize />
      </div>
      <div v-if="profileStore.currentView === 4">
        <UpdatePants />
      </div>
    </v-dialog>
  </div>
</template>
<script setup>
import updateNewAlias from "@/views/profile/updateNewAlias.vue";
import UpdateGender from "@/views/profile/UpdateGender.vue";
import UpdateTorso from "@/views/profile/UpdateTorso.vue";
import UpdateShoesize from "@/views/profile/UpdateShoesize.vue";
import UpdatePants from "@/views/profile/UpdatePants.vue";

import { useProfileStore } from "@/store/stores";

const profileStore = useProfileStore();
</script>
