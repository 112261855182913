/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
//Styles
import "./styles/main.css";
//Router
import { router } from "./router/index";
//Pinia
import { createPinia } from "pinia";
import piniaPersistedState from "pinia-plugin-persistedstate";
//Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
//Firebase
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const app = createApp(App);
const pinia = createPinia();
const vuetify = createVuetify({ components, directives });
pinia.use(piniaPersistedState);
app.use(pinia);
app.use(vuetify);
app.use(router);
const appFirebase = initializeApp(firebaseConfig);
app.mount("#app");
