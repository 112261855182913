<template>
  <div class="view">
    <div class="back-close">
      <div class="back" @click="$Profile.currentView--">
        <v-icon icon="mdi-chevron-left" />
        <div class="back-text">Atras</div>
      </div>
      <DotsView />
    </div>
    <div class="fit-image">
      <img src="../../assets/fit-modal-blue.svg" alt="logo" />
    </div>

    <div class="fit-title">¿Cuál es el talle de tu torso?</div>
    <div class="fit-text">
      Con esto te mostramos modelos aproximados a tu talle.
    </div>
    <div class="grid-buttons">
      <div class="grid-container">
        <div
          :class="['grid-item', { selected: slectedTorso === torso }]"
          v-for="torso in torsos"
          :key="torso"
          @click="updatedTorso(torso)"
        >
          {{ torso }}
        </div>
      </div>
    </div>
    <button class="modal-button" @click="nextModal">
      Siguiente
    </button>
    <div class="bottom-text" v-if="!$User.currentUser">
      <p>
        ¿Ya sos miembro? <strong><RouterLink to="/login"><a href="#">Iniciar Sesión</a></RouterLink></strong>
      </p>
    </div>
  </div>
</template>

<script setup>
import { RouterLink } from "vue-router";
import { ref, reactive } from "vue";
import { useProfileStore, useUserStore } from "@/store/stores";
import DotsView from "./DotsView.vue";

const slectedTorso = ref(null);
const torsos = ["XS", "S", "M", "L", "XL", "XXL"];
const $User = useUserStore();

const updatedTorso = (torso) => {
  if (slectedTorso.value === torso) {
    slectedTorso.value = null;
  } else {
    slectedTorso.value = torso;
  }
  dataToSend.torso = slectedTorso.value;
};

const $Profile = useProfileStore();
const currentProfile = $Profile.currentProfile;
const dataToSend = reactive({
  torso: "",
});
const nextModal = () => {
  currentProfile.torso = dataToSend.torso;
  $Profile.currentView++;
  console.log("torso",$Profile.currentProfile.torso);
};
</script>
