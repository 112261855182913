<template>
  <div class="faqs-conditions">
    <div class="faqs-background">
      <div class="faqs">
        <div class="faqs-title">Preguntas Frecuentes Mall Clientes</div>
        <div class="faqs-subtitle">¿Dónde cargo mi perfil?</div>
        <div class="faqs-text"></div>
        <div class="faqs-subtitle">
          ¿Cómo comparto el perfil de un amigo para hacerle un regalo?
        </div>
        <div class="faqs-text"></div>
        <div class="faqs-subtitle">
          ¿Puedo cambiar la prenda si el talle no coincidio con lo que cargue en
          mi perfil?;¿Este luego del reclamo se actualiza solo o lo debo cargar
          yo el cambio en mi perfil?
        </div>
        <div class="faqs-text"></div>
        <div class="faqs-subtitle">
          ¿Puedo cambiar la prenda si viene con alguna falla?
        </div>
        <div class="faqs-text"></div>
        <div class="faqs-subtitle">¿Cuanto me tarda en llegar los cambios?</div>
        <div class="faqs-text"></div>
        <div class="faqs-subtitle">
          ¿Los datos de pago quedan guardados en mi perfil o los tengo que
          cargar cada vez que realice una compra?
        </div>
        <div class="faqs-text"></div>
        <!-- falta agregar "te fue util esta informacion?" -->
      </div>
    </div>
  </div>
</template>
<script></script>
