<script setup>
/* eslint-disable */
//vue
import { ref, reactive } from "vue";
//vue-router
import { RouterLink, useRouter } from "vue-router";
const router = useRouter();
//stores
import { useProfileStore, useUserStore, useWindowStore, useFilterStore } from "@/store/stores";
const $W = useWindowStore();
const $U = useUserStore();
const $P = useProfileStore();
const $F = useFilterStore();
const profile = $P.currentProfile;
//components
import TorsoModal from "./TorsoModal.vue";
import UpdateShoeData from "./UpdateShoeData.vue";
import PantsModal from "./PantsModal.vue";
//helpers
import { generateInitials, getInitialsClass } from "@/helpers/initials";
//emit
const emit = defineEmits(["apply-filter"]);

const modal = ref(false);
const filter = ref(false);
const filterData = reactive({
  checkSize: $F.filter.checkSize,
  order: $F.filter.order,
  priceRange: [$F.filter.priceRange[0], $F.filter.priceRange[1]]
});

const order = (type) => {
  if(type === "asc") {
    if(filterData.order === null || filterData.order === "DESC") filterData.order = "ASC"
    else filterData.order = null
  }
  if(type === "desc") {
    if(filterData.order === null || filterData.order === "ASC") filterData.order = "DESC"
    else filterData.order = null
  }
}

const applyFilter = (reset) => {
  if(reset) {
    filterData.checkSize = false;
    filterData.order = null;
    filterData.priceRange = [0, 300000];
    $F.cleanFilter();
  }
  else $F.filter = filterData;
  modal.value = false;
  filter.value = false;
  emit("apply-filter", true);
}

</script>

<template>
  <div class="modalButton">
    <div v-if="modal" @click="modal = false; filter = false" class="background"></div>
    <div @click="modal = true" class="profile-img" :class="getInitialsClass(profile.alias), {active: modal}">
      <img v-if="profile.image" :src="profile.image" />
      <span v-else>{{ generateInitials(profile.alias) }}</span>
    </div>
  </div>
  <div class="profile-completation mobile" :class="{activemenu: modal}">

    <v-icon @click="modal = false; filter = false" class="close">mdi-close</v-icon>
    
    <RouterLink :to=" $U.currentUser ? `/profile/info/my-profiles` : '/signin'" class="profile-name">
      <div class="profile-img" :class=getInitialsClass(profile.alias)>
        <img v-if="profile.image" :src="profile.image" />
        <span v-else>{{ generateInitials(profile.alias) }}</span>
      </div>
      <span>{{ profile.alias?.toUpperCase() }}</span>
    </RouterLink>

    <div class="profile-sizes">
      <div class="profile-images" @click="$P.openUpdateShoeData = true" :class="{active: profile.shoeSize}">
        <img src="../../assets/shoe-blue.svg"/>
        <UpdateShoeData />
        <div class="floating-size" v-if="profile.shoeSize">
          {{ profile.shoeSize }}
        </div>
      </div>
      <div class="profile-images" @click="$P.openTorsoModal = true" :class="{active: profile.torso}">
        <img src="../../assets/blue-t-shirt.svg"/>
        <TorsoModal />
        <div class="floating-size" v-if="profile.torso">
          {{ profile.torso }}
        </div>
      </div>
      <div class="profile-images" @click="$P.openPantsModal = true" :class="{active: profile.bottomSize}">
        <img src="../../assets/blue-t-pant.svg"/>
        <PantsModal />
        <div class="floating-size" v-if="profile.bottomSize">
          {{ profile.bottomSize }}
        </div>
      </div>
    </div>

    <div class="filter-container" :class="{showFilter: filter}">
      <div class="header" @click="filter = !filter">
        <span>Filtros de busqueda</span>
        <v-icon>mdi-chevron-right</v-icon>
      </div>
      <div class="filters">
        <div>
          <div class="checkSize">
            <v-switch
              v-model="filterData.checkSize"
              :label="'Aplicar talles seleccionados'"
              :ripple="false"
              :class="{active: filterData.checkSize}"
              hide-details
            ></v-switch>
          </div>
          <div class="price">
            <span>Precios</span>
            <div class="asc-des">
              <div class="check" @click="order('asc')">
                <span>Menor precio</span>
                <div class="checkinput" :class="{active: filterData.order === 'ASC'}">
                  <v-icon class="active" v-if="filterData.order === 'ASC'">mdi-minus-circle</v-icon>
                  <v-icon v-else>mdi-circle-outline</v-icon>
                </div>
              </div>
              <div class="check" @click="order('desc')">
                <span>Mayor precio</span>
                <div class="checkinput" :class="{active: filterData.order === 'DESC'}">
                  <v-icon class="active" v-if="filterData.order === 'DESC'">mdi-plus-circle</v-icon>
                  <v-icon v-else>mdi-circle-outline</v-icon>
                </div>
              </div>
            </div>
            <div class="slider">
              <v-range-slider
                v-model="filterData.priceRange"
                :max="300000"
                :min="0"
                :step="10000"
                :ripple="false"
                hide-details
              >
              </v-range-slider>
              <div class="inputs">
                <input type="number" v-model="filterData.priceRange[0]">
                <input type="number" v-model="filterData.priceRange[1]">
              </div>
            </div>
          </div>
          <span class="reset" @click="applyFilter(true)">Resetear los filtros</span>
          <div @click="applyFilter(false)" class="blue-button">Aplicar</div>
        </div>
      </div>
    </div>

  </div>
</template>
