<template>
  <div class="completation-modals">
    <div class="back-close">
      <div class="cross-close" @click="$Profile.openTorsoModal = false">
        <img src="../../assets/close-cross.svg" alt="logo" />
      </div>
    </div>
    <div class="fit-title">¿Cuál es el talle de tu torso?</div>
    <div class="grid-buttons">
      <div class="grid-container">
        <div
          :class="[
            'grid-item',
            { selected: isSelectedTorso(torso) || selectedTorso === torso },
          ]"
          v-for="torso in torsos"
          :key="torso"
          @click="updatetorso(torso)"
        >
          {{ torso }}
        </div>
      </div>
    </div>
    <button class="modal-button" @click="nextModal">
      Siguiente
    </button>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useProfileStore } from "@/store/stores";

const selectedTorso = ref(null);
const torsos = ["XS", "S", "M", "L", "XL", "XXL"];
const lastProfile = ref(null);

const updatetorso = (torso) => {
  if (selectedTorso.value === torso) {
    selectedTorso.value = null;
  } else {
    selectedTorso.value = torso;
  }
  dataToSend.torso = selectedTorso.value;
};

const $Profile = useProfileStore();
const currentProfile = $Profile.currentProfile;
const dataToSend = reactive({
  torso: "",
});

const nextModal = () => {
  currentProfile.torso = dataToSend.torso;
  $Profile.currentView++;
};
const isSelectedTorso = (bottomSize) => {
  if (lastProfile.value?.id !== $Profile.currentProfile?.id) {
    lastProfile.value = $Profile.currentProfile;
    selectedTorso.value = $Profile.currentProfile.bottomSize;
  }
  return selectedTorso.value === bottomSize;
};
onMounted(async () => {
  selectedTorso.value = $Profile.currentProfile.torso;
});
</script>
