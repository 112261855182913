<template>
  <div class="shipment-conditions">
    <div class="shipment-background">
      <div class="shipment">
        <div class="shipment-title">
          Términos y condiciones de Mall para Envíos
        </div>
        <div class="shipment-subtitle">Última actualización: 27/05/2024</div>
        <div class="shipment-text">
          Los términos y condiciones de Mall Envíos son parte de los Términos y
          Condiciones Generales de Mall. Por eso, mediante la aceptación de
          estos últimos en el momento del registro del Usuario, también se
          aceptan las políticas aquí detalladas. En los Términos y Condiciones
          de Mall Envíos se describen los derechos, obligaciones y
          responsabilidades que los Usuarios deberán respetar si deciden usar
          los Servicios de Mall Envíos. Cualquier incumplimiento de estos
          Términos y Condiciones podrá acarrear sanciones a los Usuarios, que
          pueden llegar hasta la inhabilitación de sus cuentas. Cualquier
          persona que no acepte los Términos y Condiciones de Mall Envíos y/o
          los Términos y Condiciones Generales de Mall, que tienen un carácter
          obligatorio y vinculante, deberá abstenerse de usar el sitio y/o los
          Servicios de Mall Envíos. Mall se reserva el derecho de rechazar una
          solicitud de Servicio de Mall Envíos o de cancelar o suspender con o
          sin causa, temporal o definitivamente la prestación de los Servicios
          de Mall Envíos, sin que tal decisión genere para el Usuario derechos
          de indemnización o resarcimiento.
        </div>
        <div class="shipment-subtitle">1. El Servicio</div>
        <div class="shipment-text">
          Mall Envíos es una herramienta ofrecida por Mall app MDQ S.A. (“Mall”)
          con el objetivo de facilitar el envío de un determinado producto
          anunciado en sitios web, aplicaciones y restantes plataformas de
          comercio electrónico operadas por (o que utilicen la tecnología de)
          Mall, valiéndose de (a) servicios de transporte y/o logística y/o
          postales brindados por terceros (las “Empresas de Encomiendas y
          Envíos”) o de (b) soluciones logísticas propias del Usuario Vendedor o
          contratadas por éste (los “Transportistas Flex”).
        </div>
        <div class="shipment-subtitle">
          Mall se limita a brindar la solución tecnológica que permite:
        </div>
        <ul>
          <li>El cálculo del valor del envío,</li>
          <li>
            La generación de etiquetas para el posterior envío del producto,
          </li>
          <li>El seguimiento del envío por parte de los Usuarios,</li>
          <li>
            la contratación por parte de los Usuarios compradores del servicio
            de envío de un bien,
          </li>
          <li>
            la recepción y gestión de los reclamos entre los Usuarios y/o las
            Empresas de Encomiendas y Envíos y/o los Transportistas Flex
          </li>
        </ul>
        <div class="shipment-text">
          Todo esto, en adelante, el “Servicio de Mall”. Las Empresas de
          Encomiendas y Envíos y/o los Transportistas Flex serán quienes
          realizarán directamente los servicios de envíos a favor de los
          Usuarios compradores, cuando los mismos se contraten a través del
          Servicio de Mall (el “Servicio de las Empresas”). La única y exclusiva
          participación de Mall en lo que refiere al Servicio de las Empresas se
          encuentra limitada a referenciar a los Usuarios compradores, que
          solicitan la contratación de los Servicios de las Empresas, a las
          Empresas de Encomiendas y Envíos y/o a los Transportistas Flex, así
          como a brindar el Servicio de Mall conforme se lo define más arriba. A
          los efectos de los presentes Términos y Condiciones se entenderá por
          “Servicio de Mall Envíos” un concepto que incluye el Servicio de Mall
          (a ser prestado por Mall) y el Servicio de las Empresas (a ser
          prestado directamente por las Empresas de Encomiendas y Envíos o por
          los Transportistas Flex).
        </div>
        <div class="shipment-subtitle">
          2. Disponibilidad del Servicio de Mall Envíos
        </div>
        <div class="shipment-text">
          El Usuario vendedor podrá ofrecer en sus publicaciones el Servicio de
          Mall Envíos, siempre que (i) dicho servicio esté disponible en la
          categoría correspondiente al bien publicado, (ii) el bien en cuestión
          sea admitido por las Empresas de Encomiendas y Envíos y/o por los
          Transportistas Flex para la prestación del Servicio de Empresas y
          (iii) el Usuario vendedor haya ingresado en sus datos de registración
          un domicilio válido dentro de la República Argentina que tenga
          cobertura por parte del Servicio de las Empresas. El Usuario vendedor
          es responsable de categorizar correctamente el bien a ser publicado y
          de verificar la disponibilidad del Servicio de Empresas para dicho
          bien. Cualquier consecuencia derivada de la categorización incorrecta
          del bien a ser publicado o de la omisión de la verificación de las
          limitaciones respecto del Servicio de Empresas será asumida por el
          Usuario vendedor (por ejemplo, mayor costo del envío o imposibilidad
          de transportar el bien). El Usuario vendedor podrá brindar a sus
          Usuarios compradores las opciones de entrega disponibles a través del
          Servicio de Mall Envíos y otras opciones de entrega o retiro directo
          que pueda acordar el Usuario vendedor con el Usuario comprador. En la
          plataforma de Mall no existirá la posibilidad de elegir entre otras
          opciones de entrega distintas a las antes mencionadas.
        </div>
        <div class="shipment-subtitle">
          3. Costos y facturación del Servicio de Mall Envíos
        </div>
        <div class="shipment-text">
          Mall informará los costos del Servicio de Mall Envíos a los Usuarios
          (al Usuario vendedor en el flujo de publicación y al Usuario comprador
          en la publicación del bien a ser transportado). La factura por el
          Servicio de Mall Envíos será enviada por Mall al Usuario vendedor en
          los casos en los que el Servicio de las Empresas haya sido prestado
          por las Empresas de Encomiendas y Envíos, sin perjuicio de qué Usuario
          (vendedor o comprador) haya asumido el costo final por el Servicio de
          Mall Envíos. En caso que el Usuario vendedor haya optado porque el
          costo del envío esté a cargo del Usuario comprador, el Usuario
          vendedor se obliga a incluir en el documento fiscal correspondiente el
          costo de envío y el precio del producto. El comprobante fiscal que
          corresponda por el Servicio de Mall Envíos será enviado por el
          Transportista Flex a quien corresponda, en los casos en los que el
          Servicio de las Empresas haya sido prestado por dicho Transportista
          Flex, si el costo final por el Servicio de Mall Envíos hubiera sido
          asumido o cubierto por una persona distinta al Usuario vendedor. El
          Usuario vendedor se obliga expresamente a no cobrar ni solicitar
          agregado de dinero alguno al Usuario comprador por cuestiones
          relacionadas al uso del Servicio de Mall Envíos en ningún caso.
        </div>
        <div class="shipment-subtitle">
          4. Obligaciones del Usuario vendedor relativas al Servicio de Mall
          Envíos
        </div>
        <ol>
          <li>
            <div class="shipment-text">
              Envío efectuado por Empresa de Encomiendas y Envíos:
            </div>
            <ul>
              <li>
                B. entregar los bienes a ser transportados en cualquier punto de
                admisión debidamente autorizado o solicitar su colecta (en caso
                que tuviera esta opción disponible) en perfectas condiciones, lo
                antes posible desde que se produjo la venta y no más allá del
                tiempo indicado por Mall al notificar dicha venta al Usuario
                vendedor. Finalizado este plazo las Empresas de Encomiendas y
                Envíos podrán rechazar la admisión del envío. De ocurrir esta
                hipótesis, el Usuario vendedor deberá negociar directamente con
                el Usuario comprador la forma de envío del producto. En este
                caso el Usuario vendedor se hará cargo íntegramente de cualquier
                cargo extra originadopor el envío del producto, incluyendo los
                cargos generados por la utilización del Servicio de Mall Envíos
                (aún si éste último no hubiera concluido por causas imputables a
                una persona distinta a Mall o a la Empresa de Encomiendas y
                Envíos).
              </li>
              <li>
                B. entregar la encomienda con la orden de transporte (etiqueta)
                pegada adecuadamente, debiendo contener ésta la información
                fidedigna para la entrega.
              </li>
            </ul>
          </li>
          <li>
            <div class="shipment-tex">
              Envío efectuado por el Transportista Flex:
            </div>
            <ul>
              <li>
                A. entregar los bienes a ser transportados en el domicilio de
                destino indicado por el Usuario comprador en perfectas
                condiciones, con la orden de transporte (etiqueta) pegada
                adecuadamente, lo antes posible desde que se produjo la venta y
                no más allá del tiempo indicado por Mall al notificar dicha
                venta al Usuario vendedor; Tanto en los casos de envíos
                realizados por Empresa de Encomiendas y Envíos como en los casos
                de envíos realizados por el Transportista Flex, es obligación
                del Usuario vendedor acondicionar y embalar el bien en forma
                adecuada para protegerlo durante el envío A estos efectos, el
                Usuario vendedor deberá cumplir las siguientes disposiciones: el
                envío deberá estar cerrado y proporcionar condiciones que
                aseguren la protección del contenido (con un nivel de protección
                considerado como si la Empresa de Encomiendas y Envíos no
                dispusiera de servicios en condiciones para realizar la admisión
                y el transporte de un envío frágil) y la integridad física de
                las personas que lo manipulen, con un embalaje y
                acondicionamiento con forma prismática, sin salientes que
                excedan el embalaje, sin etiquetas postales anteriores y
                debidamente acondicionados de forma tal que eviten derrames en
                caso de líquidos. En caso de incumplimiento de cualquiera de las
                obligaciones previstas, el Usuario vendedor será responsable de
                cualquier daño o perjuicio que sufra Mall y/o la Empresa de
                Encomiendas y Envíos.
              </li>
            </ul>
          </li>
        </ol>
        <div class="shipment-subtitle">
          5. Procedimiento para utilizar el Servicio de Mall Envíos
        </div>
        <div class="shipment-text">
          El Usuario comprador, al elegir la opción “Mall Envíos” e ingresar el
          domicilio donde desea recibir el envío en la herramienta puesta a su
          disposición por Mall, será informado sobre (i) la disponibilidad del
          Servicio de Mall Envíos para el domicilio ingresado, y en su caso,
          (ii) los costos del envío que deberá abonar de decidirse a contratar
          dicho Servicio de Mall Envíos. Dicho costo será visualizado
          separadamente del valor del producto ofertado y podrá ser abonado
          únicamente mediante el uso de los servicios de gestión de pagos y
          cobros en línea que Mall ofrece a través de su plataforma en línea.El
          Usuario comprador que decida adquirir un producto donde la publicación
          ofrezca el Servicio de Mall Envíos, deberá brindar toda la información
          completa y correcta para el envío y recepción del producto. El Usuario
          comprador reconoce que resultará ser el único responsable por las
          consecuencias resultantes de un incumplimiento de dicha obligación.
          Una vez que el Usuario comprador haya completado la información para
          el envío del producto en cuestión, abonado el valor del producto
          ofertado y el costo del envío calculado por Mall (en caso de
          corresponder), el Usuario vendedor recibirá la información cargada por
          el Usuario comprador para el envío del producto, razón por la cual el
          Usuario vendedor deberá: (i) imprimir las órdenes de transporte
          (etiquetas) para el envío del producto, (ii) cerrar y empaquetar
          correctamente el producto a enviar, respetando para ello la naturaleza
          del mismo según corresponda, siguiendo las directivas que se incluyen
          en estos Términos y Condiciones y además en el sitio web de la Empresa
          de Encomiendas y Envíos que corresponda, y (iii) según sea el caso:
          (a) entregar el producto a enviar bajo cualquier modalidad de admisión
          debidamente autorizada a la Empresa de Encomiendas y Envíos que
          corresponda o (b) entregar el producto a enviar directamente el
          Usuario comprador (en el caso del envío a través del Transportista
          Flex).
        </div>
        <div class="shipment-subtitle">
          6. Procedimiento en caso de no entrega y abandono de productos no
          entregados
        </div>
        <div class="shipment-text">
          Si por cualquier causa no imputable a Mall y/o a la Empresa de
          Encomiendas y Envíos el producto no puede ser entregado (ya sea por
          dirección inexacta, o por no encontrarse una persona que reciba el
          producto en el domicilio, o por falta de presentación de la persona
          autorizada a recibir el producto en la sucursal de la Empresa de
          Encomiendas y Envíos designada para la recepción del envío, entre
          otras) y Mall no hubiera indemnizado a los Usuarios en relación a la
          operación en cuestión, la Empresa de Encomiendas y Envíos procederá de
          acuerdo a lo previsto en la normativa aplicable.
        </div>
        <div class="shipment-subtitle">
          7. Cancelación de la operación por cualquier causa
        </div>
        <div class="shipment-text">
          En caso que el Usuario comprador decida devolver el artículo al
          Usuario vendedor y/u ocurra la cancelación de la transacción luego del
          envío del artículo por el Usuario vendedor, se mantendrá el cobro por
          la utilización del Servicio de Mall Envíos de la manera descripta en
          estos Términos y Condiciones de Mall Envíos toda vez que dichos
          servicios ya habrán sido prestados.Además, dentro de los 30 días de
          recibido el producto por cuenta del Usuario comprador y en
          cumplimiento con lo dispuesto en la cláusula 6.2.de los Términos y
          Condiciones Generales de Mall, el Usuario vendedor deberá aceptar el
          cambio o devolución del mismo y hacerse cargo de los gastos que esto
          conlleve, incluyendo los costos de envíos que fueren necesarios. Mall
          podrá debitar estos costos, a través del medio de pago ingresado, en
          la cuenta del Usuario vendedor. Si en la operación hubiese sido usado
          el Servicio de Mall Envíos, Mall podrá disponibilizar etiquetas de
          envíos para que el Usuario comprador realice la devolución y debitar
          el costo de las mismas, como se indicó anteriormente, de la cuenta del
          Usuario vendedor.
        </div>
        <div class="shipment-subtitle">
          8. Reembolso de gastos y otras deducciones
        </div>
        <div class="shipment-text">
          El Usuario comprador y el Usuario vendedor expresamente autorizan a
          Mall a que debite cualquier costo en que Mall incurra en relación al
          Servicio de Mall Envíos, a través, de la cuenta del Usuario comprador.
          Asimismo, Mall se reserva el derecho de cobrar al Usuario vendedor la
          diferencia entre el valor del envío determinado por el Servicio de
          Mall y cobrado al Usuario que corresponda y el valor real del envío
          efectivamente informado por las Empresas de Encomiendas y Envíos en el
          caso que la herramienta sea utilizada por el Usuario vendedor de forma
          indebida y/o la información suministrada por el Usuario vendedor sea
          incorrecta). El Usuario vendedor expresamente autoriza a Mall a cobrar
          y debitar automáticamente de su cuenta de los valores referentes al
          uso del Servicio de Mall Envíos en caso de que algún Usuario comprador
          realice algún desconocimiento, contra-cargo o reversión de la
          operación de compra que involucró a dicha operación.
        </div>
        <div class="shipment-subtitle">
          9. Responsabilidad del Usuario vendedor
        </div>
        <div class="shipment-text">
          Sin perjuicio de las responsabilidades adicionales que le caben en
          caso que él realice el envío del producto, el Usuario vendedor será
          absolutamente responsable, eximirá de toda responsabilidad y mantendrá
          indemne a Mall y a las Empresas de Encomiendas y Envíos que
          correspondan, asumiendo cualquier consecuencia que se generara
          (incluyendo sin carácter limitativo, el abono de cualquier costo
          adicional que sea pertinente), si por su dolo o culpa: (i) completara
          errónea o falsamente los datos necesarios para realizar la entrega del
          paquete por parte de la Empresa de Encomiendas y Envíos y recibir
          devoluciones; (ii) cerrara y/o embalara de manera incorrecta el
          producto a enviar; (iii) completara errónea o falsamente los datos
          necesarios para calcular los costos de envíos (incorrecta
          categorización del producto); y/o (iv) utilizara el Servicio de Mall
          Envíos para enviar alguno de losproductos en incumplimiento a las
          condiciones de transporte previstas y/o productos expresamente
          prohibidos por estos Términos y Condiciones y/o por la legislación
          aplicable en la materia para su transportación y/o productos que no
          sean aceptados por la Empresa de Encomiendas y Envíos y/o por el
          Transportista Flex, los cuales pueden estar detallados de manera
          enunciativa en los presentes Términos y Condiciones de Mall Envíos. El
          Usuario vendedor se compromete a entregar a la Empresa de Encomiendas
          y Envíos que corresponda cualquier documento que cualquier autoridad
          le pudiera llegar a exigir o que sea necesario para transportar el
          bien conforme a la legislación vigente en la materia. En virtud de
          ello, el Usuario vendedor reconoce que será el único responsable por
          la falta de entrega de dicha documentación, y su falta no podrá ser
          invocada para atribuir un incumplimiento a la Empresa de Encomiendas y
          Envíos. Asimismo, el Usuario vendedor autoriza a la Empresa de
          Encomiendas y Envíos que corresponda a inspeccionar los envíos, sin
          previa solicitud, siempre y cuando dicha inspección se deba realizar
          por cuestiones de seguridad, de inspección aduanera, por solicitud de
          alguna autoridad competente o por cualquier otra razón justificada. En
          los casos en que el envío sea realizado por el Transportista Flex,
          dicho Usuario vendedor será absolutamente responsable, eximirá de toda
          responsabilidad y mantendrá indemne a Mall por cualquier reclamo
          vinculado al servicio de envío, asumiendo cualquier consecuencia que
          se generara por su dolo o culpa.
        </div>
        <div class="shipment-subtitle">
          10. Responsabilidad del Usuario comprador
        </div>
        <div class="shipment-text">
          El Usuario comprador será absolutamente responsable, eximirá de toda
          responsabilidad y mantendrá indemne a Mall y a las Empresas de
          Encomiendas y Envíos o al Transportista Flex, si por su dolo o culpa
          (i) completara errónea o falsamente los datos necesarios para realizar
          la entrega del paquete y/o (ii) no dispusiera de una persona que pueda
          recibir el envío o retirarlo en el lugar que dicho Usuario comprador
          hubiera seleccionado.
        </div>
        <div class="shipment-subtitle">
          11. Reconocimientos y declaraciones de los Usuarios
        </div>
        <div class="shipment-text">
          Los Usuarios reconocen y aceptan que la actividad de Mall, a través
          del Servicio de Mall, se limita a las actividades descritas en la
          cláusula 1. de estos Términos y Condiciones. En consecuencia, los
          Usuarios entienden y reconocen que Mall no es propietario, no tiene la
          posesión, ni vende los artículos anunciados por los Usuarios
          vendedores, ni los transporta en ninguna forma, razón por la cual
          comprenden yaceptan que Mall no puede ser responsabilizada por el
          Servicio de las Empresas que referencia con distintas Empresas de
          Encomiendas y Envíos o Transportistas Flex, ni por los bienes
          entregados y/o contenidos en el interior de los paquetes entregados
          por las Empresas de Encomiendas y Envíos o los Transportista Flex, en
          tanto que no es quien brinda el Servicio de las Empresas y tampoco es
          quien despacha los productos involucrados. De esta forma, los Usuarios
          compradores reconocen y aceptan que el Usuario vendedor es el único
          responsable por la existencia, estado, calidad y cantidad de los
          bienes enviados, en conformidad con la publicación por él realizada.
          El Usuario comprador y el Usuario vendedor reconocen y aceptan que
          Mall no es responsable por ninguna de las actividades de las Empresas
          de Encomiendas y Envíos o de los Transportistas Flex, o los hechos de
          sus dependientes, así como tampoco por los hechos de fuerza mayor o
          caso fortuito que impidan o demoren el Servicio de Mall Envíos. Son
          hechos de fuerza mayor o caso fortuito, los acontecimientos de la
          naturaleza o hechos humanos respectivamente, ambos de carácter
          imprevisibles o inevitables, ajenos a Mall y a las Empresas de
          Encomiendas y Envíos y a los Transportistas Flex que le imposibiliten
          a las Empresas de Encomiendas y Envíos y/o a los Transportistas Flex
          y/o a Mall cumplir con sus obligaciones total o parcialmente, a pesar
          de haber actuado con la diligencia debida. En particular, Mall no
          responderá por los desperfectos técnicos, mecánicos, eléctricos,
          magnéticos, electrónicos, fotográficos o de grabaciones, que pudieran
          tener los productos transportados. Los Usuarios entienden y reconocen
          que en caso de ocurrencia de algún supuesto de caso fortuito o fuerza
          mayor, avería o destrucción de los bienes transportados por defecto
          propio de los mismos en forma exclusiva, se entenderá que los bienes
          se transportan a riesgo del Usuario que invistiere el carácter de
          propietario de ellos y por consiguiente serán a su cargo las pérdidas
          y averías que sufran durante el traslado.
        </div>
        <div class="shipment-subtitle">
          12. Responsabilidad del Usuario
        </div>
        <div class="shipment-text">
          Es de responsabilidad tanto del Usuario comprador como del Usuario
          vendedor la veracidad de la información requerida por la plataforma de
          Mall (que a vía de ejemplo será en cuanto a datos personales y
          categorización de los bienes), no siendo responsable la Empresa de
          Encomiendas y Envíos de que, al momento de concurrir a despachar el
          envío, el mismo no le sea admitido al Usuario vendedor por haber
          brindado información falsa o incorrecta, o al momento de retirar el
          envío, el mismo no sea entregado a la persona designada por el Usuario
          comprador por falta de autorización para ello. El Usuario comprador y
          el Usuario vendedor consienten expresamente que todos los datos
          incorporados que sean necesarios para el Servicio de Mall Envíos y la
          determinación de su precio sean recolectados y almacenados por Mall,
          quien podrá transmitirlos a la Empresa de Encomiendas y Envíos o al
          Transportista Flex seleccionado con el exclusivo fin de facilitar el
          Serviciode las Empresas. La Empresa de Encomiendas y Envíos y el
          Transportista Flex no estarán autorizados a recolectar los datos con
          otros fines, salvo que ello sea aceptado por los Usuarios directamente
          ante la Empresa de Encomiendas y Envíos y/o el Transportista Flex
          (según el caso). Adicionalmente, el Usuario vendedor será responsable
          por las averías o destrucción que sufran los bienes transportados
          derivada de los defectos propios de los bienes o su embalaje. El
          Usuario comprador reconoce y acepta que al momento de recibir el envío
          bajo el Servicio de las Empresas, es recomendable que revise el estado
          general del mismo así como su contenido, y realizar todo reclamo por
          daños a la brevedad, en tanto que los plazos de reclamación aplicables
          son muy breves, y que Mall no será responsable por las consecuencias
          del atraso del Usuario comprador en formular los reclamos pertinentes
          contra las Empresas de Encomiendas y Envíos o los Transportistas Flex.
          Asimismo, reconoce y acepta que podrá rechazar el envío recibido bajo
          el Servicio de las Empresas si el mismo estuviera dañado, no teniendo
          obligación de aceptarlo.
        </div>
        <div class="shipment-subtitle">
          13. Responsabilidad de la Empresa de Encomiendas y Envíos y
          Transportistas Flex que brinden el Servicio de Empresa
        </div>
        <div class="shipment-text">
          En caso de pérdidas y/o averías que sufran el o los productos, durante
          su traslado exclusivamente por causa imputable a las Empresas de
          Encomiendas y Envíos o al Transportista Flex que prestaran el Servicio
          de Empresa, el Usuario afectado podrá perseguir la responsabilidad y
          la reparación del perjuicio provocado por éstos directamente a los
          mismos.
        </div>
        <div class="shipment-subtitle">
          14. Productos restringidos
        </div>
        <div class="shipment-text">
          Asimismo, los Usuarios reconocen que los envíos que programen a través
          del Servicios de Mall Envíos, no podrán consistir en el envío de
          objetos inflamables, corrosivos, explosivos, perecederos, alimentos,
          precursores químicos, dinero en efectivo, monedas metálicas o
          cualquier otro valor, obras de arte, tarjetas de crédito, débito o
          compra, billetes de lotería y/o cualquier otro tipo de comprobantes de
          juego de azar, joyas, plantas, animales, elementos líquidos y/o
          frágiles y/o sustancias de carácter infeccioso y/o peligroso y/o
          deletéreas y/o tóxicas y/o contaminante, material magnético, armas de
          fuego de cualquier tipo, pólvora, municiones y afines, materiales
          radioactivos, cualquier otro elemento que ponga en peligro la
          seguridad de los bienes de las Empresa de Encomiendas y Envíos y/o de
          los TransportistasFlex, y/o de las personas y/o de los envíos en
          general, estupefacientes o cualquier otra sustancia y/o material cuyo
          uso o tenencia esté penado legalmente o que se hubieran obtenido en
          violación a normas del código aduanero y/o de cualquier otra normativa
          vigente, como así también bienes cuyo transporte y/o circulación esté
          prohibida, restringida o requiera tratamiento especial (medidas de
          transporte distintas a la carga general) o habilitación y/o unidades
          distintas a la distribución de carga general en la República
          Argentina, como por ejemplo, cadena de frío, o no sea aceptada por la
          Empresa de Encomiendas y Envíos y/o los Transportistas Flex. El
          listado precedente es a título meramente ejemplificativo, debiendo el
          Usuario verificar si existen restricciones adicionales en el sitio web
          de la Empresa de Encomiendas y Envíos que corresponda. A todos los
          efectos, los Usuarios reconocen y aceptan que el mero hecho que una
          categoría en la plataforma de Mall se muestre como habilitada para los
          Servicios de Mall Envíos no implica desde ya una obligación de Mall de
          prestar el Servicio de Mall, ni tampoco una obligación de las Empresas
          de Encomiendas y Envíos de prestar el Servicio de las Empresas o
          aceptar la imposición de paquetes que tengan objetos prohibidos de
          acuerdo a las regulaciones que rijan su actividad. Asimismo, los
          Usuarios reconocen y aceptan que ni las Empresas de Encomiendas y
          Envíos ni Mall serán responsables de aceptar dichos productos no
          admisibles. El único y exclusivo responsable sobre la aceptación de
          los envíos a imponer resulta ser el Usuario vendedor, razón por la
          cual este último se obliga a indemnizar y eximir de toda
          responsabilidad a ambas empresas de toda posible contingencia que
          pudiera llegar a derivarse en su contra como derivación directa o
          indirectamente de la violación a las condiciones de transporte
          aludidas en este párrafo.
        </div>
        <div class="shipment-subtitle">
          15. Seguros
        </div>
        <div class="shipment-text">
          El Servicio de Mall Envíos no incluye seguros. Si un Usuario deseara
          contratar seguros, lo deberá hacer por su cuenta. Cuando un Usuario
          contrate seguros respecto de los bienes a ser transportados a través
          del Servicio de Mall Envíos, dicho Usuario deberá exigir a su
          aseguradora la inclusión de una cláusula por la cual dicha compañía
          renuncie expresamente a promover acción de repetición y/o cualquiera
          que pudiera corresponderle contra Mall y/o cualquier Empresa de
          Encomiendas y Envíos que preste el Servicio de Empresa ante cualquier
          hecho de pérdida o daño que pudiera afectar a los bienes asegurados
          y/o a terceros.
        </div>
        <div class="shipment-subtitle">
          16. Atención de reclamos
        </div>
        <div class="shipment-text">
          En caso de que el Usuario tenga un reclamo que formular con relación
          al Servicio de Mall Envíos, deberá contactarse con Mall mediante los
          canales que ésta habilite, para que Mall gestione los procesos de
          reclamo respectivos.
        </div>
        <div class="shipment-subtitle">
          17. Envíos internacionales
        </div>
        <div class="shipment-text">
          En el caso de los envíos internacionales, los Usuarios declaran tener
          conocimiento, y aceptan que los productos transportados, aún en forma
          interna, pueden ser objeto de controles de parte de organismos
          aduaneros, policiales, postales, sanitarios, agrícolas, ganaderos u
          otros que, legalmente, pudieran solicitar la intervención en la
          comisión del servicio de las Empresas de Encomiendas y Envíos, y que
          de dichas actuaciones puedan derivarse por ejemplo, el cobro de
          derechos de importación en el país de destino, tales como aranceles
          aduaneros e impuestos, los que no están incluidos en la tarifa del
          Servicio de Mall Envíos y, de ser exigibles, deberán ser pagados en su
          destino a la autoridad administrativa que corresponda, por el Usuario
          comprador.
        </div>
        <!-- falta agregar "te fue util esta informacion?" -->
      </div>
    </div>
  </div>
</template>
<script></script>
