<template>
  <v-dialog v-model="$Profile.openUpdateShoeData">
    <div class="completation-modals">
      <div class="back-close">
        <div class="cross-close" @click="$Profile.openUpdateShoeData = false">
          <img src="../../assets/close-cross.svg" alt="logo" />
        </div>
      </div>
      <div class="shoe-image">
        <img src="../../assets/shoe-blue.svg" alt="logo" />
      </div>

      <div class="shoesize-title">¿Cuál es tu número de calzado?</div>
      <div class="shoesize-text">
        Con esto te mostramos modelos aproximados a tu talle.
      </div>
      <div class="grid-shoe">
        <div class="size-select-modal">
          <v-select
            v-model="select"
            :items="items"
            item-title="shoeType"
            label="Tipo de calzado"
            variant="outlined"
          ></v-select>
        </div>
        <div class="shoe-container">
          <div
            class="shoesize-container"
            v-if="select === 'Argentino'"
          >
            <div
              :class="[
                'shoesize-item',
                { selected: isSelectedShoeSize(shoeSize) || selectedShoesize === shoeSize },
              ]"
              v-for="shoeSize in argShoesizes"
              :key="shoeSize"
              @click="updateShoesize(shoeSize); updateSizeType()"
            >
              {{ shoeSize }}
            </div>
          </div>
          <div
            class="shoesize-container"
            v-if="select === 'Americano'"
          >
            <div
              :class="[
                'shoesize-item',
                { selected: isSelectedShoeSize(shoeSize) || selectedShoesize === shoeSize },
              ]"
              v-for="shoeSize in americanShoesizes"
              :key="shoeSize"
              @click="updateShoesize(shoeSize); updateSizeType()"
            >
              {{ shoeSize }}
            </div>
          </div>
          <div
            class="shoesize-container"
            v-if="select === 'Brasilero'"
          >
            <div
              :class="[
                'shoesize-item',
                { selected: isSelectedShoeSize(shoeSize) || selectedShoesize === shoeSize },
              ]"
              v-for="shoeSize in brShoesizes"
              :key="shoeSize"
              @click="updateShoesize(shoeSize); updateSizeType()"
            >
              {{ shoeSize }}
            </div>
          </div>
        </div>
      </div>
      <button class="modal-button" @click="lastModal">
        Listo
      </button>
    </div>
  </v-dialog>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { useProfileStore } from "@/store/stores";

const selectedShoesize = ref(null);
const lastProfile = ref(null);

const americanShoesizes = [
  "4",
  "4.5",
  "5",
  "5.5",
  "6",
  "6.5",
  "7",
  "7.5",
  "8",
  "8.5",
  "9",
  "9.5",
  "10",
  "10.5",
  "11",
  "11.5",
  "12",
  "12.5",
  "13",
  "13.5",
  "14",
  "14.5",
  "15",
  "15.5",
  "16",
  "16.5",
  "17",
  "17.5",
];
const argShoesizes = [
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
];
const brShoesizes = [
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
];
const select = ref("Argentino" );
const items = ref([
  { shoeType: "Argentino" },
  { shoeType: "Americano" },
  { shoeType: "Brasilero" },
]);

const updateShoesize = (shoeSize) => {
  if (selectedShoesize.value === shoeSize) {
    selectedShoesize.value = null;
  } else {
    selectedShoesize.value = shoeSize;
    dataToSend.shoeSize = shoeSize;
  }
  dataToSend.shoeSize = selectedShoesize.value;
};


const updateSizeType = () => {
  dataToSend.sizeType = select.value;
};

const isSelectedShoeSize = (shoeSize) => {
  if(lastProfile.value?.id !== $Profile.currentProfile?.id) {
    lastProfile.value = $Profile.currentProfile;
    selectedShoesize.value = $Profile.currentProfile.shoeSize;
  }
  return selectedShoesize.value === shoeSize;
};
const $Profile = useProfileStore();
const dataToSend = reactive({
  shoeSize: "",
  sizeType: "",
});
const lastModal = () => {
  $Profile.currentProfile.shoeSize = dataToSend.shoeSize;
  $Profile.currentProfile.sizeType = dataToSend.sizeType;
  $Profile.openUpdateShoeData = false;
  updateSizeType();
};
onMounted(async () => {
  selectedShoesize.value = $Profile.currentProfile.shoeSize;
});

</script>
