<script setup>
import { ref } from "vue";
const props = defineProps(["data"]);
const emits = defineEmits(["send-data"]);

const starSelected = ref();
if(props.data !== 0) {
  starSelected.value = props.data;
}

const select = (n) => {
  starSelected.value = n;
  emits("send-data", starSelected.value);
};
</script>

<template>
  <div class="rating-stars">
    <v-icon v-for="star in [1, 2, 3, 4, 5]" @click="select(star)" :class="{active: starSelected >= star}" :key="star">{{ starSelected >= star ? "mdi-star" : "mdi-star-outline" }}</v-icon>
  </div>
</template>