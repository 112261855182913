<script setup>
/* eslint-disable */
//vue
import { ref, computed, reactive, onMounted } from "vue";
//vue-router
import { useRouter, useRoute } from "vue-router";
//stores
import { useAddressStore } from "@/store/address";
import { useUserStore } from "@/store/user";

const router = useRouter();
const route = useRoute();
const $A = useAddressStore();
const $U = useUserStore();

if (route.params.idToEdit) {
  let toEdit = reactive({});
  onMounted(async () => {
    toEdit = await $A.getAddress(route.params.idToEdit);
    newAddressData.alias = toEdit.alias
    newAddressData.postalCode = toEdit.postalCode
    newAddressData.state = toEdit.state
    newAddressData.location = toEdit.location
    newAddressData.mainStreet = toEdit.mainStreet
    newAddressData.number = toEdit.number
    newAddressData.department = toEdit.department
    newAddressData.firstIntersection = toEdit.firstIntersection
    newAddressData.secondIntersection = toEdit.secondIntersection
    newAddressData.indications = toEdit.indications
    newAddressData.phone = toEdit.phone
  });
};

const step = ref(1);
const currentTitle = computed(() => {
  switch (step.value) {
    case 1: return "Datos de usuario";
    case 2: return "Datos de ciudad";
    case 3: return "Datos del domicilio";
    case 4: return "Datos del domicilio";
    default: return "Confirmar los datos";
  }
});

const newAddressData = reactive({
  userClientId: $U.currentUser.id,
  alias: "",
  postalCode: "",
  state: "",
  location: "",
  mainStreet: "",
  number: "",
  department: "",
  firstIntersection: "",
  secondIntersection: "",
  indications: "",
  phone: "",
});

const addAddress = async () => {

  if (route.params.idToEdit) {
    delete newAddressData.userClientId
    await $A.updateAddresses(route.params.idToEdit, newAddressData);
  } else {
    await $A.newAddress(newAddressData);
  }

  const path = computed(() => route.path)
  const pathParts = path.value.split('/');
  if (pathParts[1] === "profile") {
    router.push(`/profile/info/my-addresses`);
  } else {
    router.push(`/cart/purchase/select/address`);
  }
}

const validate = computed(() => {
  if(
    newAddressData.alias === "" ||
    newAddressData.phone === "" ||
    newAddressData.postalCode === "" ||
    newAddressData.state === "" ||
    newAddressData.location === "" ||
    newAddressData.mainStreet === "" ||
    newAddressData.number === "" ||
    newAddressData.firstIntersection === "" ||
    newAddressData.secondIntersection === ""
  ) {
    return true
  } else {
    return false
  }
});
</script>

<template>
  <v-card class="mx-auto" max-width="500">
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar class="mx-3" color="#0057ff" size="24" v-text="step"></v-avatar>
    </v-card-title>

    <v-window v-model="step">

      <v-window-item :value="1">
        <v-card-text>
          <v-row>

            <!-- nombre y apellido -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.alias" label="Nombre y apellido" hint="Tal cual figure en el DNI."
                persistent-hint required></v-text-field>
            </v-col>

            <v-divider class="border-opacity-0"></v-divider>

            <!-- Teléfono de contacto -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.phone" label="Teléfono de contacto" type="number"
                required></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          <v-row>

            <!-- codigo postal -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.postalCode" label="Código postal" type="number"
                required></v-text-field>
            </v-col>

            <!-- provincia -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.state" label="Provincia" required></v-text-field>
            </v-col>

            <!-- localidad -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.location" label="Localidad" required></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="3">
        <v-card-text>
          <v-row>

            <!-- calle/avenida -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.mainStreet" label="Calle/Avenida" required></v-text-field>
            </v-col>

            <!-- numero -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.number" label="Número" type="number" required></v-text-field>
            </v-col>

            <!-- piso/departamento -->
            <v-col cols="12">
              <v-text-field v-model="newAddressData.department" label="Piso/Departamento (opcional)"></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="4">
        <v-card-text>
          <v-row>

            <v-col cols="12">
              <p>¿Entre qué calles está? (opcional)</p>
            </v-col>

            <v-divider class="border-opacity-0" />

            <!-- calle 1 -->
            <v-col cols="12" sm="6">
              <v-text-field v-model="newAddressData.firstIntersection" label="Calle 1" required></v-text-field>
            </v-col>

            <!-- calle 2 -->
            <v-col cols="12" sm="6">
              <v-text-field v-model="newAddressData.secondIntersection" label="Calle 2" required></v-text-field>
            </v-col>

            <!-- indicaciones -->
            <v-col cols="12">
              <v-textarea v-model="newAddressData.indications"
                label="Indicaciones adicionales de esta dirección (opcional)"></v-textarea>
            </v-col>

          </v-row>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="5">
        <div class="pa-4">
          <p>Nombre y apellido: {{ newAddressData.alias }}</p>
          <p>Telefono de contacto: {{ newAddressData.phone }}</p>
          <p>Codigo postal: {{ newAddressData.postalCode }}</p>
          <p>Provincia: {{ newAddressData.state }}</p>
          <p>Localidad: {{ newAddressData.location }}</p>
          <p>Calle/Avenida: {{ newAddressData.mainStreet }}</p>
          <p>Numero: {{ newAddressData.number }}</p>
          <p v-if="newAddressData.department">Piso/Departamento: {{ newAddressData.department }}</p>
          <p>Calle 1: {{ newAddressData.firstIntersection }}</p>
          <p>Calle 2: {{ newAddressData.secondIntersection }}</p>
          <p v-if="newAddressData.indications">Indicaciones: {{ newAddressData.indications }}</p>
        </div>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn v-if="step > 1" variant="text" @click="step--">
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="step < 5" color="#0057ff" variant="flat" @click="step++">
        Next
      </v-btn>
      <h3 v-if="step === 5 && validate === true">Faltan datos</h3>
      <v-btn v-if="step === 5 && validate === false" color="#0057ff" variant="flat" @click="addAddress">
        {{ route.params.idToEdit ? "Cambiar" : "Agregar" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>