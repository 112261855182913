<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//vue-router
import { useRoute } from "vue-router";
//components
import bannerCarrousel from "@/components/shared/bannerCarrousel.vue";
import CategoriesCarrousel from "@/components/shared/CategoriesCarrousel.vue";
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";
import ProfileCompletation from "@/components/shared/ProfileCompletation.vue";
//store
import { useProductStore, useBannerStore } from "@/store/stores";

const route = useRoute();
const $P = useProductStore();
const $B = useBannerStore();

const loaded = ref(false);
const merchant = ref({});
const banners = ref([]);
const categories = ref([]);
const products = ref(null);
const count = ref();

const loadMore = async () => {
  if(products.value.length < count.value) {
    const res = await $P.getByMerchant(route.params.merchant, null, products.value.length);
    products.value.push(...res.articles);
    return true
  } else {
    return false;
  }
};

const scrollUp = () => {
  window.scrollBy({
    top: -window.scrollY,
    left: 0,
    behavior: 'smooth'
  });
}

onMounted(async () => {
  banners.value = await $B.getBannersByMerchant(route.params.merchant);
  categories.value = await $P.getCategoriesByMerchant(route.params.merchant);
  merchant.value = await $P.getMerchant(route.params.merchant);
  loaded.value = true
  const res = await $P.getByMerchant(route.params.merchant, null, 0);
  products.value = res.articles;
  count.value = res.count;
});

const filter = async (data) => {
  if(data) {
    products.value = null;
    const res = await $P.getByMerchant(route.params.merchant, null, 0);
    products.value = res.articles;
    count.value = res.count;
  }
}

</script>

<template>
  <div v-show="loaded" class="merchant-container">

    <div @click="scrollUp" class="logo">
      <img :src="merchant.image">
    </div>

    <div v-if="banners.length > 0" class="merchant-banner">
      <bannerCarrousel :data="banners?.map(img => img.image)" />
    </div>

    <CategoriesCarrousel :data="categories" />

    <InfiniteComponent :products="products" :function="loadMore" :ig="true" />

    <ProfileCompletation @apply-filter="filter" />

  </div>
</template>