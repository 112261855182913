import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
export const useContactusStore = defineStore("contactus", () => {
  const newMessage = async (dataToSend) => {
    try {
      await fetchWrapper.post("contacts", dataToSend);
    } catch (error) {
      console.log(error);
    }
  };
  return {
    newMessage,
  };
});