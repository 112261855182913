<template>
  <div class="shop-view">
    <div class="shop-brand">
      <img src="../assets/little-noa.svg"/>
    </div>
    <div class="shop-banner">
      <img src="../assets/shop-banner.svg"/>
    </div>
    <div class="shop-tags">
      <div class="box box1">
        <img src="../assets/bottle1.svg"/>
        <div class="box-title">Accesorios</div>
      </div>
      <div class="box box2">
        <img src="../assets/shoes1.svg"/>
        <div class="box-title">Calzado</div>
      </div>
      <div class="box box3">
        <img src="../assets/kid1.svg"/>
        <div class="box-title">Conjuntos</div>
      </div>
      <div class="box box4">
        <img src="../assets/pullover1.svg"/>
        <div class="box-title">Tejidos</div>
      </div>
    </div>
    <div class="shop-links">
      <div class="shop-square">
        <div :class="['logo-container', { hover }]"></div>
        <div>Sucursales</div>
      </div>
      <div class="branch-square">
        <div :class="['branch-ofices', { hover }]"></div>
        <div>Promociones</div>
      </div>
      <RouterLink to="/contactus">
        <div class="email-square">
          <div :class="['email-ofices', { hover }]"></div>
          <div>Contacto</div>
        </div>
      </RouterLink>
    </div>
    <div class="shop-cards">
      <CardComponent
        v-for="card in filteredProducts"
        :key="card.id"
        :data="card"
      />
    </div>
  </div>
</template>

<script setup>
import CardComponent from "@/components/shared/CardComponent.vue";
import { RouterLink } from "vue-router";
import { ref, onMounted } from "vue";
import { useProductStore } from "../store/product";

const $P = useProductStore();
const filteredProducts = ref([]);
onMounted(async () => {
  filteredProducts.value = await $P.getByIds({
    ids: [
      "2243bcf0-1f24-4c78-ba81-6c790dcf0260",
      "8de88052-2b66-495b-8d59-db522c000a11",
      "13e96c7e-859c-4fe9-a55c-907a47e593a0",
      "59912f1f-5388-4ea2-8601-a687a87a1918",
    ],
  });
});
</script>
