<script setup>
/* eslint-disable */
//vue-router
import { useRouter, useRoute } from "vue-router";
//stores
import { useAddressStore } from "@/store/address.js";

const props = defineProps(["data", "index"]);
const { deleteAddress } = useAddressStore();
const router = useRouter();
const route = useRoute();

//ModalData
const items = [
  {
    title: "Editar",
    function: () => {
      router.push(`/profile/edit/address/${props.index}`)
    }
  },
  {
    title: "Eliminar",
    function: async () => {
      await deleteAddress(props.index)
    }
  }
];
</script>

<template>
  <div class="addressDetails">
    <v-icon class="icon">mdi-home-variant-outline</v-icon>
    <p class="title" v-if="data.department === ''">{{ data.mainStreet + " " + data.number }}</p>
    <p class="title" v-else>{{ data.mainStreet + " " + data.number + " " + data.department }}</p>
    <div class="details">
      <p>{{ data.state + " - " + data.location + " - Código postal " + data.postalCode }}</p>
      <p>{{ data.alias + " - " + data.phone }}</p>
    </div>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-icon class="modal-menu" v-bind="props">mdi-dots-vertical</v-icon>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" :value="index">
          <v-list-item-title @click="item.function">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>