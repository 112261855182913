<script setup>
//vue
import { ref } from "vue";
//stores
import { useCartStore } from "@/store/stores";
const $C = useCartStore();
const dltModal = ref(false);
</script>

<template>
  <div class="security-container">
    <div class="btns">
      <button @click="$C.deleteAll" class="blue-button">Limpiar carrito</button>
      <button @click="dltModal = true" class="blue-button">Eliminar cuenta</button>
    </div>
    <v-dialog v-model="dltModal">
      <div class="delete-user">
        <img src="../../assets/logos/mall.svg" >
        <p>¿Deseas eliminar esta cuenta?</p>
        <div class="btns">
          <span @click="dltModal = false" class="form-button">Cancelar</span>
          <span @click="$U.deleteUser($U.currentUser.id)" class="blue-button">Eliminar</span>
        </div>
      </div>
    </v-dialog>
  </div>
</template>