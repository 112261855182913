<template>
  <div class="floating-banner" v-show="profileStore.openFloating">
    <div class="floating-cross" @click="profileStore.openFloating = !profileStore.openFloating">
      <img src="../../assets/close-cross.svg" alt="logo" />
    </div>
    <div class="floating-gift">
      <img src="../../assets/gift.svg" alt="logo" />
    </div>
    <div class="floating-tittle">Hacé tu regalo a medida</div>
    <div class="floating-text">
      Solo responde 3 preguntas y te sugerimos los mejores regalos que se
      adaptan a esa persona especial
    </div>
    <div class="floating-btn"><v-btn>¡Hacé tu mejor regalo!</v-btn></div>
  </div>
</template>
<script setup>
import { useProfileStore } from "@/store/profile.js";

const profileStore = useProfileStore();
</script>
