<template>
  <div class="firstlogin-view">
    <v-dialog v-model="profileStore.createNewProfile">
      <div v-if="profileStore.currentView === 0">
        <CreateAlias />
      </div>
      <div v-if="profileStore.currentView === 1">
        <GenderModal />
      </div>
      <div v-if="profileStore.currentView === 2">
        <BottomSize />
      </div>
      <div v-if="profileStore.currentView === 3">
        <TopModal />
      </div>
      <div v-if="profileStore.currentView === 4">
        <ShoesizeModal />
      </div>
    </v-dialog>
  </div>
</template>
<script setup>
import BottomSize from "../login/BottomSize.vue";
import GenderModal from "../login/GenderModal.vue";
import ShoesizeModal from "../login/ShoesizeModal.vue";
import TopModal from "../login/TopModal.vue";
import CreateAlias from "../login/CreateAlias.vue";

import { useProfileStore } from "@/store/stores";

const profileStore = useProfileStore();
</script>
