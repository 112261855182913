<script setup>
/* eslint-disable */
//vue-router
import { RouterLink } from "vue-router";
//helpers
import { getRandomColor } from "@/helpers/randomColor";
//props
defineProps(["data"]);

const getIcon = img => {
  return img ? require("@/assets/svgs/" + img) : "";
};

</script>

<template>
  <RouterLink :to="data.path" class="category">
    <div v-if="data.icon" class="category-icon">
      <img :src="getIcon(data.icon)">
    </div>
    <div v-else-if="data.image" class="category-img">
      <img :src="data.image" :alt="data.nameCategory">
    </div>
    <div v-else class="category-img" :style="{ backgroundColor: getRandomColor() }">
      <span>{{ data.initials }}</span>
    </div>
    <h1>{{ data.nameCategory.toUpperCase() }}</h1>
    <h2 v-if="data.type === 'client'">@{{ data.username }}</h2>
  </RouterLink>
</template>