<template>
  <div>
    <p>{{ initials }}</p>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";

onMounted(() => {
  watch(
    () => props.inputData,
    (newVal) => {
      inputString.value = newVal;
      countWordsAndGenerateInitials();
    }
  );
  countWordsAndGenerateInitials();
  getPositionLetters();
});

const props = defineProps({
  inputData: String,
});


const inputString = ref(props.inputData);
console.log("inputdata", props.inputData);
const wordCount = ref(0);
const initials = ref("");
const countWordsAndGenerateInitials = () => {
  if (!inputString.value) {
    return (initials.value = "--");
  } else {
    const words = inputString.value.trim().split(/\s+/);
    wordCount.value = words.length;

    let initialsValue = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initialsValue += words[i][0];
    }
    initials.value = initialsValue.toUpperCase();
  }
};
const getPositionLetters = (letter) => {
  const alphabetArray = [
    ["a", "b", "c", "d", "e", "f", "g", "h"],
    ["i", "j", "k", "l", "m", "n", "o", "p", "q", "r"],
    ["s", "t", "u", "v", "w", "x", "y", "z"],
  ];

  let groupNumber = 0;
  letter = letter?.toLowerCase();

  for (let i = 0; i < alphabetArray.length; i++) {
    if (alphabetArray[i].includes(letter)) {
      groupNumber = i + 1;
      break;
    }
  }

  let result;
  if (groupNumber === 1) {
    result = "red";
  } else if (groupNumber === 2) {
    result = "blue";
  } else if (groupNumber === 3) {
    result = "violet";
  }
  return result;
};

</script>
