import { defineStore } from "pinia";
import { ref } from "vue";

export const userPayMethodStore = defineStore("paymethod", () => {
  const modalPayments = ref(false);

  const openPaymentsModal = () => {
    modalPayments.value = !modalPayments.value;
  };
  return {
    openPaymentsModal,
    modalPayments,
  };
});
