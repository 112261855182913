<script setup>
//vue
import { onMounted, onBeforeUnmount } from "vue";
//components
import NavbarComponent from "./components/NavbarComponent.vue";
import NavbarMobileComponent from "./components/NavbarMobileComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import PopUpComponent from "./components/PopUpComponent.vue";
//store
import { useWindowStore, useUserStore, useCapacitorStore } from "./store/stores";
import mixpanel from "mixpanel-browser";
const $W = useWindowStore();
const $U = useUserStore();
const $C = useCapacitorStore(); 

mixpanel.init("94a8690dca48ab2c1bc8c5068b879d46", {debug: true, track_pageview: true, persistence: "localStorage"});

onMounted(async () => {
  window.addEventListener("resize", $W.handleSize);
  $U.checkUserInfo();
  await $C.logDeviceInfo();
  if($C.deviceInfo?.platform !== "web") {
    $C.registerNotifications();
    $C.addListeners();
  }
});
onBeforeUnmount(() => {
  window.addEventListener("resize", $W.handleSize);
});
</script>

<template>
	<v-app>
		<NavbarComponent v-if="$W.windowWidth > 768" />
		<NavbarMobileComponent v-else />
		<div id="content">
			<RouterView :key="$route.fullPath" />
		</div>
		<FooterComponent />
		<PopUpComponent />
	</v-app>
</template>