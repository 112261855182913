<template>
  <div class="payments-modal">
    <div class="payments-modal-view">
      <div class="payments-modal-title">
        Medios de pago MALL
      </div>
      <img src="@/assets/close-cross.svg" class="cross-close" @click="$PAY.openPaymentsModal" alt="logo" />
      <div class="payments-modal-subtitle">Tarjetas de debito</div>
      <div class="payments-modal-text">Acreditacion instantanea</div>

      <div class="debit-cards-list">
        <div class="debit-list">
          <div class="debit-img"><img src="@/assets/logos/maestro.png" /></div>
        </div>
        <div class="debit-list">
          <div class="debit-img"><img src="@/assets/logos/cabal.png" /></div>
        </div>
        <div class="debit-list">
          <div class="debit-img">
            <img src="@/assets/logos/mastercard.png" />
          </div>
        </div>
        <div class="debit-list">
          <div class="debit-img"><img src="@/assets/logos/visa.jpg" /></div>
        </div>
      </div>
      <div class="payments-modal-subtitle">Tarjetas de credito</div>
      <div class="payments-modal-text">Acreditacion instantanea</div>

      <div class="credit-cards-list">
        <div class="credit-list">
          <div class="credit-img">
            <img src="@/assets/logos/american.png" />
          </div>
        </div>
        <div class="credit-list">
          <div class="credit-img"><img src="@/assets/logos/cabal.png" /></div>
        </div>
        <div class="credit-list">
          <div class="credit-img">
            <img src="@/assets/logos/mastercard.png" />
          </div>
        </div>
        <div class="credit-list">
          <div class="credit-img"><img src="@/assets/logos/visa.jpg" /></div>
        </div>
        <div class="credit-list">
          <div class="credit-img"><img src="@/assets/logos/naranja.png" /></div>
        </div>
      </div>
      <div class="payments-modal-text">
        La cantidad de cuotas puede variar segun la tarjeta
      </div>
      <!-- <div class="payments-modal-subtitle">Efectivo</div>
        <div class="payments-text">
          Es muy simple: cuando termines tu compra, te daremos las instrucciones
          para que sepas como y donde pagarla
        </div>
        <div class="payment-img"><img src="@/assets/logos/pagofacil.png" /></div> -->
    </div>
  </div>
</template>

<script setup>
import { userPayMethodStore } from "@/store/stores";

const $PAY = userPayMethodStore();

</script>
