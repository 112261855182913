<script setup>
//vue-router
import { useRoute } from "vue-router";
//components
import CardComponent from "@/components/shared/CardComponent.vue";
//store
import { useProductStore } from "@/store/stores";
//helpers
import { capitalize } from "@/helpers/capitalize";

const route = useRoute();
const $P = useProductStore();
</script>

<template>
  <div class="type-container">
    <h2>{{ capitalize(route.params.type) }}</h2>
    <div v-if="route.params.type === 'tendencia'" class="products">
      <CardComponent v-for="(product, index) in $P.trending" :data="product" :key="index" />
    </div>
    <div v-if="route.params.type === 'los mas vistos'" class="products">
      <CardComponent v-for="(product, index) in $P.seen" :data="product" :key="index" />
    </div>
    <div v-if="route.params.type === 'nuevos ingresos'" class="products">
      <CardComponent v-for="(product, index) in $P.admitted" :data="product" :key="index" />
    </div>
  </div>
</template>