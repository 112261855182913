<script setup>
/* eslint-disable */
import { computed } from "vue";
import { usePopStore } from "@/store/stores";

const $P = usePopStore();
const popUp = computed(() => $P.popUp);
const text = computed(() => $P.popUpText);
</script>

<template>
  <div v-if="popUp" class="pop-container">
    <template v-for="(t, index) in text" :key="index">
      <p v-if="t.active" :class="{success: t.status === 1, ok: t.status === 2, error: t.status === 0}">{{ t.text }}</p>
    </template>
  </div>
</template>