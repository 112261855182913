<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { useRoute, useRouter } from "vue-router";
//components
import PendingView from "@/components/profile/rating/PendingView.vue";
import CompletedView from "@/components/profile/rating/CompletedView.vue";
//store
import { useRatingStore, useUserStore } from "@/store/stores";

const route = useRoute();
const router = useRouter();

const $R = useRatingStore();
const $U = useUserStore();

const tab = computed(() => {
  return route.params.section;
});

onMounted(async () => {
  if(!$R.ratings) await $R.getRatingByUser($U.currentUser.id);
  console.log($R.ratings);
});

</script>

<template>
  <div class="ratings-container">
    <h1>Opiniones</h1>
    <div class="rating-option">
      <span @click="router.push('pending')" :class="{active: tab === 'pending'}">Pendientes</span>
      <span @click="router.push('completed')" :class="{active: tab === 'completed'}">Realizadas</span>
    </div>
    <PendingView v-if="tab === 'pending' && $R.ratings" />
    <CompletedView v-if="tab === 'completed' && $R.ratings" />
  </div>
</template>