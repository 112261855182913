<template>
  <div class="update-profile-view">
    <div></div>
    <div><v-input></v-input></div>
    <div class="update-profiles-view">
      <div
        class="update-profiles"
        v-for="profile in $PROFILES.profileList"
        :key="profile?.alias"
      >
        <div
          :class="[
            'all-profiles',
            {
              selected:
                isSelectedProfile(profile.id) ||
                selectedProfile?.id === profile.id,
            },
          ]"
        >
          <div class="all-profiles-image">
            <div v-if="profile.image">
              {{ profile.image }}
            </div>
            <div v-else :class="getInitialsClass(profile?.alias)">
              {{ generateInitials(profile?.alias) }}
            </div>
            <div class="all-profiles-alias">
              {{ profile.alias }}
            </div>
          </div>
          <div class="all-profiles-btns">
            <div>
              <v-btn class="update-edit-btn" @click="saveProfile(profile)"
                >SELECCIONAR</v-btn
              >
            </div>
            <div>
              <v-btn
                class="update-delete-btn"
                @click="$PROFILES.deleteProfile(profile.id)"
                >ELIMINAR</v-btn
              >
            </div>
          </div>
          <div class="update-edit-icons">
            <div class="select-profile">
              <v-icon
                class="mdi mdi-account-box"
                @click="saveProfile(profile)"
              ></v-icon>
            </div>
            <div class="delete-profile">
              <v-icon
                class="mdi mdi-trash-can-outline"
                @click="$PROFILES.deleteProfile(profile.id)"
              ></v-icon>
            </div>
          </div>
        </div>
      </div>
      <div>
        <v-btn class="create-profile-btn" @click="createProfile()"
          >+ Crear Perfil</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="$PROFILES.createNewProfile">
      <CreateNewProfile />
    </v-dialog>
    <v-dialog v-model="$PROFILES.updateNewProfiles">
      <UpdateProfiles />
    </v-dialog>
  </div>
</template>

<script setup>
import { useProfileStore } from "../../store/stores";
import { onMounted, ref } from "vue";
import UpdateProfiles from "@/views/profile/UpdateProfiles.vue";
import CreateNewProfile from "@/views/profile/CreateNewProfile.vue";
import { generateInitials, getInitialsClass } from "@/helpers/initials";

const selectedProfile = ref(null);
const lastProfile = ref(null);
const $PROFILES = useProfileStore();

onMounted(async () => {
  await $PROFILES.getProfiles();
  isSelectedProfile($PROFILES.currentProfile);
});

const isSelectedProfile = (profile) => {
  if (lastProfile.value?.id !== $PROFILES.currentProfile?.id) {
    lastProfile.value = $PROFILES.currentProfile;
    selectedProfile.value = $PROFILES.currentProfile;
  }
  return selectedProfile.value === profile;
};

const saveProfile = (selectedProfile) => {
  $PROFILES.currentProfile = selectedProfile;
};
const createProfile = () => {
  $PROFILES.createNewProfile = true;
  $PROFILES.currentView = 0;
};

// const updateProfile = () => {
//   $PROFILES.updateNewProfiles = true;
// };
</script>
