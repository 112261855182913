<script setup>
/* eslint-disable */
//vue
import { ref } from "vue";
//components
import AddCardForm from "@/components/profile/payment/AddCardForm.vue";
//stores
import { useWindowStore } from "@/store/window";

const $W = useWindowStore();

const formData = ref({
  number: "**** **** **** ****",
  holderName: "NOMBRE Y APELLIDO",
  cardExpiration: "MM/AA",
  company: null,
  option: null
})

const getData = (data) => {
  if (data.number === "") {
    formData.value.number = "**** **** **** ****";
  } else {
    formData.value.number = data.number;
  }

  if (data.holderName === "") {
    formData.value.holderName = "NOMBRE Y APELLIDO";
  } else {
    formData.value.holderName = data.holderName;
  }

  if (data.cardExpiration === "") {
    formData.value.cardExpiration = "MM/AA";
  } else {
    formData.value.cardExpiration = data.cardExpiration;
  }
  formData.value.company = data.company
  formData.value.option = data.option
}
</script>

<template>
  <div v-if="$W.windowWidth >= 768" class="addCard">
    <AddCardForm class="paymentForm" @send-data="getData" />
    <div :class="formData.company" class="paymentCard">

      <div class="card-top">
        <p v-if="formData.option === 'credit'">CREDITO</p>
        <p v-if="formData.option === 'debit'">DEBITO</p>
        <p v-else></p>
        <div :class="{nobg: formData.company}" class="card-image">
          <img v-show="formData.company === 'Visa'" src="../../../assets/logos/visa.svg" alt="visa">
          <img v-show="formData.company === 'Master'" src="../../../assets/logos/master.svg" alt="master">
          <img v-show="formData.company === 'Amex'" src="../../../assets/logos/amex.svg" alt="amex">
        </div>
      </div>

      <p class="card-number">{{ formData.number }}</p>

      <div class="card-info">
        <p>{{ formData.holderName }}</p>
        <p>{{ formData.cardExpiration }}</p>
      </div>
    </div>
  </div>

  <div v-else class="addCard__mobile">
    <div :class="formData.company" class="paymentCard">

      <div class="card-top">
        <p v-if="formData.option === 'credit'">CREDITO</p>
        <p v-if="formData.option === 'debit'">DEBITO</p>
        <p v-else></p>
        <div :class="{nobg: formData.company}" class="card-image">
          <img v-show="formData.company === 'Visa'" src="../../../assets/logos/visa.svg" alt="visa">
          <img v-show="formData.company === 'Master'" src="../../../assets/logos/master.svg" alt="master">
          <img v-show="formData.company === 'Amex'" src="../../../assets/logos/amex.svg" alt="amex">
        </div>
      </div>

      <p class="card-number">{{ formData.number }}</p>

      <div class="card-info">
        <p>{{ formData.holderName }}</p>
        <p>{{ formData.cardExpiration }}</p>
      </div>
    </div>

    <AddCardForm @send-data="getData" />
  </div>
</template>