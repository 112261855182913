import { defineStore } from "pinia";
import { ref } from "vue";
import { useProfileStore } from "./profile";

export const useFilterStore = defineStore("filter", () => {
  const $P = useProfileStore();
  //filter
  const filter = ref({
    checkSize: false,
    order: null,
    priceRange: [0, 300000]
  });

  const cleanFilter = () => {
    $P.currentProfile.torso = null;
    $P.currentProfile.bottomSize = null;
    $P.currentProfile.shoeSize = null;
    filter.value = {
      checkSize: false,
      order: null,
      priceRange: [0, 300000]
    };
  };

  return { filter, cleanFilter };
}, {
  persist: true
});