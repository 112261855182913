/* eslint-disable */
import { defineStore } from "pinia";
import { ref } from "vue";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore } from "@/store/stores";

export const useNotificationStore = defineStore("notifications", () => {
  const notifications = ref({
    offers: false,
    news: false,
    newMessages: false,
    shoppingNotifications: false,
    promotions: false,
  });
  const $P = usePopStore();
  const getNotifications = async () => {
    // try {
    //   const res = await fetchWrapper.get("clients/configuration");
    //   notifications.value = res.data[0];
    //   //desestructurar data
    // } catch (e) {
    //   console.log(e);
    //   $P.popUpHandler("Hubo un error al cargar las notificaciones", 0);
    // }
  };
  const updateNotifications = async () => {
    // try {
    //   const notificationsStatus = {
    //     offers: notifications.value.offers,
    //     news: notifications.value.news,
    //     newMessages: notifications.value.newMessages,
    //     shoppingNotifications: notifications.value.shoppingNotifications,
    //     promotions: notifications.value.promotions,
    //   };
    //   await fetchWrapper.put("clients/configuration", notificationsStatus);
    //   console.log("updatenotifications ",notificationsStatus);
    //   $P.popUpHandler("Notificaciones actualizadas correctamente", 1);
    // } catch (error) {
    //   console.error("Error al actualizar las notificaciones:", error);
    //   $P.popUpHandler("Hubo un error al actualizar las notificaciones", 0);
    // }
  };

  return { getNotifications, updateNotifications, notifications };
});
