/* eslint-disable */
import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore, usePurchaseStore, useCartStore } from "@/store/stores";

export const useCouponStore = defineStore("coupon", () => {
  const $P = usePopStore();  
  const $C = usePurchaseStore();
  const $CART = usePurchaseStore();

  const validateCoupon = async (code) => {
    try {
      const res = await fetchWrapper.get(`coupons/validateCoupon/${code}`);
      console.log(res.data.coupon);
      return res.data.coupon;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Cupón inválido", 0);
      return false;
    }
  };

  const useCoupon = async (cart, coupon) => {
    const properties = Object.keys(cart);
    if($C.cart.merchants[coupon.userMerchant.id]?.discount.find(c => c.couponId === coupon.id)) {
      $P.popUpHandler("Ya agregaste este cupón en esta compra", 2);
    } else if(properties.includes(coupon.userMerchant.id)) {
      const orderItems = cart[coupon.userMerchant.id].products.map(p => ({
        articleVariantId: p.variantId,
        quantity: p.quantity,
        promotion: p.isOnPromotion
      }));
      try {
        const res = await fetchWrapper.post("coupons/checkOnOrderItem", {
          couponId: coupon.id,
          orderItems: orderItems
        });
        $C.cart.merchants[coupon.userMerchant.id].discount = [];
        $C.cart.merchants[coupon.userMerchant.id].discount.push({
          couponId: coupon.id,
          totalDiscount: res.data.totalDiscount
        });
        $C.cart.totalDiscount += res.data.totalDiscount;
        console.log("res", res);
        return true;
      } catch (e) {
        $P.popUpHandler("Hubo un error al agregar el cupón", 0);
        return false;
      }
    } else {
      $P.popUpHandler("Este cupón no se puede aplicar a los productos que tenes en el carrito actualmente", 0);
    }
  };

  return {
    validateCoupon,
    useCoupon
  };
}); 