<script setup>
//vue
import { ref, onMounted } from "vue";
//store
import { useFavoriteStore } from "@/store/stores";
//component
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";

const $F = useFavoriteStore();

const favorited = ref([]);
const count = ref(0);
const loaded = ref(false);

const loadMore = async () => {
  if(favorited.value.length < count.value) {
    const res = await $F.getFavorites(favorited.value.length);
    favorited.value.push(...res.article);
    return true;
  } else {
    return false;
  }
};

onMounted(async () => {
  const res = await $F.getFavorites(0);
  favorited.value = res.article;
  count.value = res.count;
  loaded.value = true;
});
</script>

<template>
  <div v-if="!loaded"></div>
  <div v-else-if="favorited.length > 0" class="favorite-container">
    <h1>Mis favoritos</h1>
    <div class="cards">
      <InfiniteComponent :products="favorited" :function="loadMore" :full="true" />
    </div>
  </div>
  <div v-else class="empty-data">
    <img src="../../assets/shoesize-modal-blue.svg" alt="svg">
    <span>Favoritos</span>
    <p>Todavia no agregaste ningun producto a favoritos.</p>
  </div>
</template>