import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";

export const useSizesStore = defineStore("sizes", () => {

  const getSize = async (sizeId) => {
    try {
      const res = await fetchWrapper.get(`sizes/${sizeId}`);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  return { getSize };
});
