<script setup>
//vue
import { onMounted } from "vue";
//vue-router
import { RouterLink, useRoute } from "vue-router";
const route = useRoute();
//stores
import { usePurchaseStore, useCartStore } from "@/store/stores";
const $P = usePurchaseStore();
const $C = useCartStore();

onMounted(() => {
  if(route.params.result !== "order-error") $C.deleteAll();
  $P.resetPurchase();
});
</script>

<template>
  <div class="result-container">
    <div v-if="route.params.result === 'success'" class="success-container">
      <div class="icon">
        <v-icon>mdi-shopping-outline</v-icon>
        <v-icon>mdi-check-decagram</v-icon>
      </div>
      <p class="green">Compra realizada con exito</p>
      <RouterLink :to="`/profile/info/my-purchases`" class="blue-button">Ver mis compras</RouterLink>
      <RouterLink :to="'/'" class="primary-button">Volver al inicio</RouterLink>
    </div>

    <div v-if="route.params.result === 'payment-error'" class="success-container error">
      <div class="icon">
        <v-icon>mdi-credit-card-outline</v-icon>
        <v-icon>mdi-alert-circle</v-icon>
      </div>
      <p class="red">Hubo un error con el pago.</p>
      <p>La orden de compra fue creada y puede continuar con el pago en la seccion de su perfil "Mis compras".</p>
      <RouterLink :to="`/profile/info/my-purchases`" class="blue-button">Ver mis compras</RouterLink>
      <RouterLink :to="'/'" class="primary-button">Volver al inicio</RouterLink>
    </div>

    <div v-if="route.params.result === 'order-error'" class="success-container error">
      <div class="icon">
        <v-icon>mdi-shopping-outline</v-icon>
        <v-icon>mdi-alert-circle</v-icon>
      </div>
      <p class="red">Hubo un error con la orden de compra.</p>
      <p>Intentelo de nuevo disculpe las molestias.</p>
      <RouterLink :to="'/'" class="primary-button">Volver al inicio</RouterLink>
    </div>
  </div>
</template>