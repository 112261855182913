const sizeOrder = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];

export const sortSize = data => {
  if(!data) return;
  if(data[0].split(" ").pop().toUpperCase() === "MESES" || data[0].split(" ").pop().toUpperCase() === "AÑOS") {
    //DIVISION POR MESES Y AÑOS
    const month = [];
    const year = [];
    data.forEach(d => {
      const lastWord = d.split(" ").pop();
      if (lastWord.toUpperCase() === "MESES") month.push(d);
      else year.push(d);
    });
    const monthSorted = month.sort((a, b) => parseFloat(a) - parseFloat(b));
    const yearSorted = year.sort((a, b) => parseFloat(a) - parseFloat(b));
    return [...monthSorted, ...yearSorted];
  } else if (sizeOrder.includes(data[0].toUpperCase())) {
    return data.sort((a, b) => sizeOrder.indexOf(a.toUpperCase()) - sizeOrder.indexOf(b.toUpperCase())); 
  } else if(isNaN(parseFloat(data[0]))) {
    return data.sort();
  } else {
    //DIVISION NUMERO
    return data.sort((a, b) => parseFloat(a) - parseFloat(b));
  }
};