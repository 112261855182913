<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//components
import CarrouselComponent from "../components/shared/CarrouselComponent.vue";
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";
import FloatingBanner from "../components/shared/FloatingBanner.vue";
import ProfileCompletation from "../components/shared/ProfileCompletation.vue";
import FirstLogin from "./login/FirstLogin.vue";
import CategoriesCarrousel from "@/components/shared/CategoriesCarrousel.vue";
//stores
import { useProductStore, useProfileStore, useWindowStore, useFavoriteStore, useUserStore } from "@/store/stores";

const $P = useProductStore();
const $W = useWindowStore();
const $F = useFavoriteStore();
const $U = useUserStore();
const $Profile = useProfileStore();
const products = ref(null)
const count = ref()

onMounted(async () => {
  const res = await $P.getProducts(0);
  console.log(res.articles);
  count.value = res.count
  products.value = res.articles
  $Profile.updateTimerModal();
});

const loadMore = async () => {
  if(products.value.length < count.value) {
    console.log("length", products.value.length);
    const res = await $P.getProducts(products.value.length);
    products.value.push(...res.articles);
    return true
  } else {
    return false
  }
};

const filter = async (data) => {
  if(data) {
    products.value = null;
    const res = await $P.getProducts(0);
    count.value = res.count
    products.value = res.articles
  }
}

const mainCarrouselImages = [
  "banner-1.webp",
  "banner-2.webp",
  "banner-3.webp",
];

const mainCarrouselImages_mobile = [
  "banner-1-m.webp",
  "banner-2-m.webp",
  "banner-3-m.webp",
];

const categories = [
  {
    icon: "ofertas.svg",
    nameCategory: "¡Ofertas!",
    path: "/search/oferta"
  },
  {
    icon: "calzado.svg",
    nameCategory: "Calzados",
    path: "/search/zapatilla"
  },
  {
    icon: "remeras.svg",
    nameCategory: "Remeras",
    path: "/search/remera"
  },
  {
    icon: "pantalones.svg",
    nameCategory: "Pantalones",
    path: "/search/pantalon"
  },
  {
    icon: "camisas.svg",
    nameCategory: "Camisas",
    path: "/search/camisa"
  },
  {
    icon: "buzos.svg",
    nameCategory: "Buzos",
    path: "/search/buzo"
  },
  {
    icon: "blazers.svg",
    nameCategory: "Blazers",
    path: "/search/blazer"
  },
  {
    icon: "accesorios.svg",
    nameCategory: "Accesorios",
    path: "/search/accesorio"
  },
  {
    icon: "bebes.svg",
    nameCategory: "Bebes",
    path: "/search/bebe"
  }
];

</script>

<template>
    <div>
      <!-- <FirstLogin/> -->
      <div class="main-container">
        <CarrouselComponent class="main-carrousel" :images="$W.windowWidth > 768 ? mainCarrouselImages : mainCarrouselImages_mobile" />
        <CategoriesCarrousel :data="categories" />
        <InfiniteComponent :products="products" :function="loadMore" :full="true" />
      </div>
      <FloatingBanner v-if="$W.windowWidth > 768"/>
      <ProfileCompletation @apply-filter="filter" />
    </div>
</template>