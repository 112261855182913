import { defineStore } from "pinia";
import { ref } from "vue";

export const useInputStore = defineStore("inputs", () => {
  const required = ref((value) => {
    if (value) return true;
    return "Incompleto";
  });

  const validatePassword = ref((value) => {
    if (value.length >= 8) return true;
    return "La contraseña debe tener 8 o más caracteres";
  });

  const validateEmail = ref((value) => {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(validRegex)) return true;
    return "El mail es invalido";
  });

  // FORMATO DE REGLAS EN VUETIFY
  // const nameRules = ref([
  //   value => {
  //     if (value) return true

  //     return 'Name is required.'
  //   },
  //   value => {
  //     if (value?.length <= 10) return true

  //     return 'Name must be less than 10 characters.'
  //   },
  // ]);
  // DENTRO DEL INPUT
  // :rules="nameRules"

  return { required, validateEmail, validatePassword };
});
