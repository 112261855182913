<script setup>
/* eslint-disable */
//vue
import { ref, onMounted, watch } from "vue";
//vue-router
import { RouterLink, useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
//helpers
import { getRandomColor } from "@/helpers/randomColor";
//stores
import { useUserStore, useSocialStore, useFavoriteStore, useCapacitorStore } from "@/store/stores";
const $U = useUserStore();
const $F = useSocialStore();
const $X = useFavoriteStore();
const $C = useCapacitorStore();
//components
import InfiniteComponent from "@/components/shared/InfiniteComponent.vue";
//variables
const profile = ref(null);
const isFollowed = ref(null);
const stats = ref(null);
const accounts = ref({
  modal: false,
  data: null
});
const favorites = ref(null);
const favCount = ref(0);

onMounted(async () => {
  await initData();
  const res = await $F.getUserFavoriteProfile(profile.value.id, 0);
  favorites.value = res.social;
  favCount.value = res.count;
});

//functions
const initData = async () => {
  const res = await $F.getUserData(route.params.username);
  if(res) stats.value = [
    {
      name: "Tiendas",
      stat: res.merchantsFollowed
    },
    {
      name: "Seguidores",
      stat: res.followers
    },
    {
      name: "Seguidos",
      stat: res.clientsFollowed
    },
    {
      name: "Favoritos",
      stat: res.favorites
    },
  ];
  profile.value = res ? res.userClient : res;
  isFollowed.value = res ? res.isFollowed : null;
}

const loadMore = async () => {
  if(favorites.value.length < favCount.value) {
    const res = await $F.getUserFavoriteProfile(profile.value.id, favorites.value.length);
    favorites.value.push(...res.social);
    return true;
  } else {
    return false;
  }
};

const getData = async (stat_name) => {
  accounts.value.modal = true;
  if(stat_name === "Tiendas") {
    const res = await $F.getUserMerchantsFollowed(profile.value.id);
    accounts.value.data = res;
  }
  if(stat_name === "Seguidores") {
    const res = await $F.getUserFollowers(profile.value.id);
    accounts.value.data = res;
  }
  if(stat_name === "Seguidos") {
    const res = await $F.getUserFollowed(profile.value.id);
    accounts.value.data = res;
  }
  if(stat_name === "Favoritos") {
    const res = await $F.getUserFavorite(profile.value.id);
    accounts.value.data = res;
  }
}

const follow = async () => {
  if(!$U.currentUser) {
    router.push("/logToContinue");
    return;
  }
  if(!isFollowed.value) {
    const res = await $F.followUser(profile.value.id);
    if(res) {
      isFollowed.value = !isFollowed.value
      stats.value[1].stat += 1;
    }
  } else {
    const res = await $F.unfollowUser(profile.value.id);
    if(res) {
      isFollowed.value = !isFollowed.value
      stats.value[1].stat -= 1;
    }
  }
};

const dialogFollow = async (a, stat, id) => {
  if(!$U.currentUser) {
    router.push("/logToContinue");
    return;
  }
  
  if(stat === "Tiendas seguidas") {
    if(a.isFollowed) {
      const res = await $F.unfollowMerchant(id);
      if(res) a.isFollowed = !a.isFollowed;
    } else {
      const res = await $F.followMerchant(id);
      if(res) a.isFollowed = !a.isFollowed;
    }
  }

  if(stat === "Seguidores") {
    if(a.isFollowed) {
      const res = await $F.unfollowUser(id);
      if(res) a.isFollowed = !a.isFollowed;
    } else {
      const res = await $F.followUser(id);
      if(res) a.isFollowed = !a.isFollowed;
    }
  }

  if(stat === "Seguidos") {
    if(a.isFollowed) {
      const res = await $F.unfollowUser(id);
      if(res) a.isFollowed = !a.isFollowed;
    } else {
      const res = await $F.followUser(id);
      if(res) a.isFollowed = !a.isFollowed;
    }
  }

  if(stat === "Favoritos") {
    if(a.isFavorited) {
      const res = await $X.deleteFavorite(a.id);
      if(res) a.isFavorited = !a.isFavorited;
    } else {
      const res = await $X.addFavorite(a.article.id);
      if(res) a.isFavorited = !a.isFavorited;
    }
  }
};

watch(accounts.value, async () => {
  if(accounts.value.modal === false) {
    setTimeout(() => {
      accounts.value.data = null;
    }, 200);
  }
});
</script>

<template>
  <div class="loading" v-if="profile === null">
    <img class="manijita" src="../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../assets/logos/bolsa.svg">
  </div>
  <div v-else-if="profile === false" class="empty-data">
    <img src="../../assets/shoesize-modal-blue.svg" alt="svg">
    <span>No se encontro ningun usuario con ese nombre.</span>
    <RouterLink to="/">Volver al inicio</RouterLink>
  </div>
  <div v-else class="profileindex-container" :class="{one: $U.currentBg === 1, two: $U.currentBg === 2, three: $U.currentBg === 3}">
    <div class="profileindex-img">
      <div class="bg">
        <img v-if="$U.currentBg === 1" src="../../assets/images/profilebg1.webp">
        <img v-if="$U.currentBg === 2" src="../../assets/images/profilebg2.webp">
        <img v-if="$U.currentBg === 3" src="../../assets/images/profilebg3.webp">
      </div>
      <div v-if="profile.image" class="profile-img">
        <img :src="profile.image">
      </div>
      <div v-else class="profile-initials" :style="{ backgroundColor: getRandomColor() }">
        <span>{{ profile.firstName[0] }}{{ profile.lastName[0] }}</span>
      </div>
    </div>

    <div class="profile-user">
      <h1>{{ profile.firstName }} {{ profile.lastName }}</h1>
      <span>@{{ profile.username }}</span>
    </div>

    <div class="profileindex-description">
      <p>{{ profile.description }}</p>
    </div>
    
    <div class="profileindex-stats">
      <div @click="getData(s.name)" class="stat" v-for="(s, index) in stats" :key="index">
        <h1>{{ s.stat }}</h1>
        <span>{{ s.name }}</span>
      </div>

      <v-dialog v-model="accounts.modal">
        <div class="stats-dialog">
          <div v-if="!accounts.data" class="loading">
            <img class="manijita" src="../../assets/logos/manijita.svg">
            <img class="bolsa" src="../../assets/logos/bolsa.svg">
          </div>

          <div v-else class="stats-data">
            <p>{{ accounts.data.title }}</p>
            <div v-if="accounts.data.social.length" class="stats-accounts">
              <div v-for="(a, i) in accounts.data.social" class="stats-account" :key="i">
                <div class="stats-account_img">
                  <img :src="accounts.data.type ? a[accounts.data.property].image : a[accounts.data.property]?.images[0]">
                </div>

                <RouterLink :to="`/merchant/${a[accounts.data.property].id}`" v-if="accounts.data.type === 'merchant'" class="stats-account_name">
                  <h1>{{ a[accounts.data.property].name }}</h1>
                </RouterLink>
                <RouterLink :to="`/profile/${a[accounts.data.property].username}`" v-else-if="accounts.data.type === 'client'" class="stats-account_name">
                  <h1>{{ a[accounts.data.property].username }}</h1>
                  <span>{{ a[accounts.data.property].firstName }} {{ a[accounts.data.property].lastName }}</span>
                </RouterLink>
                <RouterLink :to="`/producto/${a[accounts.data.property].id}`" v-else class="stats-account_name">
                  <h1>{{ a[accounts.data.property].name }}</h1>
                  <span>{{ a.userMerchant.name }}</span>
                </RouterLink>

                <div v-if="accounts.data.type" class="blue-button" :class="{active: a.isFollowed}">
                  <span @click="dialogFollow(a, accounts.data.title, a[accounts.data.property].id)">{{ a.isFollowed ? 'Dejar de seguir' : 'Seguir' }}</span>
                </div>
                <div v-else :class="{favotired: a.isFavorited}">
                  <v-icon @click="dialogFollow(a, accounts.data.title)">{{ a.isFavorited ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                </div>
              </div>
            </div>
            <div v-else class="empty-data">
              <img src="../../assets/shoesize-modal-blue.svg" alt="svg">
            </div>
          </div>

        </div>
      </v-dialog>
    </div>

    <div v-if="profile.id !== $U.currentUser?.id" class="profileindex-btns">
      <div @click="follow" class="blue-button" :class="{active: isFollowed}">{{ isFollowed ? 'Dejar de seguir' : 'Seguir' }}</div>
      <div @click="$C.share(`https://mall.tienda/profile/${profile?.username}`)" class="primary-button"><v-icon>mdi-share</v-icon></div>
      <!-- <div class="primary-button"><v-icon>mdi-chat-outline</v-icon></div> -->
    </div>
    <div v-else class="profileindex-btns">
      <RouterLink :to="'/profile/config/edit'" class="blue-button">Editar perfil</RouterLink>
      <div @click="$C.share(`https://mall.tienda/producto/${profile?.username}`)" class="primary-button"><v-icon>mdi-share</v-icon></div>
    </div>

  </div>
  <InfiniteComponent :products="favorites" :function="loadMore" :full="true" />
</template>