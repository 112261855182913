<template>
  <div class="we-are-view">
    <div class="main-alignment">
      <div class="view-alignment">
        <div class="picture-view">
          <img src="@/assets/logos/mall.svg" alt="logo" />
        </div>
        <div class="text-view">
          <div class="title">Quienes somos</div>
          <div class="text">
          </div>
        </div>
      </div>
      <div class="mobile-view-alignment">
        <div class="mobile-picture-view">
          <img src="../../assets/giant-mall.svg" alt="logo" />
        </div>
        <div class="mobile-text-view">
          <div class="mobile-text">
          </div>
        </div>
      </div>
      <div class="desktop-alignment">
        <div class="desktop-text-view">
          <div class="desktop-text">
          </div>
        </div>
        <div class="desktop-picture-view">
          <img src="../../assets/giant-mall.svg" alt="logo" />
        </div>
      </div>
      <div class="view-alignment">
        <div class="picture-view">
          <img src="../../assets/phone-hand.svg" alt="logo" />
        </div>
        <div class="text-view">
          <div class="text">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
