/* eslint-disable */
import { defineStore } from "pinia";
import { ref } from "vue";
import { usePopStore, usePurchaseStore } from "@/store/stores";
import { fetchWrapper } from "@/helpers/fetchWrapper";

export const useCartStore = defineStore(
  "carts",
  () => {
    const $P = usePopStore();
    const $C = usePurchaseStore();
    const carts = ref([]);
    const currentCart = ref({ merchants: {} });
    const cartModal = ref(false);

    const openCart = () => {
      cartModal.value = true;
    };

    const newCart = (userId) => {
      const newCart = {
        userClientId: userId,
        merchants: currentCart.value.merchants,
      };
      carts.value.push(newCart);
    };

    const addItem = (product, addProd) => {

      const merchant = currentCart.value.merchants[product.userMerchant.id]?.products;

      if(currentCart.value.merchants.hasOwnProperty(product.userMerchant.id)) {

        const result = merchant.find(p => p.variantId === addProd.variantId);

        if (result) {
          const index = merchant.findIndex((p) => p === result);
          add(product.userMerchant.id, index);
        } else {
          merchant.push(addProd);
          $P.popUpHandler("Se agrego el producto al carrito");
        }

      } else {
        currentCart.value.merchants[product.userMerchant.id] = {
          merchant: product.userMerchant,
          products: [],
          discount: [],
        };
        currentCart.value.merchants[product.userMerchant.id].products.push(addProd);
        $P.popUpHandler("Se agrego el producto al carrito");
      }
    };

    const subtract = (merchantId, index) => {
      const productInCart = currentCart.value.merchants[merchantId].products[index];
      if(productInCart.quantity > 1) {
        productInCart.quantity--;
        updatePromotion(productInCart, merchantId);
      }
    };

    const add = (merchantId, index) => {
      const productInCart = currentCart.value.merchants[merchantId].products[index];
      if(productInCart.quantity < productInCart.stock) {
        productInCart.quantity++;
        updatePromotion(productInCart, merchantId);
      }
    };

    const updatePromotion = (product, merchantId) => {
      if(product.promotion) {
        const first = Number(product.promotion.type.split("x")[0]);
        const second = Number(product.promotion.type.split("x")[1]);
        const promotions = currentCart.value.merchants[merchantId].discount;
        const isOnPromotion = promotions.find(p => p.articleVariantId === product.variantId);
        const totalDiscount = ((product.isOnPromotion ? product.promotionalPrice : product.price) * product.quantity) * (1 - (second / first));
        if(product.quantity % first === 0) {
          if(isOnPromotion) {
            const index = promotions.findIndex(p => p === isOnPromotion);
            promotions[index].totalDiscount = totalDiscount;
          } else {
            promotions.push({
              articleVariantId: product.variantId,
              totalDiscount: totalDiscount,
              promotionId: product.promotion.id
            });
          }
        } else {
          if(isOnPromotion) {
            const index = promotions.findIndex(p => p === isOnPromotion);
            promotions.splice(index, 1);
          }
        }
      };
    };

    const getOrderItemsDiscount = () => {
      const orderItemsDiscount = [];
      for (const merchant in currentCart.value.merchants) {
        currentCart.value.merchants[merchant].discount.map(p => {
          orderItemsDiscount.push(p);
        });
      };
      return orderItemsDiscount;
    };

    const deleteItem = (merchantId, id) => {
      const merchant = currentCart.value.merchants[merchantId];
      if(merchant.products.length === 1) {
        delete currentCart.value.merchants[merchantId];
      } else {
        const productToRemove = merchant.products.splice(id, 1);
        const indexDiscountToRemove = merchant.discount.findIndex(p => p.variantId === productToRemove[0].variantId);
        merchant.discount.splice(indexDiscountToRemove, 1);
      }
    };

    const deleteAll = () => {
      currentCart.value.merchants = {};
    };

    const totalPrice = () => {
      const totalPrice = [];
      for (const key in currentCart.value.merchants) {
        currentCart.value.merchants[key].products.map(p => {
          totalPrice.push((p.isOnPromotion ? p.promotionalPrice : p.price) * p.quantity);
        });
      }
      return totalPrice.reduce((a, b) => a + b, 0);
    };

    const totalDiscount = () => {
      const totalDiscount = [];
      for (const key in currentCart.value.merchants) {
        currentCart.value.merchants[key].discount.map(p => {
          totalDiscount.push(p.totalDiscount ? p.totalDiscount : 0);
        });
      }
      return totalDiscount.reduce((a, b) => a + b, 0);
    };

    const totalAmountToPay = () => {
      if($C.step === 4) {
        const totalAmount = [];
        for (const plan of $C.installmentsPlans) {
          totalAmount.push(plan.installment.totalAmount);
        }
        return totalAmount.reduce((a, b) => a + b, 0);
      }
    };

    const loginCart = (id) => {
      const userCart = carts.value.find((cart) => cart.userClientId === id);
      if (userCart) {
        console.log("carrito encontrado");
        currentCart.value = userCart;
      } else {
        console.log("carrito creado");
        newCart(id);
        const userCart = carts.value.find((cart) => cart.userClientId === id);
        currentCart.value = userCart;
      }
    };

    const logoutCart = () => {
      const index = carts.value.findIndex(cart => cart.userClientId === currentCart.value.userClientId);
      carts.value[index].merchants = currentCart.value.merchants;
      currentCart.value = { merchants: {} };
    };

    const checkStock = async (data) => {
      const ids = data.map(x => x.variantId);
      const res = await fetchWrapper.post("articles/variants/byIds", {ids})
      const check = data.map(x => {
        const find = res.data.articleVariants.find(y => y.id === x.variantId);
        return {
          merchantId: x.merchantId,
          name: x.name,
          image: x.image,
          attributes: x.attributes,
          quantity: x.quantity,
          price: x.price,
          promotionalPrice: x.promotionalPrice,
          isOnPromotion: x.isOnPromotion,
          realStock: find.stock,
          realPrice: find.price,
          realPromotionalPrice: find.promotionalPrice,
          realIsOnPromotion: find.isOnPromotion,
          continue: (x.quantity <= find.stock) && (find.isOnPromotion === x.isOnPromotion) && (find.isOnPromotion ? (find.promotionalPrice === x.promotionalPrice) : (find.price === x.price))
        };
      });
      console.log(check);
      if(check.find(x => x.continue === false)) {
        const outStock = check.filter(x => x.continue === false);
        return outStock;
      } else {
        return true;
      }
    };

    const deleteOutStock = (outStock) => {
      outStock.forEach(x => {
        const id = currentCart.value.merchants[x.merchantId].products.findIndex(y => y.name === x.name && y.attributes === x.attributes);
        deleteItem(x.merchantId, id);
      });
    };

    return {
      carts,
      currentCart,
      cartModal,
      openCart,
      newCart,
      addItem,
      subtract,
      add,
      getOrderItemsDiscount,
      deleteItem,
      deleteAll,
      totalPrice,
      totalDiscount,
      totalAmountToPay,
      loginCart,
      logoutCart,
      checkStock,
      deleteOutStock
    };
  },
  {
    persist: true,
  }
);
