<script setup>
/* eslint-disable */
//vue-router
import { RouterLink } from "vue-router";
//components
import CardComponent from './CardComponent.vue';
//store
import { useWindowStore } from '@/store/window';
//swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
const modules = [Pagination, Navigation];

const props = defineProps(["data", "title", "link", "type"]);
const $W = useWindowStore();

const arrayDivider = array => {
  return array.slice(0, 4);
};

const random = Math.random().toString().split(".")[1];
const prev = `.prev-${random}`
const next = `.next-${random}`
const prevClass = `prev-${random}`
const nextClass = `next-${random}`
const breakpoints = (type) => {
  if (type === "cardView") {
    return {
      '300': {
        slidesPerView: 2,
        slidesPerGroup: 2,
      }
    }
  } else {
    return {
      '768': {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      '1035': {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      '1260': {
        slidesPerView: 5,
        slidesPerGroup: 5,
      }
    }
  }
}

</script>

<template>
  <div v-if="data.length > 0" class="productCarrousel-container">
    <div class="text">
      <h2>{{ title }}</h2>
      <RouterLink v-if="link" :to="`/products/${title.toLowerCase()}`">{{ link }}</RouterLink>
    </div>
    <swiper
      :pagination="{
        dynamicBullets: false,
      }"
      :slidesPerView="4"
      :slidesPerGroup="4"
      :breakpoints= breakpoints(type)
      :navigation= "{
        prevEl: prev,
        nextEl: next,
      }"
      :modules="modules"
      class="carrousel"
    >
      <swiper-slide v-for="(product, index) in data" :key="index">
        <CardComponent :data="product" />
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev-unique" :class="prevClass"><v-icon>mdi-chevron-left</v-icon></div>
    <div class="swiper-button-next-unique" :class="nextClass"><v-icon>mdi-chevron-right</v-icon></div>
  </div>

  <div v-else class="productCarrousel-skeleton">
    <div class="top">
      <div class="title"></div>
      <v-icon>mdi-loading</v-icon>
    </div>
    <div v-if="type === 'cardView'" class="card-container">
      <div class="card"></div>
      <div class="card"></div>
    </div>
    <div v-else-if="$W.windowWidth > 768" class="card-container">
      <div class="card"></div>
      <div class="card"></div>
      <div class="card"></div>
      <div v-if="$W.windowWidth > 1035" class="card"></div>
      <div v-if="$W.windowWidth > 1260" class="card"></div>
    </div>
    <div v-else class="card-container">
      <div class="card"></div>
      <div class="card"></div>
      <div class="card"></div>
      <div class="card"></div>
    </div>
  </div>

</template>