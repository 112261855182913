<script setup>
//vue
import { ref, onMounted } from "vue";
//vue-router
import { useRoute, RouterLink } from "vue-router";
const route = useRoute();
//store
import { usePurchaseStore, useOcaStore } from "@/store/stores";
const $P = usePurchaseStore();
const $OCA = useOcaStore();
//helper
import { capitalize } from "@/helpers/capitalize";
import { fullTextDate } from "@/helpers/dateFormatter";

const order = ref();

onMounted(async() => {
  order.value = await $P.getOrder(route.params.orderId);
  console.log(order.value);
  $OCA.linkToOca = "";
  if(!order.value.pickUpStore) await $OCA.trackOrder(order.value.id);
});
</script>

<template>
  <div v-if="order" class="order-container">

    <h1>Estado de compra</h1>

    <div class="main">
      <div class="items-container">
        <div class="items">
          <RouterLink v-for="(item, index) in order?.orderItems" :key="index" :to="`/producto/${item.articleVariant.article.id}`" class="item">
            <img :src="(item.articleVariant.images ?? item.articleVariant.article.images)[0]">
            <div class="info">
              <h1>{{ item.articleVariant.article.name }}</h1>
              <p v-for="(value, index) in item.articleVariant.values" :key="index">{{ capitalize(item.articleVariant.article.attributes[index]) }}: {{ value.toUpperCase() }}</p>
            </div>
          </RouterLink>
        </div>

        <div class="order-details">
          <div class="title-detail">
            <h3>Detalle de la compra</h3>
            <p>{{ fullTextDate(order?.createdAt) }}</p>
          </div>
          <div class="orderItems-detail">
            <div v-for="(item, index) in order?.orderItems" class="detail" :key="index">
              <p>{{ capitalize(item.articleVariant.article.name) }} x{{ item.quantity }}</p>
              <span>${{ item.total }}</span>
            </div>
            <div v-if="!order?.pickUpStore" class="detail">
              <p>Envío</p>
              <span>${{ order?.orderDetails.shippingCost }}</span>
            </div>
          </div>
          <div v-if="order?.orderItemsDiscount.length" class="discount-detail">
            <p>Descuento</p>
            <div class="discounts">
              <span v-for="(discount, index) in order?.orderItemsDiscount" :key="index">-${{ discount.totalDiscount }}</span>
            </div>
          </div>
          <div class="detail total">
            <p>Total</p>
            <span v-if="order?.payment.installments">{{ order?.payment.installments }} cuotas de ${{ (order?.payment.installmentsTotalAmount / order?.payment.installments).toFixed() }}</span>
            <span v-else>${{ order?.payment.amountPaid.toFixed() }}</span>
          </div>
        </div>
      </div>
  
      <div class="order-signs">
        
        <template v-if="!order?.pickUpStore">
        <!-- ENTREGADO -->
        <div v-if="order?.status === 'RECEIVED'" class="order-status check">
          <v-icon>mdi-check</v-icon>
          <h2>Entregado</h2>
          <p>Llegó el 31 de octubre. Entregamos tu paquete a las 15:17 hs en {{ order?.userClientAddress?.mainStreet + " " + order?.userClientAddress?.number + ", " + order?.userClientAddress?.location + ", " + order?.userClientAddress?.state + "." }}</p>
          <RouterLink :to="`/merchant/${order?.userMerchantId}`" class="primary-button">Volver a comprar</RouterLink>
        </div>

        <!-- MERCHANT PREPARANDO EL ENVIO -->
        <div v-else-if="order?.status === 'MERCHANT-PREPARING-SHIPMENT'" class="order-status delivery">
          <div class="transitions-icons"> 
             <v-icon class="truck-icon">mdi-truck-delivery-outline</v-icon>
             <img src="@/assets/logos/logooca.png" class="delivery-logo">
          </div>
        
          <h2>La tienda esta preparando tu pedido</h2>

          <p>
            Hace click en el siguiente enlace para hacer el seguimiento de tu pedido:
            <a :href="$OCA.linkToOca?.data" target="_blank">Tu seguimiento </a>
          </p>         
          <RouterLink :to="`/merchant/${order?.userMerchantId}`" class="primary-button">Volver a comprar</RouterLink>
        </div>

        <!-- MERCHANT PREPARANDO LA ORDEN -->
        <div v-else-if="order?.status === 'MERCHANT-PREPARING-ORDER'" class="order-status delivery">
          <div class="transitions-icons"> 
             <v-icon class="truck-icon">mdi-truck-delivery-outline</v-icon>
             <img src="@/assets/logos/logooca.png" class="delivery-logo">
          </div>
        
          <h2>En camino</h2>

          <p>
            Hace click en el siguiente enlace para hacer el seguimiento de tu pedido:
            <a :href="$OCA.linkToOca?.data" target="_blank">Tu seguimiento </a>
          </p>         
          <RouterLink :to="`/merchant/${order?.userMerchantId}`" class="primary-button">Volver a comprar</RouterLink>
        </div>
      </template>

      <template v-else>
        <!-- MERCHANT PREPARANDO EL ENVIO -->
        <div v-if="order?.status === 'MERCHANT-PREPARING-ORDER'" class="order-status delivery">
          <v-icon class="truck-icon">mdi-store-outline</v-icon>
        
          <h2>La tienda esta preparando tu pedido</h2>

          <p>...</p>
          <RouterLink :to="`/merchant/${order?.userMerchantId}`" class="primary-button">Volver a comprar</RouterLink>
        </div>
      </template>

        <!-- CANCELADO -->
        <div v-if="order?.status === 'CANCELLED'" class="order-status cancel">
          <v-icon>mdi-cancel</v-icon>
          <h2>Cancelado</h2>
          <p>Se cancelo la compra el 31 de octubre. Se reembolso el dinero a la cuenta con la que se hizo el pago.</p>
          <RouterLink :to="`/merchant/${order?.userMerchantId}`" class="primary-button">Volver a comprar</RouterLink>
        </div>

        <div class="order-status help">
          <h2>Ayuda con la compra</h2>
          <RouterLink to="/profile/info/ratings/pending">Opinar sobre los productos</RouterLink>
          <RouterLink to="/contactus">Tengo un problema con mi compra</RouterLink>
        </div>

      </div>
    </div>
    
    
  </div>
</template>