<script setup>
//vue
import { ref, computed } from "vue";
//vue-router
import { RouterLink, useRouter } from "vue-router";
const router = useRouter();
//components
import RatingComponent from "@/components/shared/RatingComponent.vue";
//store
import { useRatingStore, useWindowStore } from "@/store/stores";
const $R = useRatingStore();
const $W = useWindowStore();
//helper
import { divideArray } from "@/helpers/arrayDivider";
import { fullTextDate } from "@/helpers/dateFormatter";
import { capitalize } from "@/helpers/capitalize";

const page = ref(1);
const ratings = computed(() => {
  if($R.ratings) {
    const data = $R.ratings?.pending;
    return divideArray(data, 4);
  } else {
    return [];
  }
});

</script>

<template>
  <div v-if="$R.ratings.pending.length === 0" class="rating-container__empty">
    <img src="../../../assets/svgs/search_one.svg" alt="svg">
    <span>Felicitaciones, ¡estás al día!</span>
    <p>Ya opinaste sobre todos tus productos comprados. Siguí colaborando con la comunidad opinando en tus compras realizadas.</p>
    <button @click="router.push('completed')" class="blue-button">Editar mis opiniones</button>
  </div>

  <div v-else class="completed-container">
    <div class="completed-length">
      <p>Gracias por contribuir con la comunidad</p>
      <span>{{ 1 + 4 * (page - 1) }} - {{ ratings[page - 1].length + 4 * (page - 1) }} de {{ $R.ratings.pending.length }} opiniones realizadas</span>
    </div>

    <div v-for="rating in ratings[page - 1]" :key="rating.id" class="completed">
      <div class="top">
        <div @click="router.push(`/merchant/${rating.userMerchant.id}`)" class="merchant">
          <div class="image">
            <img :src="rating.userMerchant.image">
          </div>
          <h1>{{ rating.userMerchant.name }}</h1>
        </div>
        <span>{{ fullTextDate(rating.createdAt) }}</span>
      </div>

      <div class="bottom">
        <RouterLink class="image" :to="`/producto/${rating.article.id}`"><img :src="rating.article.images[0]" :alt="rating.article.name"></RouterLink>

        <div class="title">
          <RouterLink :to="`/producto/${rating.article.id}`">{{ rating.article.name }}</RouterLink>
          <span v-for="(value, index) in rating.article.attributes" :key="index">{{ capitalize(value) }}: {{ rating.articleVariant.values[index] }}</span>
        </div>

        <div v-if="$W.windowWidth > 1100" class="rating-stars nohover">
          <v-icon v-for="star in [1, 2, 3, 4, 5]" :key="star">mdi-star-outline</v-icon>
        </div>

        <RatingComponent :data="rating" />
      </div>
    </div>

    <v-pagination
      v-model="page"
      :length="ratings.length"
      :total-visible="5"
    ></v-pagination>
  </div>
</template>