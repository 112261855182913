<template>
  <div class="view">
    <div class="back-close">
      <div class="back" @click="$Profile.currentView--">
        <v-icon icon="mdi-chevron-left" />
        <div class="back-text">Atras</div>
      </div>
      <DotsView />
    </div>
    <div class="shoesize-image">
      <img src="../../assets/shoesize-modal-blue.svg" alt="logo" />
    </div>

    <div class="shoesize-title">¿Cuál es tu número de calzado?</div>
    <div class="shoesize-text">
      Con esto te mostramos modelos aproximados a tu talle.
    </div>
    <div class="size-select">
      <v-select
        v-model="select"
        :items="items"
        item-title="sizeType"
        label="Tipo de calzado"
        variant="outlined"
      ></v-select>
    </div>
    <div class="grid-shoesize">
      <div class="shoesize-container" v-if="select === 'Argentino'">
        <div
          :class="[
            'shoesize-item',
            { selected: selectedShoesize === shoesize },
          ]"
          v-for="shoesize in argShoesizes"
          :key="shoesize"
          @click="
            updateShoesize(shoesize);
            updateSizeType();
          "
        >
          {{ shoesize }}
        </div>
      </div>
      <div class="shoesize-container" v-if="select === 'Americano'">
        <div
          :class="[
            'shoesize-item',
            { selected: selectedShoesize === shoesize },
          ]"
          v-for="shoesize in americanShoesizes"
          :key="shoesize"
          @click="
            updateShoesize(shoesize);
            updateSizeType();
          "
        >
          {{ shoesize }}
        </div>
      </div>
      <div class="shoesize-container" v-if="select === 'Brasilero'">
        <div
          :class="[
            'shoesize-item',
            { selected: selectedShoesize === shoesize },
          ]"
          v-for="shoesize in brShoesizes"
          :key="shoesize"
          @click="
            updateShoesize(shoesize);
            updateSizeType();
          "
        >
          {{ shoesize }}
        </div>
      </div>
    </div>
    <button class="modal-button" @click="lastModal">
      Finalizar
    </button>
    <div class="bottom-text" v-if="!$User.currentUser">
      <p>
        ¿Ya sos miembro?
        <strong
          ><RouterLink to="/login"
            ><a href="#">Iniciar Sesión</a></RouterLink
          ></strong
        >
      </p>
    </div>
  </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { ref, reactive } from "vue";
import { useProfileStore, useUserStore } from "@/store/stores";
import DotsView from "./DotsView.vue";

const selectedShoesize = ref(null);
const $User = useUserStore();

const americanShoesizes = [
  "4",
  "4.5",
  "5",
  "5.5",
  "6",
  "6.5",
  "7",
  "7.5",
  "8",
  "8.5",
  "9",
  "9.5",
  "10",
  "10.5",
  "11",
  "11.5",
  "12",
  "12.5",
  "13",
  "13.5",
  "14",
  "14.5",
  "15",
  "15.5",
  "16",
  "16.5",
  "17",
  "17.5",
];
const argShoesizes = [
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
];
const brShoesizes = [
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
];

const updateShoesize = (shoesize) => {
  selectedShoesize.value =
    selectedShoesize.value === shoesize ? null : shoesize;
  dataToSend.shoesize = selectedShoesize.value;
};

const select = ref("Argentino");
const items = ref([
  { sizeType: "Argentino" },
  { sizeType: "Americano" },
  { sizeType: "Brasilero" },
]);

const updateSizeType = () => {
  dataToSend.sizeType = select.value;
};

const $Profile = useProfileStore();
const currentProfile = $Profile.currentProfile;
const dataToSend = reactive({
  shoesize: "",
  sizeType: "",
});
const lastModal = () => {
  currentProfile.shoeSize = dataToSend.shoesize;
  currentProfile.sizeType = dataToSend.sizeType;
  $Profile.createNewProfile = false;
  $Profile.isOpenModal = false;
  updateShoesize();
  updateSizeType();
  $Profile.newProfile();
};
</script>
