<template>
  <div class="view">
    <div class="back-close">
      <div v-if="!$Profile.createNewProfile" class="back" @click="$Profile.currentView--">
        <v-icon icon="mdi-chevron-left" />
        <div class="back-text">Atras</div>
      </div>
      <DotsView />
    </div>
    <div class="wellcome-image">
      <img src="../../assets/img-alias.svg" alt="logo" />
    </div>
    <div class="wellcome-title">¡¿Como te llamamos?!</div>
    <div class="insert-alias">
      <div class="alias">
        <v-text-field
          v-model="dataToSend.alias"
          label="Escribe tu nombre de alias"
          variant="outlined"
        ></v-text-field>
        </div>
    </div>
    <div class="wellcome-text">
      Recuerda que no puedes tener dos nombres iguales
    </div>
    <button class="modal-button" @click="nextModal">
      Siguiente
    </button>
    <div class="bottom-text" v-if="!$User.currentUser">
      <p>
        ¿Ya sos miembro?
        <strong
          ><RouterLink to="/login"
            ><a href="#">Iniciar Sesión</a></RouterLink
          ></strong
        >
      </p>
    </div>
  </div>
</template>

<script setup>
import { useProfileStore, useUserStore } from "@/store/stores";
import DotsView from "./DotsView.vue";
import { RouterLink } from "vue-router";
import { reactive } from "vue";

const $Profile = useProfileStore();
const $User = useUserStore();
const dataToSend = reactive({
  alias: "",
});
const nextModal = () => {
  $Profile.isCreatingNewProfile = true;
  $Profile.currentProfile.alias = dataToSend.alias;
  $Profile.currentView++;
  console.log("alias en createalias.vue", $Profile.currentProfile.alias);
};
</script>
