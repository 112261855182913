<script setup>
/* eslint-disable */
//vue
import { ref, onMounted } from "vue";
//vue-router
import { useRouter } from "vue-router";
const router = useRouter();
//components
import PaymentCard from "@/components/shared/PaymentCard.vue";
//stores
import { usePurchaseStore, usePaymentStore, usePopStore } from "@/store/stores";
const $POP = usePopStore();
const $P = usePurchaseStore();
const $C = usePaymentStore();
//variables
const button = ref(true);
const paying = ref(false);
const error = ref(false);

onMounted(() => {
  if($P.cart.totalPrice === 0) {
    router.push("/");
    return;
  }
  if($P.step > 4) {
    $P.step = 5;
  } else {
    router.push(`/cart/purchase/confirm`);
  }
})

const payOrders = async () => {
  const data = $P.payOrderBodies[0].data;
  const validate = await $C.validateCard(data.paymentCardId, data.userClientId, $P.payment.securityCode.toString(), data.lastFourDigits);
  console.log(validate);
  if(!validate) {
    $P.payment.securityCode = "";
    $POP.popUpHandler("El código de seguridad es incorrecto", 0);
  } else {
    paying.value = true;
    for (const payOrderBody of $P.payOrderBodies) {
      if(!error.value) {
        const payOrder = {
          ...payOrderBody.data,
          securityCode: $P.payment.securityCode.toString()
        }
        console.log("payOrder", payOrder);
        const res = await $P.payOrder(payOrder);
        if(res) {
          payOrderBody.payingOff = true;
        } else {
          payOrderBody.payingOff = "error";
          error.value = true;
        }
      } else {
        payOrderBody.payingOff = "error";
      }
    }
    button.value = false;
    if(error.value) router.push("/purchase/payment-error");
    else router.push("/purchase/success");
  }
}
</script>

<template>
  <div class="payOrder-container">
    <PaymentCard :delete="false" :data="$P.payment.card" />

    <input type="number" placeholder="Código de seguridad" v-model="$P.payment.securityCode">

    <div class="payOrder">
      <div class="order" v-for="(order, index) in $P.payOrderBodies.filter(o => o.data !== false)" :key="index">
        <div class="merchant">
          <div class="image">
            <img :src="order.merchant.image">
          </div>
          <span>{{ order.merchant.name }}</span>
        </div>
        
        <div class="check">
          <img v-if="order.payingOff === false" :class="{paying: paying}" src="../../assets/logos/mall-small.svg">
          <v-icon v-else-if="order.payingOff === true">mdi-check</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </div>
      </div>
    </div>

    <div v-if="$P.payOrderBodies.filter(o => o.data === false).length > 0" class="payOrder">
      <p>Hubo un error al generar la orden de compra de las siguientes tiendas:</p>
      <strong v-for="(order, index) in $P.payOrderBodies.filter(o => o.data === false)" :key="index">
        {{ order.merchant.name }}
      </strong>
    </div>

    <button v-if="button" :disabled="$P.payment.securityCode === ''" class="blue-button" @click="payOrders">¡Pagar!</button>
  </div>
</template>