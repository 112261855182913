<script setup>
//vue
import { ref, computed } from "vue";
//vue-router
import { RouterLink, useRouter } from "vue-router";
const router = useRouter();
//components
import StarsComponent from "@/components/shared/StarsComponent.vue";
import RatingComponent from "@/components/shared/RatingComponent.vue";
//store
import { useRatingStore } from "@/store/rating";
const $R = useRatingStore();
//helper
import { date } from "@/helpers/dateFormatter";
import { divideArray } from "@/helpers/arrayDivider";
import { capitalize } from "@/helpers/capitalize";

const page = ref(1);
const ratings = computed(() => {
  if($R.ratings) {
    const data = $R.ratings.completed;
    return divideArray(data, 4);
  } else {
    return [];
  }
});

</script>

<template>
  <div v-if="$R.ratings.completed.length === 0" class="rating-container__empty">
    <img src="../../../assets/shoesize-modal-blue.svg" alt="svg">
    <span>No hiciste ninguna opinion</span>
    <p>Hace la compra de algun producto y opina del mismo para aportar tu granito de arena a la comunidad.</p>
  </div>

  <div v-else class="completed-container">
    <div class="completed-length">
      <p>Gracias por contribuir con la comunidad</p>
      <span>{{ 1 + 4 * (page - 1) }} - {{ ratings[page - 1].length + 4 * (page - 1) }} de {{ $R.ratings.completed.length }} opiniones realizadas</span>
    </div>

    <div v-for="rating in ratings[page - 1]" :key="rating.id" class="completed">
      <div class="top">
        <div @click="router.push(`/merchant/${rating.userMerchant.id}`)" class="merchant">
          <div class="image">
            <img :src="rating.userMerchant.image">
          </div>
          <h1>{{ rating.userMerchant.name }}</h1>
        </div>
        <span class="date">Realizada el {{ date(rating.ratedAt) }}</span>
      </div>

      <div class="bottom">
        <RouterLink class="image" :to="`/producto/${rating.article.id}`"><img :src="rating.article.images[0]" :alt="rating.article.name"></RouterLink>

        <div class="title">
          <RouterLink :to="`/producto/${rating.article.id}`">{{ rating.article.name }}</RouterLink>
          <span v-for="(value, index) in rating.article.attributes" :key="index">{{ capitalize(value) }}: {{ rating.articleVariant.values[index] }}</span>
        </div>

        <StarsComponent :rating="rating.rating" />

        <RatingComponent :data="rating" :type="'edit'" />
      </div>
    </div>

    <v-pagination
      v-model="page"
      :length="ratings.length"
      :total-visible="5"
    ></v-pagination>
  </div>
</template>