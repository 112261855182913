<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import require from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    getIMGPath(img) {
      return img ? require("@/assets/images/" + img) : "";
    }
  },
  props: {
    images: Array
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
};
</script>

<template>
  <swiper
  :slidesPerView="1"
  :spaceBetween="0"
  :loop="true"
  :pagination="{
    clickable: true,
  }"
  :grabCursor="true"
  :autoplay="{
    delay: 3000,
    disableOnInteraction: true,
  }"
  :modules="modules">
    <swiper-slide v-for="(image, index) in images" :key="index">
      <img :src="getIMGPath(image)" :alt="image">
    </swiper-slide>
  </swiper>
</template>