<script setup>
/* eslint-disable */
//vue
import { ref, computed, watch } from "vue";
//vue-router
import { RouterLink, useRouter, useRoute } from "vue-router";
//components
import CartComponent from "./CartComponent.vue";
import SearchComponent from "./SearchComponent.vue";
//stores
import { useUserStore } from "@/store/stores";

const $U = useUserStore();

const router = useRouter();
const route = useRoute();

const routeCheck = computed(() => route.fullPath.split("/")[1] === "cart");

const logged = computed(() => Boolean($U.currentUser));

const logout = () => {
  menuDialog.value = false;
  $U.logout();
};

//links
const menuDialog = ref(false);

const routeOn = computed(() => {
  return route.fullPath.split("/").pop().split("?")[0];
});
const itemsLog = ref([
  { text: "Mi perfil", icon: "mdi-account" },
  { text: "Opiniones", icon: "mdi-cloud-question-outline", link: "ratings/pending" },
  { text: "Guardados", icon: "mdi-bookmark-outline", link: "saved" },
  { text: "Favoritos", icon: "mdi-heart-box-outline", link: "favorites" },
]);

const itemsSign = ref([
  { text: "Iniciar sesión", icon: "mdi-login", link: "/login" },
  { text: "Creá tu cuenta", icon: "mdi-open-in-app", link: "/signin" },
]);

watch(route, () => {
  menuDialog.value = false;
});
</script>

<template>
  <div class="navbar-container">

    <div class="navbar-information">
      <div v-if="route.fullPath === '/'" @click="menuDialog = !menuDialog" class="navbar-menu">
        <v-icon v-if="menuDialog">mdi-close</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </div>
      <div v-else @click="router.back()" class="navbar-menu">
        <v-icon>mdi-arrow-left</v-icon>
      </div>

      <div class="navbar-logo" :class="{activeLogo: routeCheck}">
        <RouterLink to="/">
          <img src="../assets/logos/mall.svg" alt="Logo" />
        </RouterLink>
      </div>

      <div class="navbar-user">
        <SearchComponent />
        <CartComponent v-if="!routeCheck" />
      </div>
    </div>

    <div v-if="logged" class="user-menu" :class="{active: menuDialog}">
      <div class="user-menu__links">
        <RouterLink class="link" v-for="(item, index) in itemsLog" :key="index" :to="item.text === 'Mi perfil' ? `/profile/config` : `/profile/info/${item.link}`">
          <v-icon :class="{color: routeOn === item.link}">{{ item.icon }}</v-icon>
          <span>{{ item.text }}</span>
        </RouterLink>
        <div @click="logout" class="link">
          <v-icon>mdi-logout</v-icon>
          <span>Cerrar Sesión</span>
        </div>
      </div>
    </div>

    <div v-else class="user-menu" :class="{active: menuDialog}">
      <div class="user-menu__links">
        <RouterLink class="link" v-for="(item, index) in itemsSign" :key="index" :to="`${item.link}`">
          <v-icon :class="{color: routeOn === item.link}">{{ item.icon }}</v-icon>
          <span>{{ item.text }}</span>
        </RouterLink>
      </div>
    </div>

  </div>
</template>

<style scoped>
.color {
  color: #0057ff;
}
.activeLogo {
  margin-left: 0 !important;
}
</style>
