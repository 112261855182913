import { defineStore } from "pinia";
import { useUserStore } from "@/store/user";
import { usePopStore } from "@/store/popUp";

import { ref } from "vue";

export const useImageStore = defineStore("Image", () => {
  // Variables
  const dialogImage = ref(false);
  const imagePreview = ref();
  const fileTemp = ref();
  const $USER = useUserStore();
  const editClient = ref();
  const $POPUP = usePopStore();

  // Funciones
  const previewImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      dialogImage.value = true;
      const reader = new FileReader();
      reader.onload = () => {
        imagePreview.value = reader.result;
      };
      reader.readAsDataURL(file);
    }
    fileTemp.value = file;
  };
  //verificar funcionamiento
  const saveImage = async () => {
    const formData = new FormData();
    formData.append("image", fileTemp.value);
    try {
      const response = await uploadImage("client", formData);
      if (response && response.data) {
        editClient.value = response.data;
        dialogImage.value = false;
      } else {
        if (response === null) {
          $POPUP.popUpHandler(
            "Imagen muy grande, inténtelo con otra imagen.",
            0
          );
        }
      }
    } catch (error) {
      $POPUP.popUpHandler(
        "Ocurrió un error al guardar la imagen. Por favor, inténtalo de nuevo.",
        0
      );
    }
  };

  const uploadImage = async (category, formData) => {
    // eslint-disable-next-line
    const baseUrl = process.env.VUE_APP_API_URL;
    try {
      const response = await fetch(
        `${baseUrl}files/create/${category}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        if (response.status === 413) {
          $POPUP.popUpHandler(
            "La imagen es demasiado grande. Por favor, selecciona una imagen más pequeña.",
            0
          );
        } else {
          $POPUP.popUpHandler(
            "Ocurrió un error al subir la imagen. Por favor, inténtalo de nuevo.",
            0
          );
        }
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      const data = await response.json();
      $USER.updateInfo($USER.currentUser.id, "image", data.data);
      return data;
    } catch (error) {
      return null;
    }
  };

  return {
    uploadImage,
    previewImage,
    saveImage,
    fileTemp,
    imagePreview,
    dialogImage,
  };
});
