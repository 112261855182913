<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { useRouter } from "vue-router";
//components
import PaymentCard from "@/components/shared/PaymentCard.vue";
//stores
import { usePurchaseStore, usePaymentStore } from "@/store/stores";

const router = useRouter();
const $P = usePurchaseStore();
const $C = usePaymentStore();
const loaded = ref(false);

const payment = () => {
  router.push(`/cart/purchase/confirm`);
  $P.step = 4;
};

const addInstallment = (installment, userMerchantId) => {
  const merchantIndex = $P.installmentsPlans.findIndex(i => i.userMerchant.id === userMerchantId);
  $P.installmentsPlans[merchantIndex].installment = installment;
};

const allInstallmentChosen = computed(() => $P.installmentsPlans.every(i => i.installment !== null));

onMounted(async () => {
  if($P.cart.totalPrice === 0) {
    router.push("/");
    return;
  }
  if($P.step > 2) {
    $P.step = 3;
    await $C.getInstallment();
    loaded.value = true;
  } else {
    router.push(`/cart/purchase/payment-options`);
  }
});
</script>

<template>
  <div class="loading" v-if="!loaded">
    <img class="manijita" src="../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../assets/logos/bolsa.svg">
  </div>
  <div v-else class="purchase-shipping">
    <p class="purchase-title">¿En cuantas cuotas?</p>
    <div class="card-selected">
      <PaymentCard :delete="false" :data="$P.payment.card" />
    </div>
    <v-expansion-panels>
      <v-expansion-panel v-for="(plans, index) in $P.installmentsPlans" :key="index">
        <v-expansion-panel-title v-slot="{ expanded }">
          <v-row no-gutters class="d-flex flex-row justify-start align-center"> 
            <v-col class="d-flex flex-row justify-start align-center" cols="4">
              <div class="merchant">
                <div class="image">
                  <img :src="plans.userMerchant.image">
                </div>
                <span>{{ plans.userMerchant.name }}</span>
              </div>
            </v-col>
            <v-col
              class="text--secondary"
              cols="8"
            >
              <v-fade-transition leave-absolute>
                <span class="d-flex justify-center align-center text-center" v-if="expanded">Selecciona la cantidad de cuotas deseada</span>
                <v-row
                  v-else
                  style="width: 100%"
                  no-gutters
                >
                  <v-col class="d-flex justify-center align-center text-center" :class="{installmentMissing: !plans.installment}">
                    {{ plans.installment ? `${plans.installment.actualInstallments}x $${plans.installment.amountPerInstallment.toFixed()} con un total de $${plans.installment.totalAmount.toFixed()}` : 'Falta agregar cantidad de cuotas' }}
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="options-installment">
            <div v-for="(i, index) in plans.installmentsPlan" :key="index" class="options-installment__option" @click="addInstallment(i, plans.userMerchant.id)">
              <div class="radio" :class="{active: plans.installment === i}">
                <v-icon>mdi-check</v-icon>
              </div>
              <div class="installment-content">
                <div>
                  <p class="installment">{{ i.actualInstallments }}x</p>
                  <p class="price">${{ i.amountPerInstallment.toFixed() }}</p>
                </div>
                <div v-if="i.installments !== 1">
                  <p v-if="i.coefficient > 1" class="interest">Con {{ ((i.coefficient - 1) * 100).toFixed() }}% de interes</p>
                  <p v-else class="interest">Sin interes</p>
                  <p class="totalPrice">${{ i.totalAmount.toFixed() }}</p>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="buttons">
      <button @click="router.back()" class="blue-button">Atras</button>
      <button :disabled="!allInstallmentChosen" @click="payment" class="blue-button">Continuar</button>
    </div>
    
  </div>
</template>

<style scoped>
.v-selection-control {
  display: contents;
}
.blue-button {
  align-self: flex-end;
}
.installmentMissing {
  color: rgb(197, 19, 19);
}
</style>