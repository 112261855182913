export const sortProducts = (data) => {
  const groups = data.reduce((acc, article) => {
    if (!acc[article.userMerchantId]) {
      acc[article.userMerchantId] = [];
    }
    acc[article.userMerchantId].push(article);
    return acc;
  }, {});
  const stores = Object.keys(groups);
  let result = [];
  let i = 0;
  while (result.length < data.length) {
    stores.forEach(userMerchantId => {
      if (groups[userMerchantId][i]) {
        result.push(groups[userMerchantId][i]);
      }
    });
    i++;
  }
  return result;
};