const month = {
  "01": "enero",
  "02": "febrero",
  "03": "marzo",
  "04": "abril",
  "05": "mayo",
  "06": "junio",
  "07": "julio",
  "08": "agosto",
  "09": "septiembre",
  "10": "octubre",
  "11": "noviembre",
  "12": "diciembre"
};

export const fullDate = (data) => {
  const split = data.split("T");
  const hourSplit = split[1].split(":");
  const dateSplit = split[0].split("-");
  const full = {
    hour: `Hora de compra: ${hourSplit[0] - 3}:${hourSplit[1]}hs`,
    date: `Fecha de compra: ${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
  };
  return full;
};

export const date = (data) => {
  const split = data.split("T");
  const dateSplit = split[0].split("-");
  return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
};

export const textDate = (data) => {
  const date = data.split("T")[0];
  const splitDate = date.split("-");
  return `${splitDate[2]} de ${month[splitDate[1]]}`;
};

export const textDateFuture = (data) => {
  const date = data.split("T")[0];
  const splitDate = date.split("-");
  return `${Number(splitDate[2]) + 2} de ${month[splitDate[1]]}`;
};

export const fullTextDate = (data) => {
  if(data) {
    const date = data.split("T")[0];
    const splitDate = date.split("-");
    return `${splitDate[2]} de ${month[splitDate[1]]} de ${splitDate[0]}`;
  }
};