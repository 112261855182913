<template>
  <div class="change-conditions">
    <div class="change-background">
      <div class="change">
        <div class="change-title">
          Cómo devuelvo un producto
        </div>
        <div class="change-subtitle">
          1. Pedí la devolución del producto
        </div>
        <div class="change-text">
          Para devolver un producto, <b>buscá la opción “Devolver”</b> que
          aparece en el menú de la compra que ya no querés y seguí los pasos.
          Según la información que tengamos sobre la devolución, te vamos a
          pedir que hables con el vendedor para la misma. También vas a tener
          que hablar con el vendedor si al comprar acordaste la entrega con él.
          Podrás hacerlo a través de la mensajería o iniciando un reclamo desde
          la compra, si no se pueden poner de acuerdo. Tené en cuenta que en
          esos casos te vamos a ayudar a que envíes de vuelta tu compra.
        </div>
        <div class="change-subtitle">
          2. Prepará el paquete para enviarlo
        </div>
        <div class="change-subtitle">
          Antes de preparar el paquete, revisá que el producto esté en las
          mismas condiciones en las que lo recibiste, sin usar y con sus
          accesorios, manuales y etiquetas.
        </div>
        <div class="change-text">
          - Guardá el producto en su envoltorio o caja original.
        </div>
        <div class="change-text">
          - Embalalo y cerralo con una cinta adhesiva resistente; si es frágil,
          protegelo para que no se rompa. Podés usar el embalaje en el que llegó
          o uno nuevo, lo importante es que se ajuste al tamaño del producto.
        </div>
        <div class="change-text">
          - Etiqueta: imprimila y pegala en el paquete.
        </div>
        <div class="change-text">
          - Código QR: mostralo al entregar el paquete.
        </div>
        <div class="change-text">
          - Llevá el paquete a un correo o punto de despacho según lo que te
          asignamos.
        </div>
        <div class="change-text">
          <b
            >Si compraste más de una unidad del mismo producto, debés embalar
            todas las unidades que querés devolver en un solo paquete y usar la
            etiqueta.</b
          >
        </div>
        <div class="change-subtitle">
          3. Recibí el reembolso del dinero
        </div>
        <div class="change-text">
          Haremos el reembolso 3 días hábiles después de que llegue el producto,
          una vez que revisemos si cumple con las políticas de devolución. Sin
          embargo, algunas veces podemos hacer el reembolso ni bien entregás el
          producto, para que tengas el dinero más rápido. Una vez que hagamos el
          reembolso, vas a ver todos los detalles sobre el monto, lugar y día de
          acreditación del dinero desde el estado de tu compra. Para más
          información, podés consultar los tiempos de acreditación de cada medio
          de pago.
        </div>
        <div class="change-subtitle">
          ¿Cuánto tiempo tengo para enviar el producto?
        </div>
        <div class="change-text">
          <b>Tenes una validez por 5 días habiles.</b> Si por algún motivo no
          podés enviar el paquete durante ese período, vas a poder reenviarlo
          siempre que no hayan pasado más de 30 días desde que recibiste tu
          compra.
        </div>
        <div class="change-subtitle">
          ¿Qué pasa si me arrepiento de devolver?
        </div>
        <div class="change-text">
          Si querés, podés cancelar la devolución. Y si más adelante querés
          hacer la devolución, podés volver a comenzar, siempre que no hayan
          pasado los 30 días que tenés desde que recibís el producto.
        </div>
        <div class="change-title">
          Cuándo y dónde recibo el dinero de mi devolución
        </div>
        <div class="change-subtitle">
          ¿Cuándo me reembolsan el dinero?
        </div>
        <div class="change-text">
          El reembolso esta a cargo del vendedor el cual dispondrá de 10 dias
          habiles después de que llegue el producto, una vez que revisemos si
          cumple con las políticas de devolución. Podrás seguir todos los
          detalles sobre el monto, lugar y día de acreditación del dinero desde
          el estado de tu compra.
        </div>
        <div class="change-subtitle">
          ¿Dónde me reembolsan el dinero?
        </div>
        <div class="change-text">
          Reembolsamos el dinero en el mismo medio de pago que usaste para
          comprar.
        </div>
        <div class="change-subtitle">
          ¿Cuándo recibo el reembolso?
        </div>
        <div class="change-text">
          El tiempo que tarda en acreditarse el reembolso depende del medio en
          el que recibirás el dinero. Los plazos suelen ser los siguientes:
        </div>
        <div class="change-text">
          Tarjeta de crédito : <br />Entre 2 y 35 días (el plazo depende del
          banco)
        </div>
        <div class="change-text">
          Tarjeta de débito : <br />
          Entre 2 y 35 días (el plazo depende del banco)
        </div>
        <div class="change-text">*Si está disponible para tu compra</div>
      </div>
      <!-- falta agregar "te fue util esta informacion?" -->
    </div>
  </div>
</template>
<script></script>
