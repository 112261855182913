/* eslint-disable */
import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore } from "@/store/stores";

export const useSocialStore = defineStore("social", () => {
  const $P = usePopStore();
  //USER CLIENT
  const getUserData = async (username) => {
    try {
      const res = await fetchWrapper.get(`social/clientData/byUsername/${username}`);
      return res.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const getUserFollowers = async (userClientId) => {
    const result = {
      social: [],
      count: null,
      type: "client",
      title: "Seguidores",
      property: "follower"
    }
    try {
      const res = await fetchWrapper.get(`social/followersByClient/${userClientId}`);
      result.social.push(...res.data.follows);
      result.count = res.data.count;
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserFollowed = async (userClientId) => {
    const result = {
      social: [],
      count: null,
      type: "client",
      title: "Seguidos",
      property: "followed"
    }
    try {
      const res = await fetchWrapper.get(`social/clientsFollowedByClient/${userClientId}`);
      result.social.push(...res.data.follows);
      result.count = res.data.count;
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserMerchantsFollowed = async (userClientId) => {
    const result = {
      social: [],
      count: null,
      type: "merchant",
      title: "Tiendas seguidas",
      property: "followed"
    }
    try {
      const res = await fetchWrapper.get(`social/merchantsFollowedByClient/${userClientId}`);
      result.social.push(...res.data.follows);
      result.count = res.data.count;
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserFavorite = async (userClientId) => {
    const result = {
      social: [],
      count: null,
      title: "Favoritos",
      property: "article"
    }
    try {
      const res = await fetchWrapper.get(`social/favoritesByClient/${userClientId}?count=0&limit=100`);
      result.social.push(...res.data.favorites);
      result.count = res.data.count;
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserFavoriteProfile = async (userClientId, count) => {
    const result = {
      social: null,
      count: null,
    }
    try {
      const res = await fetchWrapper.get(`social/favoritesByClient/${userClientId}?count=${count}&limit=20`);
      result.social = res.data.favorites.map(x => ({
        ...x.article,
        isFavorited: x.isFavorited,
        userMerchant: x.userMerchant
      }));
      result.count = res.data.count;
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const followUser = async (followedId) => {
    try {
      const res = await fetchWrapper.post("social/followClient", { followedId });
      return true;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error intente más tarde", 0);
      return false;
    }
  };

  const unfollowUser = async (followedId) => {
    try {
      const res = await fetchWrapper.post("social/unfollowClient", { followedId });
      return true;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error intente más tarde", 0);
      return false;
    }
  };

  const followMerchant = async (followedId) => {
    try {
      const res = await fetchWrapper.post("social/followMerchant", { followedId });
      return true;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error intente más tarde", 0);
      return false;
    }
  };

  const unfollowMerchant = async (followedId) => {
    try {
      const res = await fetchWrapper.post("social/unfollowMerchant", { followedId });
      return true;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error intente más tarde", 0);
      return false;
    }
  };

  //USER MERCHANT
  const getMerchantData = async (userMerchantId) => {
    try {
      const res = await fetchWrapper.get(`social/merchantData/${userMerchantId}`);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getMerchantFollowers = async (userMerchantId) => {
    try {
      const res = await fetchWrapper.get(`social/followersByMerchant/${userMerchantId}`);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  return {getUserData, getUserFollowers, getUserFollowed, getUserFavorite, getUserFavoriteProfile, getUserMerchantsFollowed, followUser, unfollowUser, followMerchant, unfollowMerchant, getMerchantData, getMerchantFollowers };
}, {
  persist: true
}
);