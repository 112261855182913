import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore, useUserStore } from "@/store/stores";

export const useCommentArticles = defineStore("commentArticles", () => {
  const $P = usePopStore();
  const $U = useUserStore();

  const getCommentsByArticle = async (articleId) => {
    try {
      const res = await fetchWrapper.get(`messages/byArticle/${articleId}`);
      const filter = res.data.messages.filter(c => c.response !== null || c.userClientId === $U.currentUser?.id);
      return filter;
    } catch (e) {
      console.log(e);
    }
  };
  const newComment = async (articleId, message) => {
    try {
      const data = {
        articleId: articleId,
        message: message,
      };
      await fetchWrapper.post("messages", data);
      $P.popUpHandler("Su comentario fue enviado correctamente");
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al enviar el comentario", 0);
    }
  };
  return {
    getCommentsByArticle,
    newComment
  };
});
