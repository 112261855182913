<script setup>
//vue-router
//vue
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
//stores
import { usePaymentStore, useUserStore } from "@/store/stores";

const router = useRouter();
const $U = useUserStore();
const $C = usePaymentStore();
const loading = ref(true);

const allCards = computed(() => $C.paymentCards);
onMounted(async () => {
  await $C.getAllCards();
  loading.value = false;
});

const radios = ref($U.currentUser.mainPaymentCardId);

const chooseCard = async () => {
  $U.currentUser.mainPaymentCardId = radios.value;
  await $C.updateMainPaymentCardId(radios.value);
  router.go(-1);
};
</script>

<template>
  <div class="loading" v-if="loading">
    <img class="manijita" src="../../assets/logos/manijita.svg">
    <img class="bolsa" src="../../assets/logos/bolsa.svg">
  </div>
  <div v-else class="select-address">
    <v-radio-group v-model="radios">
      <div v-for="(card, index) in allCards" :key="index" class="select-address__option">
        <v-radio :value="card.id" />
        <div>
          <h3>{{ card.holderName + " - DNI: " + card.holderIdentification }}</h3>
          <p>Número de tarjeta: {{ card.number }}</p>
          <p>Expiracion de la tarjeta: {{ card?.expirationMonth }}/{{ card?.expirationYear }}</p>
        </div>
      </div>
    </v-radio-group>
    <button @click="chooseCard" class="form-button">Continuar</button>
  </div>
</template>