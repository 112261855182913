<script setup>
//vue
import { ref } from "vue";
//vue-router
import { useRouter } from "vue-router";
const router = useRouter();
//components
import UploadImages from "@/components/UploadImages.vue";
//stores
import { useUserStore, useImageStore } from "@/store/stores";
const $U = useUserStore();
const $I = useImageStore();
const imagePrev = () => {
  $I.imagePreview = "";
  $I.dialogImage = true;
};
const bg = ref(JSON.parse(JSON.stringify($U.currentBg)));
const loading = ref(false);
const validUsername = ref(null);
const form = ref({
  firstName: JSON.parse(JSON.stringify($U.currentUser.firstName)),
  lastName: JSON.parse(JSON.stringify($U.currentUser.lastName)),
  username: JSON.parse(JSON.stringify($U.currentUser.username)),
  description: JSON.parse(JSON.stringify($U.currentUser.description))
});

const saveChanges = async () => {
  const isValid = await validateUsername();
  if(isValid) {
    const dataToUpdate = {
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      description: form.value.description
    };
    if(form.value.username !== $U.currentUser.username) dataToUpdate.username = form.value.username;
    const res = await $U.updateUser(dataToUpdate);
    if(res) {
      $U.changeBg(bg.value);
      router.push(`/profile/${$U.currentUser.username}`);
    }
  }
};

const validateUsername = async () => {
  if(form.value.username !== $U.currentUser.username) {
    loading.value = true;
    validUsername.value = null;
    const res = await $U.validateUsername(form.value.username);
    if(!res) {
      loading.value = false;
      validUsername.value = true;
      return true;
    } else {
      loading.value = false;
      validUsername.value = false;
      return false;
    }
  } else {
    return true;
  }
};

</script>

<template>
  <div class="edit-container">
    <h2>Editar perfil</h2>
    <div class="edit-form">
      <div class="input">
        <label for="nombre">Nombre</label>
        <input id="nombre" type="text" placeholder="Nombre" v-model="form.firstName">
      </div>
      <div class="input">
        <label for="apellido">Apellido</label>
        <input id="apellido" type="text" placeholder="Apellido" v-model="form.lastName">
      </div>
      <div @focusin="validUsername = null" class="input" :class="{loading: loading, isValid: validUsername === true, isntValid: validUsername === false}">
        <div>
          <label for="username">Nombre de usuario</label>
          <v-icon v-if="loading">mdi-loading</v-icon>
          <v-icon v-if="validUsername === true">mdi-check</v-icon>
          <v-icon v-if="validUsername === false">mdi-close</v-icon>
        </div>
        <input id="username" type="text" placeholder="Nombre de usuario" v-model="form.username" @blur="validateUsername">
      </div>
      <div class="input">
        <label for="descripcion">Descripción</label>
        <textarea id="descripcion" cols="30" rows="2" v-model="form.description"></textarea>
      </div>
      <div @click="imagePrev" class="user-image">
        <div class="image">
          <img :src="$U.currentUser.image">
        </div>
        <v-icon>mdi-pencil</v-icon>
      </div>
      <div class="user-bg">
        <div @click="bg = 1" class="bg" :class="{active: bg === 1}">
          <img src="../../assets/images/profilebg1.webp">
        </div>
        <div @click="bg = 2" class="bg" :class="{active: bg === 2}">
          <img src="../../assets/images/profilebg2.webp">
        </div>
        <div @click="bg = 3" class="bg" :class="{active: bg === 3}">
          <img src="../../assets/images/profilebg3.webp">
        </div>
      </div>
      <button :disabled="validUsername === false" @click="saveChanges" class="blue-button">Guardar</button>
    </div>
    <v-dialog v-model="$I.dialogImage">
      <UploadImages />
    </v-dialog>
  </div>
</template>