import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore } from "@/store/stores";

export const useBannerStore = defineStore(
  "banners",
  () => {
    const $P = usePopStore();

    const getBanners = async () => {
      try {
        const res = await fetchWrapper.get("banners");
        return res.data;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al cargar los banners", 0);
      }
    };

    const getBannersByMerchant = async (merchantId) => {
      try {
        const res = await fetchWrapper.get(`bannersByMerchant/userMerchant/${merchantId}`);
        return res.data;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al cargar los banners del merchant", 0);
      }
    };

    return {
      getBanners,
      getBannersByMerchant
    };
  },
);
