<script setup>
/* eslint-disable */
//vue
import { reactive, computed } from "vue";
//bue-router
import { RouterLink } from "vue-router";
//stores
import { useUserStore, useInputStore, usePopStore, useWindowStore } from "@/store/stores";

const { newUser } = useUserStore();
const { required, validateEmail, validatePassword } = useInputStore();
const $PopUp = usePopStore();
const $W = useWindowStore();

const formData = reactive({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: ""
});

const createUser = async () => {
  if(formData.password !== formData.confirmPassword) {
    $PopUp.popUpHandler("Las contraseñas no coinciden", 0);
  } else if(validateEmail(formData.email) !== true){
    $PopUp.popUpHandler("El mail es invalido", 0);
  } else {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email.toLowerCase(),
      password: formData.password,
      phone: "vacio"
    }
    await newUser(data);
  }
};

const disabled = computed(() => {
  return Object.values(formData).some(x => x === "")
})
</script>

<template>
  <div class="login-container">
    <div class="form-user signin">
      <img src="../assets/logos/mall.svg" alt="logo">
      <p class="title">Creá tu cuenta</p>
      <div class="form">
        <v-text-field :rules="[required]" v-model="formData.firstName" label="Nombre" variant="outlined"></v-text-field>
        <v-text-field :rules="[required]" v-model="formData.lastName" label="Apellido" variant="outlined"></v-text-field>
        <v-text-field :rules="[required, validateEmail]" v-model="formData.email" label="Correo electrónico" variant="outlined"></v-text-field>
        <v-text-field :rules="[required, validatePassword]" v-model="formData.password" :type="'password'" label="Contraseña" variant="outlined"></v-text-field>
        <v-text-field :rules="[required]" v-model="formData.confirmPassword" :type="'password'" label="Confirmar contraseña" variant="outlined"></v-text-field>
        <button class="form-button" :disabled="disabled" @click.prevent="createUser">Crear</button>
      </div>
      <div class="login-text">
        <p>¿Ya tenes una cuenta? <strong><RouterLink to="/login">Entra aca</RouterLink></strong></p>
      </div>
    </div>
    <div v-if="$W.windowWidth > 768" class="login-img">
      <img src="../assets/images/registro.webp">
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.error-message
  font-weight: 600
  color: red
</style>