<script setup>
//vue
import { ref } from "vue";
//components
import CategoriesComponent from "@/components/shared/CategoriesComponent.vue";
//stores
import { useWindowStore } from "@/store/stores";
const $W = useWindowStore();
//swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper/modules";
const modules = [FreeMode, Navigation];
//props
defineProps(["data"]);

const length = ref($W.windowWidth > 768 ? 6 : 3);
const random = Math.random().toString().split(".")[1];
const prev = `.prev-${random}`;
const next = `.next-${random}`;
const prevClass = `prev-${random}`;
const nextClass = `next-${random}`;
</script>

<template>
  <div v-if="data && data?.length > length" class="categorie-container">
    <swiper
    :slidesPerView="$W.windowWidth > 768 ? length : length + 0.3"
    :slidesPerGroup="$W.windowWidth > 768 ? length : length + 0.3"
    :freeMode="$W.windowWidth > 768 ? false : true"
    :navigation= "{
      prevEl: prev,
      nextEl: next,
    }"
    :modules="modules">

      <swiper-slide v-for="(x, index) in data" :key="index">
        <CategoriesComponent :data="x" />
      </swiper-slide>

    </swiper>
    <div class="swiper-button-prev-unique" :class="prevClass"><v-icon>mdi-chevron-left</v-icon></div>
    <div class="swiper-button-next-unique" :class="nextClass"><v-icon>mdi-chevron-right</v-icon></div>
  </div>
  <div v-else class="categorie-container">
    <CategoriesComponent v-for="(x, index) in data" :key="index" :data="x" />
  </div>
</template>