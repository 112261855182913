import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { useCartStore, useProfileStore, usePopStore, useSaveStore } from "./stores";
import mixpanel from "mixpanel-browser";

export const useUserStore = defineStore(
  "users",
  () => {
    const router = useRouter();
    const $P = usePopStore();
    const $CART = useCartStore();
    const $PROFILE = useProfileStore();
    const $SAVE = useSaveStore();
    const currentUser = ref(null);
    const token = ref(null);
    const currentBg = ref(1);
   
    const newUser = async (newUser) => {
      try {
        const res = await fetchWrapper.post("auth/signin/client", newUser);
        $CART.newCart(res.data.userClient.id);
        $PROFILE.newProfile();
        await login(newUser.email, newUser.password);
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Ya existe un usuario con ese mail", 0);
      }
    };

    const confirmUser = async (email) => {
      const res = await fetchWrapper.get(`users/emailExists/${email}`);
      return res.data.emailExists;
    };

    const googleLogin = async (data) => {
      const res = await fetchWrapper.post("auth/google/register/client", data);
      $PROFILE.isOpenModal = false;
      if (!res?.data?.userClient) return false;
      currentUser.value = res.data.userClient;
      token.value = res.data.authToken;
      localStorage.setItem("users", res.data);
      $CART.loginCart(currentUser.value.id);
      $P.popUpHandler("Bienvenido");
      mixpanel.identify(currentUser.value.id, res.data.userClient.email);
      mixpanel.people.set(currentUser.value);
      mixpanel.people.set("Email", res.data.userClient.email);
      mixpanel.track("Sign Up", {
        "Signup Type": "Referral"
      });
      await $PROFILE.getProfiles();
      router.push("/");
      return true;
    };

    const getUsers = async () => {
      try {
        const res = await fetchWrapper.get("clients/users");
        return res.data;
      } catch (e) {
        console.log(e);
      }
    };

    const updateInfo = async (id, prop, data) => {
      try {
        const newInfo = {};
        newInfo[prop] = data;
        currentUser.value[prop] = data;
        await fetchWrapper.put(`clients/users/${id}`, newInfo);
      } catch (e) {
        console.log(e);
      }
    };

    const changeBg = (bg) => {
      currentBg.value = bg;
    };

    const updateUser = async (data) => {
      try {
        const res = await fetchWrapper.put(`clients/users/${currentUser.value.id}`, data);
        console.log(res);
        currentUser.value = res.data.updatedUserClient;
        return true;
      } catch(e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al editar el perfil", 0);
        return false;
      }
    };

    const validateUsername = async (username) => {
      try {
        const res = await fetchWrapper.get(`clients/users/existsUsername/${username}`);
        return res.data.existsUsername;
      } catch(e) {
        console.log(e);
        return false;
      }
    };

    const deleteUser = async (id) => {
      try {
        logout();
        await fetchWrapper.delete(`clients/users/${id}`);
      } catch (e) {
        console.log(e);
      }
    };

    const checkUserInfo = async () => {
      try {
        const res = await fetchWrapper.get("/auth/userData");
        currentUser.value = res.data.userClient;
      } catch(e) {
        console.log(e);
      }
    };

    const login = async (email, password) => {
      try {
        const res = await fetchWrapper.post("auth/login", { email, password });
        if (res?.data.type === "merchant") {
          $P.popUpHandler("Esta cuenta es de tienda. Para entrar en la app tiene que loguearse con una cuenta cliente.", 2);
          return false;
        }
        if (!res?.data?.userClient || res?.data?.userClient.isDeleted) {
          $P.popUpHandler("Usuario incorrecto", 0);
          return false;
        }
        currentUser.value = res.data.userClient;
        token.value = res.data.authToken;
        localStorage.setItem("users", res.data);
        $CART.loginCart(currentUser.value.id);
        $P.popUpHandler("Bienvenido");
        mixpanel.identify(currentUser.value.id, email);
        mixpanel.people.set(currentUser.value);
        mixpanel.people.set("Email", email);
        mixpanel.track("Sign Up", {
          "Signup Type": "Referral"
        });
        await $PROFILE.getProfiles();
        $PROFILE.isOpenModal = false;
        router.go();
        return true;
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Usuario incorrecto", 0);
      }
    };
    const logout = () => {
      $PROFILE.isOpenModal = true;
      $CART.logoutCart();
      $SAVE.cleanSave();
      currentUser.value = null;
      token.value = null;
      $PROFILE.cleanProfile();
      router.go();
      router.push("/");
    };

    return {
      currentUser,
      currentBg,
      token,
      getUsers,
      newUser,
      confirmUser,
      googleLogin,
      updateInfo,
      changeBg,
      updateUser,
      validateUsername,
      checkUserInfo,
      deleteUser,
      login,
      logout,
    };
  },
  {
    persist: true,
  }
);