import { defineStore } from "pinia";
import { ref } from "vue";
import { usePopStore } from "@/store/stores";

export const useSaveStore = defineStore(
  "save",
  () => {
    const $P = usePopStore();
    const save = ref([]);

    const addSave = async (data) => {
      try {
        save.value.push(data);
        $P.popUpHandler("Se guardo el producto");
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al guardar el producto", 0);
      }
    };

    const deleteSave = async (data) => {
      try {
        const deleteId = save.value.findIndex(s => s === data);
        save.value.splice(deleteId, 1);
        $P.popUpHandler("Eliminado de tu lista", 2);
      } catch (e) {
        console.log(e);
        $P.popUpHandler("Hubo un error al eliminar de tu lista", 0);
      }
    };

    const cleanSave = () => {
      save.value = [];
    };

    return {
      save,
      addSave,
      deleteSave,
      cleanSave
    };
  },
  {
    persist: true,
  }
);