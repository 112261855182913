<template>
  <div class="contact-us">
    <div class="contact-information">
      <div class="contact-title">¿En qué podemos ayudarte?</div>
      <div class="contact-info">
        Te podés comunicar con nosotros por <a href="https://wa.me/message/KMN7SHK3B2K7O1" target="_blank">Whatsapp</a>.
      </div>
      <div class="contact-info">
        Si querés podés mandar un mensaje en el siguiente formulario.
      </div>
      <div class="contact-input">
        <v-text-field
          v-model="dataToSend.name"
          label="Nombre"
          placeholder="Ingrese su Nombre"
        ></v-text-field>
        <v-text-field
          v-model="dataToSend.email"
          label="E-Mail"
          placeholder="Ingrese su Correo"
        ></v-text-field>
        <v-textarea
          v-model="dataToSend.message"
          label="Contactenos"
          placeholder="Dejenos su comentario"
        ></v-textarea>
        <v-btn class="input-btn" @click="sendMessage">Enviar Mensaje</v-btn>
      </div>
      <div class="contact-wpp">
        <a href="https://wa.me/message/KMN7SHK3B2K7O1" target="_blank">
          <img src="@/assets/logos/whatsapp.png">
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import { useUserStore, useContactusStore } from "@/store/stores";

const dataToSend = reactive({
  name: "",
  email: "",
  message: "",
});

const contactusStore = useContactusStore();
const $U = useUserStore();

const sendMessage = () => {
  const messageData = {
    email: dataToSend.email,
    message: dataToSend.name + " " + dataToSend.message,
    userClientId: $U.currentUser?.id || null,
  };
  contactusStore.newMessage(messageData);
  dataToSend.name = "";
  dataToSend.email = "";
  dataToSend.message = "";
};
</script>
