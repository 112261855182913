const alphabetArray = {
  "violet-class" : ["a", "b", "c", "d", "e", "f", "g", "h"],
  "red-class": ["i", "j", "k", "l", "m", "n", "o", "p", "q", "r"],
  "cyan-class": ["s", "t", "u", "v", "w", "x", "y", "z"]
};

export const generateInitials = (inputString) => {
  try {
    const words = inputString.trim().split(/\s+/);
    const initialsValue = words[0][0] + (words[1] ? words[1][0] : "");
    return initialsValue.toUpperCase();
  } catch (e) {
    return "-";
  }
};

export const getFirstInitial = (inputString) => {
  const initials =  generateInitials(inputString);
  return initials ? initials[0] : "-";
};

export const getInitialsClass = (inputString) => {
  const letter = getFirstInitial(inputString).toLowerCase();
  
  const className = Object.entries(alphabetArray).find(([key, value]) => {
    if (value.includes(letter)) {
      return key;
    }
  });

  return className || "violet-class";
};
