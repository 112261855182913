<template>
  <div class="card-skeleton">

    <div class="header">
      <div class="img"></div>
      <div class="title"></div>
    </div>

    <div class="image"></div>

    <div class="info">
      <div class="content"></div>
    </div>

  </div>
</template>