<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted, watch } from "vue";
//vue-router
import { useRoute, useRouter, RouterLink } from "vue-router";
//component
import StarsComponent from "@/components/shared/StarsComponent.vue";
import ProductCarrousel from "@/components/shared/ProductCarrousel.vue";
import errorModal from "@/components/shared/errorModal.vue";
import PaymentsModal from "@/components/PaymentsModal.vue";
//helpers
import { sortSize } from "@/helpers/sortSize"

//stores
import {
  useProductStore,
  useCartStore,
  usePurchaseStore,
  useUserStore,
  useFavoriteStore,
  useRatingStore,
  useWindowStore,
  useCommentArticles,
  usePopStore,
  useSaveStore,
  userPayMethodStore,

} from "@/store/stores";
//helpers
import { date } from "@/helpers/dateFormatter";
import { capitalize } from "@/helpers/capitalize"
//swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
const modules = [Pagination];

//router
const route = useRoute();
const router = useRouter();

//stores
const { getProduct, getByMerchant } = useProductStore();
const { getRatingByArticle } = useRatingStore();
const { addItem, openCart } = useCartStore();
const $W = useWindowStore();
const $U = useUserStore();
const $P = usePurchaseStore();
const $F = useFavoriteStore();
const $S = useSaveStore();
const $COMMENTS = useCommentArticles();
const $POPUP = usePopStore();
const $PAY = userPayMethodStore();


//product
const loaded = ref(false);
const merchantProducts = ref([]);
const product = ref(null);
const variants = ref([]);
const selected = computed(() => {
  return variantSelected.value.reduce((acc, key) => acc[key], productMap.value);
});
const productMap = ref({});
const button = ref(true);
const shipping = ref(null);
const cp = ref("");

onMounted(async () => {
  loaded.value = false;
  //Producto
  product.value = await getProduct(route.params.id);
  active.value = product.value.isFavorited;
  const maxLength = ref($W.windowWidth > 768 ? 140 : 100);
  buttonDesc.value = decodeHTMLEntities(product.value?.description).length < maxLength.value
  product.value.articleVariants.forEach(variant => {
    for (let i = 0; i < product.value.attributes.length; i++) {
      const val = variant.values[i];
      if(variants.value[i]) {
        if(!variants.value[i].includes(val)) variants.value[i].push(val);
      } else {
        variants.value.push([val]);
      }
    }
  });
  productMap.value = generateCombinations(variants.value, product.value);
  console.log("VARIANTES", variants.value);
  console.log("PRODUCTO", product.value);
  console.log("MAP", productMap.value);
  defaultSelecter();
  //Carrousel del merchant
  const res = await getByMerchant(product.value.userMerchant.id, route.params.id);
  merchantProducts.value = res.articles;
  loaded.value = true;
  // //Opiniones
  ratings.value = await getRatingByArticle(route.params.id);
  // //Comentarios
  const comments = await $COMMENTS.getCommentsByArticle(route.params.id);
  commentsArticleById.value = comments;
});

//variant
const variantSelected = ref([]);

//save
const activeSave = computed(() => $S.save.find((s) => s === product.value?.id));
const save = () => {
  if (activeSave.value) {
    $S.deleteSave(product.value?.id);
  } else {
    $S.addSave(product.value?.id);
  }
};

//favorite
const logged = computed(() => Boolean($U.currentUser));
const active = ref(false);
const fav = async () => {
  if (active.value) {
    await $F.deleteFavorite(product.value?.id);
    active.value = false;
  } else {
    await $F.addFavorite(product.value?.id);
    active.value = true;
  }
};

//promotions
const installmentsAbsorption = computed(() => product.value?.userMerchant.configuration.installmentsAbsorption);
const shippingPercentageDiscount = computed(() => product.value?.userMerchant.configuration.shippingPercentageDiscount);
const getIMGPath = (img) => {
  return img ? require("@/assets/images/prom" + img + ".png") : "";
}

//description
const decodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}
const isExpanded = ref(false);
const buttonDesc = ref(null);

//img
const imgSelected = ref(0);
const selectImg = (id) => {
  imgSelected.value = id;
};

//functions
const generateCombinations = (arrays, data) => {
  function generate(current, index) {
    if (index === arrays.length) {
      const variant = data.articleVariants.find(variant => 
        variant.values.every((value, i) => value === current[i])
      );
      return variant ? {
        articleVariantId: variant.id,
        isAvailable: variant.isAvailable,
        price: variant.price,
        isOnPromotion: variant.isOnPromotion,
        promotionalPrice: variant.promotionalPrice,
        stock: variant.stock,
        values: variant.values } : {};
    } else {
      const category = arrays[index];
      const result = {};
      category.forEach(option => {
        const combinations = generate(current.concat(option), index + 1);
        result[option] = combinations;
      });
      return result;
    }
  }
  return generate([], 0);
};

const optionSelecter = (index, option) => {
  variantSelected.value[index] = option
  button.value = selected.value.isAvailable;
  console.log("optionSelected", selected.value);
}

const defaultSelecter = () => {
  variantSelected.value = interar(productMap.value, [], 0);
  console.log("optionSelected", selected.value);
}

const interar = (ob, selectors, index) => {
  if(ob?.articleVariantId) {
    return ob.isAvailable ? selectors : null;
  }
  let result;
  const values = Object.values(ob);
  const keys = Object.keys(ob);
  for (let i = 0; i < values.length; i++) {
    selectors[index] = keys[i];
    result = interar(values[i], selectors, index + 1);
    if(result) {
        break;
    }
  }
  return result;
}

const createAttrib = (values) => {
  const result = {};
  for (let i = 0; i < values.length; i++) {
    result[product.value.attributes[i]] = values[i];
  };
  return result;
}

const getPromotion = (promotions) => {
  if(promotions.length > 0) return promotions.find(prom => prom.type === '2x1') || promotions.find(prom => prom.type === '3x2') || promotions.find(prom => prom.type === '4x3');
  else return null;
};

const addToCart = () => {
  const addProd = {
    id: product.value.id,
    image: product.value.images[0],
    name: product.value.name,
    price: selected.value.price,
    promotionalPrice: selected.value.promotionalPrice,
    isOnPromotion: selected.value.isOnPromotion,
    promotion: getPromotion(product.value?.promotions),
    quantity: 1,
    stock: selected.value.stock,
    variantId: selected.value.articleVariantId,
    attributes: createAttrib(selected.value.values),
    merchantId: product.value.userMerchant.id
  };
  addItem(product.value, addProd);
  openCart();
};

const getShippingCost = async () => {
  shipping.value = [];
  const operativas = await $P.getOpetativas();
  const data = [{
    articleVariantId: selected.value.articleVariantId,
    quantity: 1,
  }];
  const puerta = await $P.getShippingCost(operativas.puerta, cp.value, data);
  const sucursal = await $P.getShippingCost(operativas.sucural, cp.value, data);
  shipping.value = [{puerta}, {sucursal}];
  if(!puerta && !sucursal) {
    shipping.value = null;
    $POPUP.popUpHandler("Verifica el código postal ingresado", 2);
  }
}

const scrollDown = () => {
  window.scrollBy({
    top: window.innerHeight - 60,
    left: 0,
    behavior: "smooth",
  });
};

//Comments
const commentsArticleById = ref(null);
const question = ref("");
const sendQuestions = async () => {
  if(commentsArticleById.value.find(c => c.response === null && c.userClientId === $U.currentUser.id)) {
    $POPUP.popUpHandler("Ya tenes un comentario sin responder.", 2);
    question.value = "";
  } else if($U.currentUser) {
    await $COMMENTS.newComment(route.params.id, question.value);
    question.value = "";
  } else {
    router.push("/logToContinue");
  }
};

//Rating
const ratings = ref(null);
const totalRatings = computed(() => {
  const totalRatings = ratings.value.map((r) => r.rating);
  return (totalRatings.reduce((a, b) => a + b, 0) / totalRatings.length).toFixed(1);
});
</script>

<template>
  <div v-show="loaded === true" class="product-container">
    <!-- IMAGENES -->
    <div class="card-images" v-if="$W.windowWidth > 768">
      <div v-if="product?.images.length > 0" class="img-selected">
        <img class="img-background" :src="product?.images[imgSelected]" :alt="product?.name" />
        <img class="img-index" :src="product?.images[imgSelected]" :alt="product?.name" />
        <div class="promotions">
          <img v-for="(prom, index) in product?.promotions" :key="index" :src="getIMGPath(prom.type)">
        </div>
      </div>
      <div v-else class="img-notFound">
        <v-icon>mdi-image-off-outline</v-icon>
      </div>

      <div class="card-carrousel" v-if="product?.images.length <= 4 && product?.images.length > 1">
        <template v-for="(image, index) in product?.images" :key="index">
          <img :src="image" :alt="product?.name" @click="selectImg(index)" />
        </template>
      </div>

      <div class="card-carrousel" v-if="product?.images.length > 4">
        <swiper :slidesPerView="4" :pagination="{clickable: true}" :modules="modules">
          <swiper-slide v-for="(image, index) in product?.images" :key="index">
            <img :src="image" :alt="product?.name" @click="selectImg(index)" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-else-if="product?.images.length > 0" class="card-images__images">
      <swiper :pagination="{type: 'fraction'}" :modules="modules">
        <swiper-slide v-for="(image, index) in product?.images" :key="index">
          <img class="img-index" :src="image" />
          <img class="img-background" :src="image" />
        </swiper-slide>
      </swiper>
      <v-icon @click="scrollDown" class="arrow">mdi-chevron-down</v-icon>
      <div class="promotions">
        <img v-for="(prom, index) in product?.promotions" :key="index" :src="getIMGPath(prom.type)">
      </div>
    </div>

    <!-- CARROUSEL DEL MERCHANT -->
    <div class="carrousel-merchant">
      <ProductCarrousel :data="merchantProducts" :title="`Otros productos de ${product?.userMerchant?.name}`" :type="'cardView'" />
    </div>

    <!-- INFORMACION DEL PRODUCTO -->
    <div class="product-information">
      <div class="card-information">

        <div class="card-interact">
          <RouterLink :to="`/merchant/${product?.userMerchant?.id}`">Ver mas productos de {{ product?.userMerchant?.name }}</RouterLink>
          <div>
            <v-icon v-if="logged" :class="{ active: active }" @click="fav">{{ active ? "mdi-heart" : "mdi-heart-outline" }}</v-icon>
            <img v-if="logged" :class="{ activeSave: activeSave }" @click.stop="save" src="../assets/logos/mall-small.svg" alt="">
          </div>
        </div>

        <div class="card-name">
          <h1>{{ product?.name }}</h1>
        </div>

        <div v-if="selected?.isOnPromotion" class="card-promotional">
          <h2>${{ selected?.promotionalPrice }}</h2>
          <span>${{ selected?.price }}</span>
          <h3>{{ Math.ceil(100 - ((selected?.promotionalPrice * 100) / selected?.price)) }}% OFF</h3>
        </div>

        <div v-else class="card-price">
          <h2>${{ selected?.price }}</h2>
          <span class="installment" v-if="installmentsAbsorption !== '0'">Mismo precio en {{ installmentsAbsorption }} cuotas de ${{ (selected?.price / installmentsAbsorption).toFixed() }}</span>
          <span class="shipping" v-if="shippingPercentageDiscount === 50">Envío a mitad de precio</span>
          <span class="shipping" v-if="shippingPercentageDiscount === 100">Envío gratis</span>
        </div>

        <span @click="$PAY.openPaymentsModal()">Ver los medios de pago</span>
        <v-dialog v-model="$PAY.modalPayments"><PaymentsModal/></v-dialog>

        <div class="card-info__container">
          <p :class="{text: !isExpanded}">{{ decodeHTMLEntities(product?.description) }}</p>
          <span v-if="!buttonDesc" @click="isExpanded = !isExpanded" class="show">{{ isExpanded ? "Ver menos" : "Ver mas" }}</span>
        </div>

        <div v-if="variants?.length && variantSelected?.length" class="variants-container">
          <div class="variant" v-for="(x, index) in product?.attributes" :key="index">
            <p>{{ capitalize(x) }}: <strong>{{ variantSelected[index].toUpperCase() }}</strong></p>
            <div class="variant-options">
              <span
              v-for="(option, i) in sortSize(variants[index])"
              :key="i"
              @click="optionSelecter(index, option)"
              :class="{active: variantSelected[index] === option}">{{ option.toUpperCase() }}</span>
            </div>
          </div>
        </div>

        <div class="card-buttons">
          <button :disabled="!button" class="blue-button" @click="addToCart()">
            {{ (!button) ? "No hay stock" : "Agregar al carrito" }}
          </button>
          <v-dialog v-model="$POPUP.errorModal"><errorModal errorText="Debes elegir un talle" /></v-dialog>
        </div>
      </div>

      <!-- INFO DEL MERCHANT -->
      <div class="card-merchant">
        <div v-if="!shipping" class="input">
          <v-text-field
            append-inner-icon="mdi-magnify"
            label="Calcular envio con el código postal"
            variant="underlined"
            hide-details
            v-model="cp"
            type="number"
            @click:append-inner="cp.length > 0 ? getShippingCost() : null"
            @keyup.enter="cp.length > 0 ? getShippingCost() : null"
          ></v-text-field>
          <a href="https://www.correoargentino.com.ar/formularios/cpa" target="_blank">No sé mi código postal</a>
        </div>
        <div class="card-shipping">
          <div class="loading" v-if="shipping && !shipping?.length">
            <img class="manijita" src="../assets/logos/manijita.svg">
            <img class="bolsa" src="../assets/logos/bolsa.svg">
          </div>
          <div v-if="shipping && shipping?.length > 0" class="cp">
            <p>Entregas para el código postal: <strong>{{ cp }}</strong></p>
            <span @click="shipping = null; cp = ''">Cambiar</span>
          </div>
          <div v-for="(cost, index) in shipping" :key="index" class="cp-price">
            <p v-if="cost.puerta">Envio a domicilio <strong>${{ cost.puerta }}</strong></p>
            <p v-if="cost.sucursal">Retiro en sucursal de OCA <strong>${{ cost.sucursal }}</strong></p>
          </div>
        </div>
      </div>
    </div>

    <!-- PREGUNTAS Y RESPUESTAS -->
    <div class="message-container">
      <p class="title">Preguntas y respuestas</p>
      <div class="message-input">
        <input v-model="question" type="text" placeholder="Escribi tu pregunta..."/>
        <button @click="sendQuestions()" class="blue-button">Enviar</button>
      </div>

      <div class="messages">
        <div class="message" v-for="(comment, index) in commentsArticleById" :key="index">
          <p class="message-text">{{ comment.message }}</p>
          <p class="message-response">
            <v-icon v-if="comment.response">mdi-arrow-down-left</v-icon>{{ comment.response ?? "(Te notificaremos cuando la tienda responda tu pregunta)" }}
          </p>
        </div>
      </div>
    </div>

    <!-- OPINIONES -->
    <div v-if="ratings?.length > 0" class="rating-container">
      <p class="title">Opiniones del producto</p>

      <div class="ratings-total">
        <div class="ratings-total__stars">
          <span>{{ totalRatings }}</span>
          <v-icon>mdi-star</v-icon>
        </div>
        <p>{{ ratings.length }} calificaciones</p>
      </div>

      <div class="ratings-users">
        <div v-for="(rating, index) in ratings" :key="index" class="rating">
          <div class="users-stars">
            <StarsComponent :rating="rating.rating" />
            {{ date(rating.updatedAt) }}
          </div>
          <p>{{ rating.comment }}</p>
        </div>
      </div>
    </div>
    <div v-else class="rating-container">
      <p class="title">Opiniones del producto</p>
      <p>Aún no hay opiniones</p>
    </div>
  </div>

  <div v-if="loaded === false" class="product-skeleton">
    <div class="image">
      <v-icon>mdi-loading</v-icon>
    </div>
    <div class="information">
      <div class="link"></div>
      <div class="title"></div>
      <div class="price"></div>
      <div class="link"></div>
      <div class="description"></div>
      <!-- <div class="button"></div> -->
      <div class="button"></div>
    </div>
    <div class="carrousel"></div>
    <div class="merchant"></div>
  </div>
</template>
