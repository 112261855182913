<script setup>
/* eslint-disable */
//vue
import { ref } from "vue";
//vue-router
import { useRouter } from "vue-router";
//store
import { useWindowStore, useSearchStore } from "@/store/stores";

const router = useRouter();
const $W = useWindowStore();
const $S = useSearchStore();

const searchModel = ref("");
const searchModal = ref(false);
const focusOn = ref(false);

const search = () => {
  if(searchModel.value !== "") {
    $S.postSearch(searchModel.value);
    router.push(`/search/${searchModel.value}`);
    focusOn.value = false;
    searchModal.value = false;
    searchModel.value = "";
  }
};

const goTo = (word) => {
  router.push(`/search/${word.toLowerCase()}`);
  focusOn.value = false;
  searchModal.value = false;
};
</script>

<template>
  <div v-if="focusOn || searchModal" @click="focusOn = false; searchModal = false" class="search-background"></div>
  <div :class="{'navbar-form': $W.windowWidth > 768, 'navbar-form-mobile': $W.windowWidth <= 768}">
    <div class="form" @click="focusOn = true" v-if="$W.windowWidth > 768" @keydown.enter="search">
      <input v-model="searchModel" type="text" placeholder="Buscar..."  />
      <button :disabled="searchModel === ''" @click="search">
        <v-icon icon="mdi-magnify"></v-icon>
      </button>
    </div>

    <v-icon v-else @click="searchModal = true" icon="mdi-magnify"></v-icon>

    <div v-if="$W.windowWidth < 768 ? true : $S.searchs.length > 0" class="search" :class="{ active: focusOn || searchModal }">
      <div class="form" v-if="$W.windowWidth < 768">
        <button :disabled="searchModel === ''" @click="search">
          <v-icon icon="mdi-magnify"></v-icon>
        </button>
        <input @keydown.enter="search" v-model="searchModel" type="text" placeholder="Buscar..."  />
        <v-icon @click="searchModal = false" icon="mdi-close" class="close"></v-icon>
      </div>
      <div v-if="$S.searchs.length > 0" class="words">
        <h3>Búsquedas recientes</h3>
        <div class="words-container">
          <div @click="focusOn = false; searchModal = false" class="word" v-for="(word, index) in $S.searchs" :key="index">
            <span @click="goTo(word)">{{ word }}</span>
            <v-icon @click.stop="$S.deleteSearch(index); focusOn = true">mdi-close</v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>