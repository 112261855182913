import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers/fetchWrapper";
import { usePopStore, useFilterStore, useProfileStore } from "@/store/stores";
import { sortProducts } from "@/helpers/sortProduct";

export const useProductStore = defineStore("products", () => {
  const $P = usePopStore();
  const $F = useFilterStore();
  const $PROFILE = useProfileStore();

  const getProducts = async (count) => {
    try {
      const res = await fetchWrapper.post(
        `search/articlesByQuery?limit=20&isAvailable=true&count=true&favoritedCount=true&isDeleted=false&offset=${count}&minPrice=${$F.filter.priceRange[0]}&maxPrice=${$F.filter.priceRange[1]}&orderBy=price${$F.filter.order ? `&orderCriteria=${$F.filter.order}` : ""}`, {
          tagsAnd: "",
          tagsOr: $F.filter.checkSize ? `${$PROFILE.currentProfile.bottomSize ? ($PROFILE.currentProfile.bottomSize + " ") : ""}${$PROFILE.currentProfile.shoeSize ? ($PROFILE.currentProfile.shoeSize + " ") : ""}${$PROFILE.currentProfile.torso ? ($PROFILE.currentProfile.torso) : ""}` : ""
        }
      );
      return {
        articles: sortProducts(res.data.articles),
        count: res.data.count
      };
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar los productos", 0);
    }
  };

  const getProduct = async (id) => {
    try {
      const res = await fetchWrapper.get(`articles/${id}`);
      return res.data;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar el producto", 0);
    }
  };

  const getByIds = async (data) => {
    try {
      const res = await fetchWrapper.post(
        "articles/byIds?isDeleted=false&isAvailable=true&favoritesCount=true",
        data
      );
      return res.data;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar los productos", 0);
    }
  };

  const getBySearch = async (data, offset) => {
    try {
      const res = await fetchWrapper.post(`search/articlesByQuery?limit=20&offset=${offset}&isAvailable=true&favoritesCount=true&count=true&minPrice=${$F.filter.priceRange[0]}&maxPrice=${$F.filter.priceRange[1]}&orderBy=price${$F.filter.order ? `&orderCriteria=${$F.filter.order}` : ""}`, {
        tagsAnd: data,
        tagsOr: $F.filter.checkSize ? `${$PROFILE.currentProfile.bottomSize ? ($PROFILE.currentProfile.bottomSize + " ") : ""}${$PROFILE.currentProfile.shoeSize ? ($PROFILE.currentProfile.shoeSize + " ") : ""}${$PROFILE.currentProfile.torso ? ($PROFILE.currentProfile.torso) : ""}` : ""
      });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const getMerchantBySearch = async (data) => {
    try {
      const res = await fetchWrapper.post("search/usersMerchantByQuery?isAvailable=true&count=true&hasStoreEnabled=true", {
        query: data
      });
      return res.data.usersMerchant;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserBySearch = async (data) => {
    try {
      const res = await fetchWrapper.post("search/usersClientByQuery?count=true&isAvailable=true", {
        query: data
      });
      return res.data.usersClient;
    } catch (e) {
      console.log(e);
    }
  };

  const getMerchant = async (merchantId) => {
    const res = await fetchWrapper.get(`merchants/users/${merchantId}`);
    return res.data.userMerchant;
  };

  const getByMerchant = async (merchantId, currentArticleId, offset = 0) => {
    try {
      const res = await fetchWrapper.post(`search/articlesByQuery?userMerchantId=${merchantId}&limit=20&offset=${offset}&isAvailable=true&isDeleted=false&count=true&favoritesCount=true&minPrice=${$F.filter.priceRange[0]}&maxPrice=${$F.filter.priceRange[1]}&orderBy=price${$F.filter.order ? `&orderCriteria=${$F.filter.order}` : ""}${ currentArticleId ? `&excludeId=${currentArticleId}` : ""}`, {
        tagsAnd: "",
        tagsOr: $F.filter.checkSize ? `${$PROFILE.currentProfile.bottomSize ? ($PROFILE.currentProfile.bottomSize + " ") : ""}${$PROFILE.currentProfile.shoeSize ? ($PROFILE.currentProfile.shoeSize + " ") : ""}${$PROFILE.currentProfile.torso ? ($PROFILE.currentProfile.torso) : ""}` : ""
      });
      return res.data;
    } catch (e) {
      console.log(e);
      $P.popUpHandler("Hubo un error al cargar los productos del merchant", 0);
    }
  };

  const getCategoriesByMerchant = async (merchantId) => {
    const res = await fetchWrapper.get(`categoryMerchant/byMerchant/${merchantId}`);
    const map = res.data.categoryMerchants.map(x => {
      x.path = `/merchant/category/${x.id}`;
      return x;
    });
    console.log("categorias del merchant", map);
    return res.data.categoryMerchants;
  };

  const getCategoryProducts = async (categoryId, offset) => {
    try {
      const res = await fetchWrapper.get(`categoryMerchant/article/byCategory/${categoryId}?limit=20&offset=${offset}&isAvailable=true&isDeleted=false&articleMinPrice=${$F.filter.priceRange[0]}&articleMaxPrice=${$F.filter.priceRange[1]}&favoritesCount=true&count=true&articleOrderBy=price${$F.filter.order ? `&articleOrderCriteria=${$F.filter.order}` : ""}`);
      console.log(res);
      return res.data;
    } catch (e) {
      console.log("Error al traer los productos de la categoria", e);
    }
  };

  return { getProducts, getProduct, getByIds, getBySearch, getMerchantBySearch, getUserBySearch, getMerchant, getByMerchant, getCategoriesByMerchant, getCategoryProducts };
}, {
  persist: true
});
