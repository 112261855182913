<template>
  <div class="view">
    <div class="back-close">
      <div class="back" @click="$Profile.currentView--">
        <v-icon icon="mdi-chevron-left" />
        <div class="back-text">Atras</div>
      </div>
     <DotsView/>
    </div>
    <div class="gender-image">
      <img src="../../assets/gender-modal-blue.svg" alt="logo" />
    </div>
    <div class="gender-title">¿Cuál es tu género?</div>
    <div class="gender-text">
      Esto nos ayuda a encontrar lo más relevante para vos.
    </div>
    <div class="selects">
      <div class="gender-select">
        <v-select
          v-model="dataToSend.genre"
          label="Tu género"
          :items="['Mujer', 'Hombre', 'Otro']"
          variant="outlined"
        ></v-select>
      </div>
      <div class="gender-select">
        <v-text-field
          v-model="dataToSend.otherGender"
          label="Especificar otro"
          variant="outlined"
          :disabled="dataToSend.genre !== 'Otro'"
        ></v-text-field>
      </div>
    </div>
    <button class="modal-button" @click="nextModal">
      Siguiente
    </button>
    <div class="bottom-text" v-if="!$User.currentUser">
      <p>
        ¿Ya sos miembro? <strong><RouterLink to="/login"><a href="#">Iniciar Sesión</a></RouterLink></strong>
      </p>
    </div>
  </div>
</template>

<script setup>
import { RouterLink } from "vue-router";
import { reactive } from "vue";
import { useProfileStore, useUserStore } from "@/store/stores";
import DotsView from "./DotsView.vue";


const $Profile = useProfileStore();
const $User = useUserStore();
const currentProfile = $Profile.currentProfile;
const dataToSend = reactive({
  genre: "",
  otherGender: "",
});
const nextModal = () => {
  currentProfile.genre = dataToSend.genre;
  $Profile.currentView++;
  console.log ("genero en gendermodal",$Profile.currentProfile.genre);
};
</script>
