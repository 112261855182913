<script setup>
//vue
import { ref, computed, onMounted } from "vue";
//components
import AddressDetails from "@/components/profile/address/AddressDetails.vue";
//store
import { useAddressStore } from "@/store/stores";

const $A = useAddressStore();

const adresses = computed(() => $A.addresses);
const loaded = ref(false);

onMounted(async() => {
  await $A.getAllAddresses();
  console.log(adresses.value);
  loaded.value = true;
});
</script>

<template>
  <div v-if="loaded" class="addresses">

    <div class="addresses-title">
      <h1>Domicilios</h1>

      <RouterLink :to="`/profile/info/add/address`" class="blue-button"><v-icon>mdi-plus</v-icon></RouterLink>
    </div>

    <div v-if="adresses?.length === 0" class="empty-data">
      <img src="../../../assets/shoesize-modal-blue.svg" alt="svg">
      <span>Agrega tu domicilio tocando "+"</span>
      <p>Todavia no agregaste ningun domicilio.</p>
    </div>

    <div v-else class="addresses-address">
      <AddressDetails v-for="(address, index) in adresses" :key="index" :index="address.id" :data="address" />
    </div>

  </div>
</template>

<style scoped>
.blue-button {
  padding: 10px;
  border-radius: 100%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  font-size: 20px;
}
</style>