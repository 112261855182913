<script setup>
/* eslint-disable */
//vue
import { onMounted, ref } from "vue";
//vue-router
import { useRouter, RouterLink } from "vue-router";
//store
import { usePurchaseStore, useUserStore } from "@/store/stores";
//helpers
import { capitalize } from "@/helpers/capitalize";

const $P = usePurchaseStore();
const $U = useUserStore();
const router = useRouter();
const loaded = ref(false);
const creatingOrders = ref(false);

onMounted(() => {
  if($P.cart.totalPrice === 0) {
    router.push("/");
    return;
  }
  if($P.step > 3) {
    $P.step = 4;
    loaded.value = true;
  } else {
    router.push(`/cart/purchase/installment-options`);
  }
})

const confirm = async () => {
  creatingOrders.value = true;
  $P.step = 4
  const ordersPromises = [];
  for (const key in $P.cart.merchants) {
    const orderItems = $P.cart.merchants[key].products.map(p => {
      return {
        articleVariantId: p.variantId,
        quantity: p.quantity
      }
    })
    const createOrderBody = {
      branchId: $P.shipping.method === "branch" ? $P.shipping.address.IdCentroImposicion : undefined,
      branchPostalCode: $P.shipping.method === "branch" ? $P.shipping.address.CodigoPostal.toString() : undefined,
      orderItems,
      orderItemsDiscount: $P.cart.merchants[key].discount,
      pickUpStore: $P.shipping.method === "pickUp",
      useBranch: $P.shipping.method === "branch",
      userClientAddressId: $U.currentUser.address.id,
    };
    ordersPromises.push($P.makeOrder(createOrderBody, $P.cart.merchants[key].merchant));
  };
  await Promise.all(ordersPromises).then(res => {
    $P.payOrderBodies = [];
    for (const order of res) {
      if(order.order) {
        const installment = getInstallmentByUserMerchant(order.order.userMerchantId);
        const discount = order.order.orderItemsDiscount.reduce((accumulator, currentValue) => accumulator + currentValue.totalDiscount, 0);
        console.log("order", order.order.total);
        console.log("shipping", order.order.orderDetails.shippingCost);
        $P.payOrderBodies.push({
          data: {
            userClientId: order.order.userClientId,
            paymentCardId: $P.payment.card.id,
            lastFourDigits: $P.payment.card.lastFourDigits,
            paymentData: {
              orderId: order.order.id,
              amountToPay: order.order.total + order.order.orderDetails.shippingCost - discount,
              installments: $P.payment.card.isCreditCard ? installment.actualInstallments : undefined,
              installmentsTotalAmount: $P.payment.card.isCreditCard ? installment.totalAmount : undefined
            },
          },
          merchant: order.merchant,
          payingOff: false,
        });
      } else {
        $P.payOrderBodies.push({
          error: true,
          merchant: order.merchant
        });
      }
    }
  })
  $P.step = 5;
  if($P.payOrderBodies.every(x => x.error === true)) router.push("/purchase/order-error");
  else router.push("/cart/purchase/pay-orders");
};

//functions
const getInstallmentByUserMerchant = (userMerchantId) => {
  const installmentIndex = $P.installmentsPlans.findIndex(x => x.userMerchant.id === userMerchantId);
  const installment = $P.installmentsPlans[installmentIndex].installment;
  return installment;
};
</script>

<template>
  <div v-if="loaded" class="confirm">
    <h1>Revisa y confirma tu compra</h1>
    <h3>¡Ya casi estamos! Revisá los datos y confirmá la orden.</h3>
    <p>Detalles del envío</p>

    <div v-if="$P.shipping.method === 'pickUp'" class="confirm-options__option">
      <v-icon>mdi-account-arrow-right-outline</v-icon>
      <div>
        <strong>Retira en {{ capitalize($P.shipping.address?.mainStreet) }} {{ $P.shipping.address?.number }}</strong>
        <div>
          <p>{{ "C.P. " + $P.shipping.address?.postalCode + " - " + $P.shipping.address?.location + ", " +
            $P.shipping.address?.state }}</p>
          <p>{{ $P.shipping.address?.alias + " - " + $P.shipping.address?.phone }}</p>
        </div>
      </div>
      <RouterLink :to="`/cart/purchase/shipping-options`" class="primary-button">Editar</RouterLink>
    </div>

    <div v-else-if="$P.shipping.method === 'branch'" class="confirm-options__option">
      <v-icon>mdi-store-outline</v-icon>
      <div>
        <strong>Retira en {{ capitalize($P.shipping.address?.Calle) }} {{ $P.shipping.address?.Numero }}</strong>
        <div>
          <p>{{ "C.P. " + $P.shipping.address?.CodigoPostal + " - " + $P.shipping.address?.Localidad + ", " +
            $P.shipping.address?.Provincia }}</p>
          <p><a class="link" href="https://www.oca.com.ar/CentroDeAyuda" target="_blank">O.C.A - Centro de ayuda</a> / Telefono: {{ $P.shipping.address?.Telefono }}</p>
        </div>
      </div>
      <RouterLink :to="`/cart/purchase/shipping-options`" class="primary-button">Editar</RouterLink>
    </div>

    <div v-else class="confirm-options__option">
      <v-icon>mdi-home-variant-outline</v-icon>
      <div>
        <strong>Envío a {{ $P.shipping.address?.mainStreet + " " + $P.shipping.address?.number }}</strong>
        <div>
          <p>{{ "C.P. " + $P.shipping.address?.postalCode + " - " + $P.shipping.address?.location + ", " +
            $P.shipping.address?.state }}</p>
          <p>{{ $P.shipping.address?.alias + " - " + $P.shipping.address?.phone }}</p>
        </div>
      </div>
      <RouterLink :to="`/cart/purchase/shipping-options`" class="primary-button">Editar</RouterLink>
    </div>

    <p>Detalles del pago</p>

    <div class="confirm-options__option">
      <v-icon>mdi-credit-card-outline</v-icon>
      <div>
        <h3>{{ $P.payment.card?.holderName + " " + $P.payment.card?.lastFourDigits }}</h3>
        <div v-for="(plan, index) in $P.installmentsPlans" :key="index" >
          <p v-if="$P.payment.card?.isCreditCard">Plan de {{ plan.userMerchant.name }}: Pagás ${{ plan.installment.totalAmount.toFixed() }} en {{ plan.installment.actualInstallments.toFixed() }} cuotas de <strong>${{ plan.installment.amountPerInstallment.toFixed() }}</strong></p>
          <p v-else>Plan de {{ plan.userMerchant.name }}: Pagás <strong>${{ plan.installment.totalAmount.toFixed() }}</strong></p>
        </div>
      </div>
      <RouterLink :to="`/cart/purchase/payment-options`" class="primary-button">Editar</RouterLink>
    </div>
    <div class="buttons">
      <button @click="router.back()" class="blue-button">Atras</button>
      <button :disabled="creatingOrders" @click="confirm" class="blue-button">Confirmar orden</button>
    </div>

  </div>
</template>

<style scoped>
.link {
  color: #0045cf;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>