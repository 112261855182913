<script setup>
/* eslint-disable */
//vue
import { ref, computed, onMounted } from "vue";
//vue-router
import { useRouter } from "vue-router";
//components
import PaymentCardCarrousel from "@/components/PaymentCardCarrousel.vue";
import PaymentCard from "@/components/shared/PaymentCard.vue";
//helpers
import { capitalize } from "@/helpers/capitalize.js";
//stores
import { useUserStore, usePaymentStore, usePopStore, useWindowStore, usePurchaseStore, useAddressStore } from "@/store/stores";
//props
const props = defineProps(["data"]);
const emit = defineEmits(["close"]);

const router = useRouter();
const $U = useUserStore();
const $C = usePaymentStore();
const $W = useWindowStore();
const $P = usePopStore();
const $PURCHASE = usePurchaseStore();
const $A = useAddressStore();

const step = ref(1);
const securityCode = ref("");
const card = ref(null);
const installments = ref([]);
const installmentSelected = ref(null);
const payingOff = ref(false);
const paying = ref(false);
const paid = ref(false);
const sucursal = ref(null);
const loading = ref(true);

const allCards = computed(() => $C.paymentCards);
onMounted(async () => {
  await $C.getAllCards();
  if(props?.data.branchId) {
    sucursal.value = await $A.geOcaBranchById(props?.data.branchPostalCode, props?.data.branchId);
  }
  loading.value = false;
});

const mainCard = ref($U.currentUser.mainPaymentCardId);

const addCard = () => {
  router.push(`/cart/purchase/add/paymentCard`);
};

const close = () => {
  emit("close", false);
}

const selectCard = async () => {
  $U.currentUser.mainPaymentCardId = mainCard.value;
  card.value = await $C.getCard(mainCard.value);
  if(card.value.isCreditCard) {
    const res = await $C.getInstallmentByOrder(props?.data, card.value.id);
    console.log(res);
    installments.value = res;
    step.value = 2;
  } else {
    installmentSelected.value = {
      totalAmount: props?.data.total,
      amountPerInstallment: props?.data.total,
      installments: 1,
      actualInstallments: 1,
      coefficient: 1
    };
    step.value = 3;
  }
};

const selectInstallment = () => {
  step.value = 3
};

const payOrder = async () => {
  const validateCard = await $C.validateCard(card.value.id, $U.currentUser.id, securityCode.value, card.value.lastFourDigits);
  if(validateCard) {
    paying.value = true;
    const discount = props?.data.orderItemsDiscount.reduce((accumulator, currentValue) => accumulator + currentValue.totalDiscount, 0);
    const data = {
      userClientId: $U.currentUser.id,
      paymentCardId: card.value.id,
      securityCode: securityCode.value,
      lastFourDigits:card.value.lastFourDigits,
      paymentData: {
        orderId: props?.data.id,
        amountToPay: props?.data.total + props?.data.orderDetails.shippingCost - discount,
        installments: card.value.isCreditCard ? installmentSelected.value.actualInstallments : undefined,
        installmentsTotalAmount: card.value.isCreditCard ? installmentSelected.value.totalAmount : undefined
      },
    };
    const res = await $PURCHASE.payOrder(data);
    paying.value = false;
    if(res) {
      payingOff.value = true;
      paid.value = true;
      setTimeout(() => {
        router.go();
      }, 1000);
    } else {
      payingOff.value = "error";
      paid.value = true;
    }
  } else {
    securityCode.value = "";
    $P.popUpHandler("El código ingresado no es correcto", 0);
  };
};

const getInfo = (info) => {
  mainCard.value = info.id;
};
</script>

<template>
  <div class="purchase-payment">
    <div v-if="loading" class="loading">
      <img class="manijita" src="../../../assets/logos/manijita.svg">
      <img class="bolsa" src="../../../assets/logos/bolsa.svg">
    </div>
    <div v-else class="purchase-payment_process">
      <v-icon @click="close" class="close">mdi-close</v-icon>

      <div v-if="step === 1" class="purchase-payment_card">
        <p class="title">¿Cómo querés pagar?</p>
        <p v-if="$U.currentUser.mainPaymentCardId === null">No tenes tarjetas agregadas</p>
        <div v-else class="options-paymentCard">
          <PaymentCardCarrousel @sendInfo="getInfo" :data="allCards" />
        </div>
        <div class="buttons">
          <button @click="addCard" class="form-button">{{ $W.windowWidth > 768 ? '+ Agregar tarjeta' : "+ Agregar" }}</button>
          <button :disabled="!allCards?.length > 0" @click="selectCard" class="blue-button">Seleccionar</button>
        </div>
      </div>

      <div v-if="step === 2" class="purchase-payment_card">
        <p class="title">¿En cuantas cuotas?</p>
        <PaymentCard :delete="false" :data="card" />
        <div class="options-installment">
          <div v-for="(i, index) in installments" :key="index" class="options-installment__option" @click="installmentSelected = i">
            <div class="radio" :class="{active: installmentSelected === i}">
              <v-icon>mdi-check</v-icon>
            </div>
            <div class="installment-content">
              <div>
                <p class="installment">{{ i.actualInstallments }}x</p>
                <p class="price">${{ i.amountPerInstallment }}</p>
              </div>
              <div v-if="i.installments !== 1">
                <p v-if="i.coefficient > 1" class="interest">{{ ((i.coefficient - 1) * 100).toFixed() }}% de interes</p>
                <p v-else class="interest">Sin interes</p>
                <p class="totalPrice">${{ i.totalAmount }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button @click="step = 1" class="blue-button">Atras</button>
          <button :disabled="!installmentSelected" @click="selectInstallment" class="blue-button">Continuar</button>
        </div>
      </div>

      <div v-if="step === 3" class="purchase-payment_card">
        <p class="title">Falta confirmar la compra</p>
        <PaymentCard :delete="false" :data="card" />
        <input v-if="!paid" type="text" v-model="securityCode" placeholder="Código de seguridad">

        <h3>Detalles del envío</h3>
        <div class="details-container">
          <div v-if="data.pickUpStore" class="confirm-options__option">
            <v-icon>mdi-account-arrow-right-outline</v-icon>
            <div>
              <strong>Retira en {{ capitalize(data.userMerchantAddress.mainStreet) }} {{ data.userMerchantAddress.number }}</strong>
              <div>
                <p>{{ "C.P. " + data.userMerchantAddress.postalCode + " - " + data.userMerchantAddress.location + ", " +
                  data.userMerchantAddress.state }}</p>
                <p>{{ data.userMerchantAddress.alias + " - " + data.userMerchantAddress.phone }}</p>
              </div>
            </div>
          </div>

          <div v-else-if="data.branchId" class="confirm-options__option">
            <v-icon>mdi-store-outline</v-icon>
            <div>
              <strong>Retira en {{ capitalize(sucursal.Calle) }} {{ sucursal.Numero }}</strong>
              <div>
                <p>{{ "C.P. " + sucursal.CodigoPostal + " - " + sucursal.Localidad + ", " + sucursal.Provincia }}</p>
                <p><a class="link" href="https://www.oca.com.ar/CentroDeAyuda" target="_blank">O.C.A - Centro de ayuda</a> / Telefono: {{ sucursal.Telefono }}</p>
              </div>
            </div>
          </div>

          <div v-else class="confirm-options__option">
            <v-icon>mdi-home-variant-outline</v-icon>
            <div>
              <strong>Envío a {{ data.userClientAddress.mainStreet + " " + data.userClientAddress.number }}</strong>
              <div>
                <p>{{ "C.P. " + data.userClientAddress.postalCode + " - " + data.userClientAddress.location + ", " +
                  data.userClientAddress.state }}</p>
                <p>{{ data.userClientAddress.alias + " - " + data.userClientAddress.phone }}</p>
              </div>
            </div>
          </div>

          <div class="confirm-options__option">
            <v-icon>mdi-credit-card-outline</v-icon>
            <div>
              <h3>{{ card?.holderName + " " + card?.lastFourDigits }}</h3>
              <div>
                <p>Pagás ${{ installmentSelected.totalAmount }} en {{ installmentSelected.actualInstallments }} cuotas de <strong>${{ installmentSelected.amountPerInstallment }}</strong></p>
              </div>
            </div>
          </div>

          <div class="payOrder">
            <div class="order">
              <div class="merchant">
                <div class="image">
                  <img :src="data.userMerchant.image">
                </div>
                <span>{{ data.userMerchant.name }}</span>
              </div>
              
              <div class="check">
                <img v-if="payingOff === false" :class="{paying: paying}" src="../../../assets/logos/mall-small.svg">
                <v-icon v-else-if="payingOff === true">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!paid" class="buttons">
          <button @click="card.isCreditCard ? step = 2 : step = 1" class="blue-button">Atras</button>
          <button :disabled="securityCode === '' || paying" @click="payOrder" class="blue-button">Comprar</button>
        </div>
        <div v-else>
          <p v-if="payingOff === true" class="success">¡Realizaste la compra con exito!</p>
          <p v-else class="error">Hubo un error al realizar la compra intentelo nuevamente</p>
        </div>
      </div>

    </div>
  </div>
</template>