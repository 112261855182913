<template>
  <div class="view">
    <div class="back-close">
      <DotsView />
    </div>
    <div class="wellcome-title">¡¿Como te llamamos?!</div>
    <div class="insert-alias">
      <div class="alias">
        <v-text-field
          v-model="dataToSend.alias"
          label="Escribe tu nombre de alias"
          variant="outlined"
        ></v-text-field>
      </div>
    </div>
    <div class="wellcome-text">
      Recuerda que no puedes tener dos nombres iguales
    </div>
    <button class="modal-button" @click="nextModal">
      Siguiente
    </button>
  </div>
</template>

<script setup>
import { useProfileStore } from "@/store/stores";
import DotsView from "@/views/login/DotsView.vue";
import { reactive } from "vue";

const $Profile = useProfileStore();
const dataToSend = reactive({
  alias: "",
});
const nextModal = () => {
  $Profile.isCreatingNewProfile = true;
  $Profile.currentProfile.alias = dataToSend.alias;
  $Profile.currentView++;
};
</script>
