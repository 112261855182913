<script setup>
/* eslint-disable */
//vue
import { ref, reactive } from "vue";
//stores
import { useUserStore, useInputStore, usePopStore, useWindowStore } from "@/store/stores";
//firebase
import { getAuth, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const $U = useUserStore();
const $P = usePopStore();
const $W = useWindowStore();
const { required } = useInputStore();

const showPassw = ref(false);
const modal = ref(false);
const modalEmail = ref("");
const formData = reactive({
  email: "",
  password: ""
});

const loginUser = async () => {
  await $U.login(formData.email.toLowerCase(), formData.password);
};

const resetPasswd = async (email) => {
  const res = await $U.confirmUser(email.toLowerCase());
  if(res) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email.toLowerCase())
      .then(() => {
        modal.value = false;
        $P.popUpHandler("Se envio exitosamente el correo de recuperacion");
      })
      .catch((error) => {
        console.log("error de recuperacion", error);
        modal.value = false;
        $P.popUpHandler("Hubo un error al enviar el correo de recuperacion", 0);
      });
  } else {
    $P.popUpHandler("No existe el correo electronico ingresado", 2);
    modal.value = false;
  }
  modalEmail.value = "";
}

const googleLogin = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  console.log("1")
  try {
    const res = await signInWithPopup(auth, provider);
    console.log("2")
    const token = {
      accessToken: res.user.accessToken
    };
    console.log("3")
    await $U.googleLogin(token);
    console.log("4")
  } catch (e) {
    console.log("Error de google", e);
    $P.popUpHandler("Hubo un error al loguearse con google", 0);
  }
};

</script>

<template>
  <div class="login-container">
    <div v-if="$W.windowWidth > 768" class="login-img">
      <img src="../assets/images/registro.webp">
    </div>
    <div class="form-user">
      <img src="../assets/logos/mall.svg" alt="logo">
      <p class="title">Te damos la bienvenida</p>
      <div class="form">
        <v-text-field :rules="[required]" v-model="formData.email" type="email" label="Correo electrónico" variant="outlined"></v-text-field>
        <v-text-field :rules="[required]" v-model="formData.password" :type="showPassw ? 'text' : 'password'" label="Contraseña" variant="outlined" @keyup.enter="loginUser" :append-inner-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPassw = !showPassw"></v-text-field>
        <strong class="modal" @click="modal = true">¿Te olvidaste la contraseña?</strong>
        <button class="blue-button" @click.prevent="loginUser">Continuar</button>
        <!-- <button @click="googleLogin" class="classic-button"><img src="../assets/google.svg" alt="google">Continuar con Google</button> -->
      </div>
      <div v-if="modal" class="reset-pass">
        <v-icon @click="modal = false">mdi-close</v-icon>
        <v-text-field v-model="modalEmail" label="Correo electrónico" variant="outlined" hide-details @keyup.enter="resetPasswd(modalEmail)"></v-text-field>
        <div @click="resetPasswd(modalEmail)" class="classic-button">Recuperar contraseña</div>
      </div>
      <div class="login-text">
        <p>¿No tenes una cuenta? <strong><RouterLink to="/signin">Crear cuenta</RouterLink></strong></p>
      </div>
    </div>
  </div>
</template>