<template>
  <div class="notifications">
    <div class="notifications-title">Notificaciones:</div>
    <div class="notifications-sub-title">
      Elegí qué tipo de información querés recibir.
    </div>
    <div class="notifications-switches">
      <div class="switches">
        <div class="title">E-mails</div>
        <div class="switches-divider">
          <div class="text">Ofertas y promociones</div>
          <v-switch
            v-model="$N.notifications.offers"
            @change="$N.updateNotifications"
            hide-details="true"
            color="blue"
          ></v-switch>
        </div>
        <div class="profile-divider">
          <div class="divider">
            <v-divider class="custom-divider"></v-divider>
          </div>
        </div>
        <div class="switches-divider">
          <div class="text">Novedades</div>
          <v-switch
            v-model="$N.notifications.news"
            @change="$N.updateNotifications"
            hide-details="true"
            color="blue"
          ></v-switch>
        </div>

        <div class="profile-divider">
          <div class="divider">
            <v-divider class="custom-divider"></v-divider>
          </div>
        </div>
        <div class="switches-divider">
          <div class="text">Mensajes nuevos</div>
          <v-switch
            v-model="$N.notifications.newMessages"
            @change="$N.updateNotifications"
            hide-details="true"
            color="blue"
          ></v-switch>
        </div>

        <div class="profile-divider">
          <div class="divider">
            <v-divider class="custom-divider"></v-divider>
          </div>
        </div>
      </div>
      <div class="switches">
        <div class="title">Whatsapp</div>
        <div class="switches-divider">
          <div class="text">Notificaciones de compras</div>
          <v-switch
            v-model="$N.notifications.shoppingNotifications"
            hide-details="true"
            color="blue"
            @change="$N.updateNotifications"
          ></v-switch>
        </div>

        <div class="profile-divider">
          <div class="divider">
            <v-divider class="custom-divider"></v-divider>
          </div>
        </div>
        <div class="switches-divider">
          <div class="text">Ofertas y promociones personalizadas</div>
          <v-switch
            v-model="$N.notifications.promotions"
            @change="$N.updateNotifications"
            hide-details="true"
            color="blue"
          ></v-switch>
        </div>

        <div class="profile-divider">
          <div class="divider">
            <v-divider class="custom-divider"></v-divider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//stores
import { useNotificationStore } from "@/store/stores";
//vue
import { onMounted } from "vue";

const $N = useNotificationStore();

onMounted(async () => {
  await $N.getNotifications();
});
</script>
