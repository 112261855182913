<script setup>
const props = defineProps(["rating"]);
const star = (n) => {
  if ((n - 0.5 <= props.rating) && ( props.rating < n)) {
    return "mdi-star-half-full";
  } else if (n > props.rating) {
    return "mdi-star-outline";
  } else {
    return "mdi-star";
  }
};
</script>

<template>
  <div class="stars">
    <v-icon>mdi-star</v-icon>
    <v-icon>{{ star(2) }}</v-icon>
    <v-icon>{{ star(3) }}</v-icon>
    <v-icon>{{ star(4) }}</v-icon>
    <v-icon>{{ star(5) }}</v-icon>
  </div>
</template>