<script setup>
/* eslint-disable */
import { useImageStore } from "@/store/stores";

const $IMAGES = useImageStore();
</script>

<template>
  <div class="upload-images">
    <div class="upload-card">
      <v-file-input
        density="compact"
        hide-details
        @change="$IMAGES.previewImage($event)"
        label="Imagen"
        type="file"
      ></v-file-input>

      <div class="up-images" v-if="!$IMAGES.imagePreview"></div>
      <div class="up-images" v-else><img :src="$IMAGES.imagePreview" /></div>

      <div class="up-btns">
        <v-btn @click="$IMAGES.dialogImage = false">Cerrar</v-btn>
        <v-btn @click="$IMAGES.saveImage">Aceptar</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
