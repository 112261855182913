<script setup>
//vue
import { reactive } from "vue";
//components
import RatingStars from "@/components/shared/RatingStars.vue";
//store
import { useRatingStore, useUserStore } from "@/store/stores";
//props
const props = defineProps(["data", "type"]);

const { getRatingByUser, editRating } = useRatingStore();
const $U = useUserStore();

const ratingData = reactive({
  rating: props.data.rating,
  comment: props.data.comment
});

const getData = (data) => {
  ratingData.rating = data;
};

const sendRating = async () => {
  const rating = {
    comment: ratingData.comment || undefined,
    rating: ratingData.rating,
    isAllowed: false,
    status: "PENDING-BY-ADMIN"
  };
  await editRating(props.data.id, rating);
  await getRatingByUser($U.currentUser.id);
};

const dltRating = async () => {
  const rating = {
    comment: ratingData.comment || undefined,
    rating: ratingData.rating,
    isAllowed: false,
    status: "IS-DELETED"
  };
  await editRating(props.data.id, rating);
  await getRatingByUser($U.currentUser.id);
};

</script>

<template>
  <v-dialog width="700">
    <template v-slot:activator="{ props }">
      <button v-bind="props" class="blue-button">{{ type === 'edit' ? 'Editar opinion' : 'Crear opinion' }}</button>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card>
        <v-card-text>
          <div class="rating-card">

            <img :src="data?.article.images[0]" :alt="data?.article.name">

            <span class="title">¿Qué te pareció tu producto?</span>

            <span class="subtitle">{{ data?.article.name }}</span>

            <RatingStars :data="data.rating" @send-data="getData" />

            <span class="title">Contanos más acerca de tu producto</span>

            <textarea v-model="ratingData.comment" placeholder="Mi producto me pareció..."></textarea>

          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :disabled="ratingData.rating === 0 && ratingData.comment === ''" v-if="type === 'edit'" text="ELIMINAR OPINION" @click="isActive.value = false; dltRating(data.id)"></v-btn>
          <v-btn :disabled="ratingData.rating === 0 && ratingData.comment === ''" text="GUARDAR" @click="isActive.value = false; sendRating()"></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped>
.blue-button {
  padding: 10px;
}
</style>