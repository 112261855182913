<template>
  <div v-if="!$User.currentUser" class="modal-dots">
    <span
      v-for="dot in dots"
      :key="dot"
      :class="['modal-dot', { 'modal-dot-gray': dot !== $Profile.currentView }]"
      :data-dot="dot"
      @click="$Profile.changeView(dot)"
    ></span>
  </div>
  <div v-else-if="$Profile.createNewProfile" class="modal-dots">
    <span
      v-for="dot in dotsCreate"
      :key="dot"
      :class="['modal-dot', { 'modal-dot-gray': dot !== $Profile.currentView }]"
      :data-dot="dot"
      @click="$Profile.changeView(dot)"
    ></span>
  </div>
  <div v-else class="modal-dots">
    <span
      v-for="dot in dotsCreate"
      :key="dot"
      :class="['modal-dot', { 'modal-dot-gray': dot !== $Profile.currentView }]"
      :data-dot="dot"
      @click="$Profile.changeView(dot)"
    ></span>
  </div>
</template>

<script setup>
import { useProfileStore, useUserStore } from "@/store/stores";

const $Profile = useProfileStore();
const $User = useUserStore();

const dots = [1, 2, 3, 4, 5, 6];
const dotsCreate = [0, 1, 2, 3, 4];
</script>
