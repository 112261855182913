<script setup>
/* eslint-disable */
//vue
import { ref } from "vue";
//vue-router
import { RouterLink } from "vue-router";

const footerMenu = ref(false);

const scrollToBottom = () => {
  footerMenu.value = !footerMenu.value;
  setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, 200)
};

</script>

<template>
  <footer class="footer">
    <div @click="scrollToBottom" class="footer-button" :class="{activeButton: footerMenu}">
      <span>Más información</span>
      <v-icon>mdi-chevron-up</v-icon>
    </div>
    <div class="footer-links" :class="{ active: footerMenu }">
      <RouterLink to="/weare">¿Quienes somos?</RouterLink>
      <RouterLink to="/faqsquestions">Preguntas frecuentes</RouterLink>
      <RouterLink to="/paymentways">Formas de pago</RouterLink>
      <RouterLink to="/termsconditions">Términos y condiciones</RouterLink>
      <RouterLink to="/changepolicies">Políticas de cambios</RouterLink>
      <RouterLink to="/privacypolicies">Política de privacidad</RouterLink>
      <RouterLink to="/shipmentpolicies">Políticas de envíos</RouterLink>
      <a href="https://www.argentina.gob.ar/servicio/iniciar-un-reclamo-ante-la-direccion-nacional-de-defensa-del-consumidor-y-arbitraje-del" target="_blank">Defensa al consumidor</a>
      <RouterLink to="/contactus">Contactanos</RouterLink>
    </div>
    <div class="footer-copyright">
      <div class="images">
        <img src="../assets/logos/mall.svg" alt="Mall">
        <a href="https://wa.me/message/KMN7SHK3B2K7O1" target="_blank"><v-icon>mdi-whatsapp</v-icon></a>
        <a href="https://www.instagram.com/malltiendaapp?igsh=MWNmdDltMjRhbWluNA==" target="_blank"><v-icon>mdi-instagram</v-icon></a>
      </div>
      <div>
        <p>Copyright © 2023 Mall. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>
</template>