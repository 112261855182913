<template>
  <v-dialog v-model="$POPUP.errorModal">
    <div class="modal">
      <div class="logo"><img src="@/assets/logos/mall-small.svg"/> </div>
      <div class="title">{{ errorText }}</div>
      <div class="btn" @click="$POPUP.errorModal=false">Volver</div>
    </div>
  </v-dialog>
</template>

<script setup>
import { usePopStore } from "@/store/popUp";

defineProps(["errorText"]);

const $POPUP = usePopStore();
</script>

<style scoped>
.modal {
  margin: auto;
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  max-width: 347px;
  width: 100%;
  height: 230px;
  border-radius: 6px;
  .mdi {
    font-size: 62px;
    color: red;
  }
  .logo{
    img{
      width: 50px;
    }
  }
  .title {
    font-weight: 300;
    font-size: 16px;
  }
  .btn {
    display: flex;
    max-width: 295px;
    min-width: 200px;
    height: 49px;
    background-color: rgba(209, 233, 255, 1);
    color: rgba(0, 87, 255, 1);
    align-items: center;
    justify-content: center;
    border-radius: 52px;
  }
}
</style>
